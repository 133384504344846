import React from "react"
import { PromiseModal } from "../PromiseModal.js"
import {solutionTabContents} from "../Solution.js";
import { levels } from "../cookies.js";
import { TabsUnder } from "../Tabs.js";
import { CenteredText, Container, FitContentFullWidthContainer, FullWidthContainer, ThemedBarButton } from "../styles/globalStyles.js";
import { FlexGrid, FlexGridRow } from "../genericComps/FlexGrid.js";
import Share from "../Share.js";
import { areCookiesAllowed, getCookie } from "../cookies.js";

const columnstyles = [
    {textAlign: "right", fontVariant: "small-caps", whiteSpace: "nowrap"},
    {textAlign: "left", wordWrap: "break-word", wordBreak: "break-word"},
    {textAlign: "left"},
];

export const MidnightContent = ({info}) => {
    const defaultVal = levels.length;
    let num;
    let succ;
    let guesses;
    if (areCookiesAllowed()) {
        num = levels.map(lvl => getCookie("num", lvl));
        succ = levels.map(lvl => getCookie("succ", lvl));
        guesses = levels.map(lvl => getCookie("langs", lvl));
    } else {
        const undefArr = levels.map(lvl => undefined);
        num = undefArr;
        succ = undefArr;
        guesses = undefArr;
    }

    const tabs = levels.map((lvl, i) => solutionTabContents(lvl, info[i], succ[i], num[i], guesses[i]));
    tabs.push({
        title: "All", 
        content: 
            <FlexGrid cols={3} xGap={6} yGap={4} columnStyles={columnstyles}>
                {Array(levels.length).fill(true).map((_, i) => 
                    <FlexGridRow key={`Level ${i + 1}`}>
                        <h2>level {i + 1}</h2>
                        <h3 lang="en">{info[i].lname}</h3>
                        <div><Share level={i + 1} mini={true} /></div>
                    </FlexGridRow>
                )}
            </FlexGrid>
    });

    return (
        <FitContentFullWidthContainer $noBottom $noTop $noLeft $noRight >
            <Container $noBottom>
                <CenteredText as="h3">
                    Yesterday's solutions were:
                </CenteredText>
            </Container>
            <TabsUnder defaultVal={defaultVal} tabs={tabs} $noTop={true} />
            <Container $thinTop $thinBottom>
                <CenteredText as="h3">
                    Are you ready for a new language?
                </CenteredText>
            </Container>
        </FitContentFullWidthContainer>
    );
};

const MidnightModal = (setIsOpen, info) => {

    const onResolve = () => {setIsOpen(false);};
    const onReject = () => {setIsOpen(false);};

    const buttons = (onResolve, onReject) => 
        <>
            <Share all={true} />
            <ThemedBarButton width="max-content" onClick={onResolve}>I'm ready!</ThemedBarButton>
        </>;

    return PromiseModal({
        title: "That late already?",
        children: <MidnightContent info={info} />,
        buttons: buttons,
        isOpen: true,
        onResolve: onResolve,
        onReject: onReject,
        noPadding: true,
    });
}

export default MidnightModal;