import AppBodySkeleton from "../skeletons/AppBodySkeleton"
import ErrorMessage from "./ErrorMessage"

const AppBodyFallback = (props) => {

  return (
    <AppBodySkeleton animation={false} errorComp={<ErrorMessage {...props}/>} />
  );
}

export default AppBodyFallback