import { useEffect, useState } from 'react'

const useBoolFunChange = (fun) => {
    const [state, setState] = useState(fun);

    useEffect(() => {
        const b = fun();
        if (b && !state) {
            setState(true);
        } else if (!b && state) {
            setState(false);
        }
    });

    return [state, setState];
}

export default useBoolFunChange