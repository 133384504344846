import { useCountdown } from './hooks/useCountdown';

export const Countdown = ({ targetDate, onMidnight }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate, onMidnight);

  if (days + hours + minutes + seconds <= 0) {
    return <ExpiredNotice />;
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  }
};

const ExpiredNotice = (props) => {
    return <h3>The next language has arrived!</h3>;
}

const ShowCounter = (props) => {
    var res = ((props.hours < 10) ? "0" + props.hours : props.hours);
    res += ":" + ((props.minutes < 10) ? "0" + props.minutes : props.minutes);
    res += ":" + ((props.seconds < 10) ? "0" + props.seconds : props.seconds);

    return (<h3>{res + " until next language"}</h3>);
}