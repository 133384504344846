import React, { useMemo, useState } from "react";
import { measureHeight } from "react-div-100vh";
import { useWindowDimensionsDebounce } from "../hooks/useWindowWidth";

const defaultHeight = "100vh";

export const DimensionsContext = React.createContext({
    appHeight: defaultHeight,
});

const DimensionsContextProvider = (props) => {
    const [windowWidth, windowHeight, outerWindowHeight] = useWindowDimensionsDebounce(500);
    //const h = use100vh();  
    //const [appHeight, setAppHeight] = useState(defaultHeight);

    const realHeight = useMemo(() => {
      const h = measureHeight();
      //console.log("real height:", h);
      //console.log(h, windowHeight, windowWidth);
      if (windowHeight < h && h < windowHeight + 1) {
        return Math.floor(windowHeight);
      }
      return windowHeight;
    }, [windowHeight])

    //console.log("Rendering context", windowWidth, windowHeight, realHeight);

    return (
        <DimensionsContext.Provider
            value={{
                appWidth: windowWidth,
                appHeight: realHeight, // || defaultHeight
                outerHeight: outerWindowHeight
            }}
        >
            {props.children}
        </DimensionsContext.Provider>
    );
};

export default DimensionsContextProvider;