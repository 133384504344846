import React, { useEffect, useState } from 'react'
import { Countdown } from './Countdown.js'
import SolutionModal from './modals/SolutionModal.js'
import Confetti from './Confetti.js'
import { getMidnight, getNearest } from './date.js'
import { Flex } from './styles/globalStyles.js'
import { withTheme } from 'styled-components'
import { getCookie } from './cookies.js'
import Share from './Share.js'
import { ErrorBoundary } from 'react-error-boundary'
import Result from './Result.js'

const GameOverLinks = (props) => {
    const [solIsOpen, setSolIsOpen] = useState(false);
    const [seenConfetti, setSeenConfetti] = useState(!getCookie("confetti"));
    const [alreadyFinished, setAlreadyFinished] = useState(props.alreadyFinished);

    useEffect(() => {
        if (!alreadyFinished) {
            const timer = setTimeout(() => {
                setSolIsOpen(true);
            }, seenConfetti ? 500 : 3000);
            if (!seenConfetti) {
                const timer2 = setTimeout(() => {
                    setSeenConfetti(true);
                }, 10000);
                return () => {
                    clearTimeout(timer); 
                    clearTimeout(timer2)
                };
            }
            return () => {
                clearTimeout(timer); 
            };
        }
    }, [])

    const style = {
        paddingTop: "var(--standard-p)",
        textAlign: "center"
    };

    return (
        <>
            <Flex $col alignContent="center" alignItems="center" style={style}>
                <Result succ={props.succ} num={props.num} ans={props.info.lname} onAnsClick={() => setSolIsOpen(true)}/>
                <Countdown targetDate={getMidnight()} onMidnight={props.onMidnight}/>
                <Share setLevel={props.setLevel} level={props.level} guesses={props.guesses} succ={props.succ} num={props.num} />
                {solIsOpen && <SolutionModal setLevel={props.setLevel} setIsOpen={setSolIsOpen} info={props.info} level={props.level} succ={props.succ} num={props.num} guesses={props.guesses} />}
            </Flex>
            {props.succ && !alreadyFinished && !seenConfetti && 
                <ErrorBoundary fallback={<></>} style={{height: 0, width: 0, overflow: "hidden"}}>
                    <Confetti />
                </ErrorBoundary>
            }
        </>
    );
};

export default withTheme(GameOverLinks);