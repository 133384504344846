import React from 'react'
import Snackbar from '@mui/material/Snackbar';
import { CloseButton } from './styles/globalStyles';
import { Portal } from '@mui/base';

const SnackBar = ({open, message, onClose}) => {

    const action = (
        <CloseButton onClick={onClose} />
    );

    return (
        <Portal>
            <Snackbar
                open={open}
                autoHideDuration={3500}
                onClose={onClose}
                message={message}
                action={action}
                ContentProps={{
                    sx: {
                        flexWrap: "nowrap"
                    }
                }}
            />
        </Portal>
    );
}

export default SnackBar