import styled from "styled-components";
import { Flex } from "./styles/globalStyles.js"
import { getFGColor } from "./styles/colors.js";
import { Socials } from "./externalLinks.js";
import { relativeSize } from "./ShadowScaler.js";

const relSize1 = relativeSize(-3, -1);
const relSize2 = relativeSize(6, 2);

const Footer = styled(Flex)`
    background-color: ${(props) => props.theme.darkbg};
    color: ${(props) => getFGColor(props, props.theme.darkbg)};
    height: fit-content;
    width: 100%;
    grid-column: 1 / 4;    
    padding-left: calc(2 * var(--standard-p));
    padding-right: calc(2 * var(--standard-p));    
    padding-top: var(--standard-p);
    padding-bottom: var(--standard-p);
    margin-top: var(--standard-p);
    overflow: hidden;
    position: absolute;
    bottom: 0;
    box-shadow: rgba(44, 64, 111, 0.16) 0 ${relSize1} ${relSize2}, 
        rgba(44, 64, 111, 0.23) 0 ${relSize1} ${relSize2};
    // &::after {
    //     height: 100%;
    //     width: 100%;
    //     position: absolute;
    //     background-color: ${(props) => props.theme.darkbg};
    //     border: 1px solid ${(props) => props.theme.darkbg};
    // }
`;

const AppFooter = () => { 
    return (
      <Footer as="footer" $row > 
          <h3>Enjoying Langle?</h3>
          <Socials />
      </Footer>
    );
}
  
export default AppFooter;
  