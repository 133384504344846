export const newShade = (hexColor, magnitude) => {
  hexColor = hexColor.replace(`#`, ``);
  if (hexColor.length === 6) {
      const decimalColor = parseInt(hexColor, 16);
      let r = (decimalColor >> 16) + magnitude;
      r > 255 && (r = 255);
      r < 0 && (r = 0);
      let g = (decimalColor & 0x0000ff) + magnitude;
      g > 255 && (g = 255);
      g < 0 && (g = 0);
      let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
      b > 255 && (b = 255);
      b < 0 && (b = 0);
      return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
  } else {
      return hexColor;
  }
};

export const setOpacity = (hex, alpha) => 
  `${hex}${Math.floor(alpha * 255).toString(16).padStart(2, '0')}`;

export const getDarkestColor = (props) => {
  if (props.theme.name === "light") {
    return props.theme.darkbg;
  }
  return props.theme.plainbg;
}

export const getFGColor = (props, col) => {
  switch (col) {
    case props.theme.darkbg:
      return props.theme.white;
    case props.theme.medbg:
      return getDarkestColor(props);
    case props.theme.plainbg:
      return props.theme.darkbg;
    case "#ffffff":
      return props.theme.darkbg;
    case props.theme.barcolor:
      return props.theme.darkbg;
    default:
      return props.theme.darkbg;
  }
}