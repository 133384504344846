import { useContext, useEffect, useState } from "react";
import { FixedPopover } from "./Popover";
import { TextWithEmoji } from "./innerScreens/Emoji";
import { DimensionsContext } from "./context/dimensionsContext";

//const getTextInstructions = 

const demoInstructions = {
    "faq": { 
        index: 7,
        text: "Frequently asked questions",
        origins: {
            anchor: { vertical: "bottom", horizontal: "center" },
            transform: { vertical: "top", horizontal: "left" },
        },
        placement: "bottom-start"
    },
    "sample": { 
        index: 0,
        text: <TextWithEmoji emoji="🤔" prefix="What language is this? " />,
        origins: {
            anchor: { vertical: "bottom", horizontal: "center" },
            transform: { vertical: "top", horizontal: "center" },
        },
        placement: "bottom"
    },
    "input": { 
        index: 1,
        text: "Enter your guess",
        origins: {
            anchor: { vertical: "top", horizontal: "center" },
            transform: { vertical: "bottom", horizontal: "center" },
        },
        placement: "top-start"
    },
    "guesses": { 
        index: 2,
        text: "You've already guessed",
        origins: {
            anchor: { vertical: "top", horizontal: "left" },
            transform: { vertical: "bottom", horizontal: "left" },
        },
        placement: "top-start"
    },
    "accuracy": { 
        index: 3,
        text: "You were this (geographically) close!",
        origins: {
            anchor: { vertical: "top", horizontal: "right" },
            transform: { vertical: "bottom", horizontal: "right" },
        },
        placement: "top-end"
    },
    "sound": { 
        index: 4,
        text: "Listen to a voice recording",
        origins: {
            anchor: { vertical: "bottom", horizontal: "center" },
            transform: { vertical: "top", horizontal: "right" },
        },
        placement: "bottom-end"
    },
    "stats": { 
        index: 5,
        text: "View your game statistics",
        origins: {
            anchor: { vertical: "bottom", horizontal: "center" },
            transform: { vertical: "top", horizontal: "right" },
        },
        placement: "bottom-end"
    },
    "settings": { 
        index: 6,
        text: "Settings: level, light/dark mode, cookies",
        origins: {
            anchor: { vertical: "bottom", horizontal: "center" }, 
            transform: { vertical: "top", horizontal: "right" },
        },
        placement: "bottom-end"
    },
}

export const getDemoStep = (id) => {
    const data = demoInstructions[id];
    if (data) {
        return data.index;
    }
    return -1;
}

export const numDemoSteps = Object.keys(demoInstructions).length;

// props: id, showStep
export const DemoInstruction = (props) => {
    const data = demoInstructions[props.id];

    //console.log("in DemoInstruction")

    // if (props.showStep !== undefined && props.showStep === data.index) {
    //     console.log(data.text, data.origins)
    // }

    return (
        props.showStep !== undefined
            ? <FixedPopover text={data.text} key={data.text} open={props.showStep === data.index} origins={data.origins} >
                {props.children}
            </FixedPopover>
            : <>
                {props.children}
            </>
    )
}

export const HideDuringDemo = (props) => {
    return (
        props.showStep !== undefined
            ? <></>
            : <>{props.children}</>
    )
}