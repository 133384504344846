import { InnerScreenWithHeader } from "../InnerScreen";
import InnerScreen from "../InnerScreen";
import ErrorMessage from "./ErrorMessage";

const AppFallBack = (props) => {

    const fullErrorScreen = 
        <InnerScreen pageTitle="" height={props.height}>
            <ErrorMessage {...props} style={{marginTop: `var(--standard-h)`}} />
        </InnerScreen>;

    return (
        <InnerScreenWithHeader plugin={fullErrorScreen} />
    );
}

export default AppFallBack

//  resetErrorBoundary={() => navigate("/")} /> 