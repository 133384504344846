
import Modal from "../Modal";
import CollapsibleList from "../Collapsible";
import { alreadyGameOver } from '../end.js';
import useBoolFunChange from "../hooks/useBoolFunChange";
import { ButtonGroup, Container, ThemedBarButton, Paragraph, FullWidthContainer, HoverCircleButton } from "../styles/globalStyles"
import { SolutionTabs } from "./SolutionModal";
import { setCookie, levels, areCookiesAllowed, allowAllCookies } from "../cookies";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { openInNewTab } from "../externalLinks";

export const Info = (props) => {
    const [playedALevel, setPlayedALevel] = useBoolFunChange(() => levels.some(lvl => alreadyGameOver(lvl)));
    const cookiesAllowed = areCookiesAllowed();

    const items = [
        {
            title: "How to play",
            content: <FullWidthContainer $thinTop >
                <Paragraph>Langle is a daily language-guessing game.
                Guess a world language from a few example
                sentences! See how close you can get in 6 tries.</Paragraph>
        
                <Paragraph>Like Wordle, 
                the daily puzzle is the same for all players, although
                Langle is available at 3 levels of difficulty for both
                language friends and language fiends.</Paragraph>
                
                <Paragraph>New languages are released each midnight!</Paragraph>
            </FullWidthContainer>
        },
        {
            title: "Solution",
            dynamic: true,
            content: 
                cookiesAllowed 
                ? 
                    playedALevel
                    ? <FullWidthContainer $noGap $thinTop>
                            <Paragraph>Today you have guessed:</Paragraph>
                            <SolutionTabs level={-1} setLevel={props.onLevelChange}/> 
                            {/*TODO: We must pass onLevelChange to SolutionTabs? */}
                    </FullWidthContainer>
                    : <FullWidthContainer $thinTop><Paragraph>Why don't you play today's game to find out?</Paragraph></FullWidthContainer>
                  
                  : <FullWidthContainer $thinTop><Paragraph>You have to enable cookies to see the solutions here.</Paragraph>
                        <ButtonGroup $thinBottom>
                            <ThemedBarButton onClick={() => {props.setIsOpen(false); props.openCookiesModal(true);}}>Review</ThemedBarButton>
                            <ThemedBarButton onClick={() => {setCookie("c", allowAllCookies); props.setIsOpen(false);}}>Allow all</ThemedBarButton>
                        </ButtonGroup>
                  </FullWidthContainer>
                    
        },
        {
            title: "Sample text",
            content: 
                <FullWidthContainer $thinTop>
                    <Paragraph>The sample text is Article 1 of the Universal Declaration of Human Rights (UDHR):</Paragraph>
                    <Container>
                        <Paragraph>“All human beings are born free and equal in 
                        dignity and rights. They are endowed with reason 
                        and conscience and should act towards one another 
                        in a spirit of brotherhood.”</Paragraph>
                    </Container>
                        <Paragraph>The UDHR is the most translated document in the 
                        world. There are 555 different translations 
                        at the time of writing, 300+ of which made it into Langle.</Paragraph>
                    <ButtonGroup $thinTop $thinBottom>
                    <ThemedBarButton 
                        width="fit-content" 
                        onClick={() => openInNewTab("UDHR")} 
                        endIcon={
                            <div style={{width: "fit-content", fontSize: "1em"}}>
                                <FontAwesomeIcon icon={icon({name: "external-link"})} />
                            </div>} 
                    >
                        UDHR
                    </ThemedBarButton>
                    </ButtonGroup>
                </FullWidthContainer>
        },
        {
            title: "Percentages",
            content: <FullWidthContainer $thinTop><Paragraph>The 
            closer languages are spoken, the higher your guess
            is rated.</Paragraph>
    
            <Paragraph>The 'location' of a language is roughly the centre
            point of the area or countries where it is spoken.
            In case of colonial languages like English and Spanish,
            the centre point of the country of origin is taken.</Paragraph>
            
            <Paragraph>A future goal is to use the genetic relationship 
            between languages as a (far more accurate) measure of
            correctness.</Paragraph></FullWidthContainer>
        },
        {
            title: "Langle is too easy / hard!",
            content: 
                <FullWidthContainer $thinTop>
                    <Paragraph>Enjoy Langle's language diversity at a different level!</Paragraph>
                    <ButtonGroup $thinBottom>
                        {levels.map((lvl) => 
                            <HoverCircleButton key={lvl} onClick={() => {setCookie("lvl", lvl); props.onLevelChange(lvl); props.setIsOpen(false);}}>
                                {lvl}
                            </HoverCircleButton>
                        )}
                    </ButtonGroup>
                </FullWidthContainer>
        },
        {
            title: "Langle is not saving my progress.",
            content: <FullWidthContainer $thinTop><Paragraph>Langle needs cookies to store your progress across days and browser refreshes. 
            Make sure to allow cookies and enable them in your browser.</Paragraph>
                <ButtonGroup $thinBottom>
                    <ThemedBarButton onClick={() => {props.setIsOpen(false); props.openCookiesModal(true);}}>Review</ThemedBarButton>
                    <ThemedBarButton onClick={() => {setCookie("c", allowAllCookies); props.setIsOpen(false);}}>Allow all</ThemedBarButton>
                </ButtonGroup>
            </FullWidthContainer>
        },
        {
            title: "Where is my favourite language?",
            content: <FullWidthContainer $thinTop>
            <Paragraph>Is it not among the 300+ languages available in level 2 and 3?</Paragraph>
            
            <Paragraph>The Langle languages were collected by matching translations of 
            Article 1 of the Universal Declaration of Human Rights with
            language data. Some languages may have been left out. Pop me a message if your language is available in 
            the UDHR but not in Langle.</Paragraph>
                <ButtonGroup $thinTop $thinBottom>
                    <ThemedBarButton 
                        width="fit-content" 
                        onClick={() => openInNewTab("Twitter")} 
                        startIcon={
                            <div style={{width: "fit-content", paddingRight: "var(--standard-p)"}}>
                                <FontAwesomeIcon icon={icon({name: "twitter", style: "brands"})} />
                            </div>} 
                        endIcon={
                            <div style={{width: "fit-content", fontSize: "1em"}}>
                                <FontAwesomeIcon icon={icon({name: "external-link"})} />
                            </div>}
                    />
                </ButtonGroup>
                </FullWidthContainer>
        },
    ];

    return (
        <CollapsibleList items={items}/>
    );
};

const InfoModal = (props) => {
    return (
        <Modal setIsOpen={props.setIsOpen} title={"FAQ"} >
            <Info {...props} />
        </Modal>
    );
};

export default InfoModal;