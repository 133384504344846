import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Flex, FullWidthContainer, SeparationLine } from "./styles/globalStyles";
import useWindowWidth from "./hooks/useWindowWidth";
import Intersperse from "./genericComps/Intersperse"
import { useMutationObserver } from '@react-hooks-library/core'
import ReplaceErrorBoundary from "./errorHandling/ReplaceErrorBoundary";
import {useUpdateEffect} from "usehooks-ts"

const CollapsibleList = ({ items, props })=> {
    const [openTab, setOpenTab] = useState(-1);
    //const [elements, setElements] = useState();
    const lastIndex = items.length - 1;

    const toggleCollapsible = (i) => (open) => {
      if (open) {
        setOpenTab(-1);
      } else {
        setOpenTab(i);
      }
    }

    //console.log("Rendering list");

    const elements = 
      items.map((item, i) => 
        <ReplaceErrorBoundary
          wrapper={ content =>
            <Collapsible title={item.title} dynamic={item.dynamic} isOpen={openTab === i} toggleCollapsible={toggleCollapsible(i)} {...props}> 
              {content}
            </Collapsible>
          }
          content={item.content} 
          key={item.title} />
      );

    return (
        <Intersperse elements={elements} separator={i => <SeparationLine key={"sep" + i} />} />
    );
}

const CollapsibleHeader = (props) => {
    return (
      <FullWidthContainer alignContent="left" onClick={props.onClick} >
        <Flex $row style={{cursor: "pointer", justifyContent: "left"}}  >
            <FontAwesomeIcon icon={props.isCollapsed ? icon({name: "caret-down"}) : icon({name: "caret-up"})} fontSize={`calc(${props.fontSize} / 1.5)`} width={props.fontSize} />
            <p style={{fontSize: props.fontSize}}><strong>{props.title}</strong></p>
        </Flex>
      </FullWidthContainer>
    );
}

const Collapsible = (props) => {
  const [isCollapsed, setIsCollapsed] = useState(!props.isOpen);
  const [height, setHeight] = useState(0);
  //const [width, setWidth] = useState(0);

  const contentRef = useRef();
  const targetRef = useRef();

  const windowWidth = useWindowWidth();

  const config = {
    childList: true, 
    subtree: true,
    attributeFilter: ["hidden"],
  };

  if (props.dynamic) {
    useMutationObserver(
      contentRef,
      (mutations) => {
        setHeight(contentRef.current.clientHeight);
      },
      config
    )
  }

  const fontSize = props.fontSize ? props.fontSize : "1em";

  const handleToggle = ev => {
    ev.preventDefault();
    ev.stopPropagation();
    setIsCollapsed(!isCollapsed);
    setHeight(contentRef.current.clientHeight);
    props.toggleCollapsible(props.isOpen);
    if (isCollapsed) {
      targetRef.current?.scrollIntoView({behavior: "smooth", block: "start", inline: "start"});
    }
  };

  useUpdateEffect(() => {
    setHeight(contentRef.current.clientHeight);
    setIsCollapsed(!props.isOpen);
    // if (contentRef.current.clientHeight === 0) {
    //   setWidth(0);
    // }
  }, [windowWidth, props.isOpen]);


  //console.log("Rendering child, props.isOpen:", props.isOpen, "isCollapsed:", isCollapsed);

  const currentHeight = isCollapsed ? 0 : height;
  //const currentWidth = isCollapsed ? 0 : width;

  return (
    <div ref={targetRef} >
      <CollapsibleHeader title={props.title} isCollapsed={isCollapsed} fontSize={fontSize} onClick={handleToggle} />
      <div style={{ 
        height: currentHeight, 
        overflow: "hidden", 
        display: "block", //isCollapsed && isUnmounted ? "none" : "block",
        WebkitTransition: "height 0.3s ease-out",
        OTransition: "height 0.3s ease-out",
        MozTransition: "height 0.3s ease-out",
        transition: "height 0.3s ease-out" }} >
        <div ref={contentRef} style={{fontSize: `calc(7 / 8 * ${fontSize})`}} onClick={(ev) => {ev.stopPropagation(); setHeight(contentRef.current.clientHeight);}}>
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default CollapsibleList;
