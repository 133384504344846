import Canvas from "./Canvas";

const darkColor = "#2C406F";
const lightColor = "#CDD9F5";
const midwayColor = "#7e8eb3";

export const setOpacity = (hex, alpha) => 
  `${hex}${Math.floor(alpha * 255).toString(16).padStart(2, '0')}`;

const condSwap = (c, a, b) => {
    if (c) {
      return [b, a];
    }
    return [a, b];
}

// const xor = (a, b) => a ? !b : b;

const realInlineNum = (length) => {
  const baseLength = 500;
  const inlineNum = 17;
  return Math.floor(length / baseLength * inlineNum);
}

export const drawBanner = (width, height, toggleColor, portraitMode = false, swap = false) => (context) => {
    let [maybeWidth, maybeHeight] = condSwap(portraitMode, width, height);
  
    const [transitionStartFraction, transitionEndFraction] = condSwap(swap, 0, 1);
    const transitionWidth = maybeWidth;
    const inlineNum = realInlineNum(maybeHeight);

    const inlineDist = Math.floor(maybeHeight / inlineNum);
    const lineNum = 4.8; //transitionWidth / lineDist;
    const lineDist = Math.floor(transitionWidth / lineNum); //Math.floor(Math.sqrt(0.75 * inlineDist * inlineDist));
  
    const maxSymbolSize = Math.ceil(0.5 * transitionWidth); //2 * inlineDist;
  
    const [leftCol, rightCol] = condSwap(toggleColor, lightColor, darkColor);
    
    // Draw gradient background
    context.fillStyle = leftCol;
    let linearGradient;
    if (portraitMode) {
      linearGradient = context.createLinearGradient(
        width / 2, 0, width / 2, height
      );
    } else {
      linearGradient = context.createLinearGradient(
        0, height / 2, width, height / 2
      );
    }
    linearGradient.addColorStop(transitionStartFraction, leftCol);
    linearGradient.addColorStop(transitionEndFraction, midwayColor);
    context.fillStyle = linearGradient;
    context.fillRect(0, 0, width, height);

    context.strokeStyle = rightCol;
    context.fillStyle = rightCol;
  
    let currentLine = transitionStartFraction * maybeWidth;
    let offset = true;
    let runner = 1;
    let fraction = 1 / lineNum;
    if (swap) {
      while (currentLine >= transitionEndFraction * maybeWidth - 20) {
        context.globalAlpha = Math.min(1.0, runner * fraction);
        let i;
        if (offset) {
          i = 0;
        } else {
          i = inlineDist / 2;
        }
        for (; i <= maybeHeight; i += inlineDist) {
          if (portraitMode) {
            drawSymbolAt(context, i, currentLine, maxSymbolSize * runner * fraction, rightCol, runner * fraction);
          } else {
            drawSymbolAt(context, currentLine, i, maxSymbolSize * runner * fraction, rightCol, runner * fraction);
          }      
        }
        //console.log("size", maxSymbolSize * runner * fraction, rightCol, "opacity", runner * fraction);
        currentLine -= lineDist;
        runner++;
        offset = !offset;
      }
    } else {
      while (currentLine <= transitionEndFraction * maybeWidth + 20) {
        context.globalAlpha = Math.min(1.0, runner * fraction);
        let i;
        if (offset) {
          i = 0;
        } else {
          i = inlineDist / 2;
        }
        for (; i <= maybeHeight; i += inlineDist) {
          if (portraitMode) {
            drawSymbolAt(context, i, currentLine, maxSymbolSize * runner * fraction, rightCol, runner * fraction);
          } else {
            drawSymbolAt(context, currentLine, i, maxSymbolSize * runner * fraction, rightCol, runner * fraction);
          }      
        }
        currentLine += lineDist;
        runner++;
        offset = !offset;
      }
    }

    return context;
}
  
const drawSymbolAt = (context, x, y, size, color, opacity) => {
    context.strokeStyle = setOpacity(color, opacity);
    context.fillStyle = setOpacity(color, opacity);
    context.font = `900 ${size}px Times`;

    context.save();
    context.translate(x, y);
    context.rotate( Math.PI / 180 * 40 );
    context.textBaseline = "middle";
    context.textAlign = "center";
    context.fillText(getSymbol(), 0, 0);
    context.restore();
}

const getSymbol = () => {
    return ipaArrSmall[Math.floor(Math.random() * ipaArrSmall.length)];
}

const ipaSmall = "mʙɱⱱvʋnrɾszɹcxʟɢɴʀʁiɪʏeøɛœæaɶɨʉɘɵəɜɞɐʊɯuɤoʌɔɑɒʘ";
const ipaArrSmall = [...ipaSmall];

const LetterFade = ({heightFragment, direction, headerContentStart}) => {
    let portraitMode = true;
    let swapSides = false;

    switch (direction) {
        case "left":
            portraitMode = false;
        case "top":
            swapSides = true;
            break;
        case "right":
            portraitMode = false;
            break;
    }

    const realDimensions = (w, h) => {
      const [maybeWidth, maybeHeight] = condSwap(portraitMode, w, h);
      const inlineDist = realInlineNum(maybeHeight);
      const lineDist = Math.floor(Math.sqrt(0.75 * inlineDist * inlineDist));
      return condSwap(portraitMode, maybeWidth + Math.floor(0.5 * lineDist), maybeHeight);
    }

    return (
        <Canvas draw={(w, h, toggleColor) => drawBanner(w, h, toggleColor, portraitMode, swapSides)} heightFragment={heightFragment} headerContentStart={headerContentStart} realDimensions={realDimensions} />
    );
}

export default LetterFade