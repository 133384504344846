export const lightBlueTheme = {
    "name": "light",
    "black": "#000000", /* Modal bg */
    "darkbg": "#2C406F",   /* Txt, logo, header content, footer bg etc. */
    "barcolor": "#ffffff", /* GuessBar, now rgb(128, 170, 255, 0.8), alt. #80aaff */
    "medbg": "#99bbff", /* Modal header, outline bars */
    "white": "#ffffff",
    "plainbg": "#CDD9F5", /* Background, footer txt */
    "red": "#E35335", /* Progress/correctness bar */
    "orange": "#FFA500", /* Progress/correctness bar */
    "yellow": "#e0f542", //"#F4C002", /* Progress/correctness bar */
    "green": "#3CC93C", /* Progress/correctness bar */
};

export const darkBlueTheme = {
    "name": "dark",
    "black": "#000000", /* Modal bg */
    "darkbg": "#CDD9F5",   /* was barcolor */
    "barcolor": "#99bbff", /* GuessBar, now rgb(128, 170, 255, 0.8), alt. #80aaff */
    "medbg": "#99bbff", /* Modal header, outline bars */
    "plainbg": "#2C406F", /* was darkbg */ 
    "white": "#2C406F",
    "red": "#F72119", /* Progress/correctness bar */
    "orange": "#FF9E3D", /* Progress/correctness bar */
    "yellow": "#FFF01F", /* Progress/correctness bar */
    "green": "#1FFF0F", /* Progress/correctness bar */
};
