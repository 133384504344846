import { useJsApiLoader, GoogleMap, MarkerF } from "@react-google-maps/api";
import MapSkeleton from './skeletons/MapSkeleton';
import { googleMapsApiKey } from "./keys/googleMapsApiKey"
import { useContext } from "react";
import { DimensionsContext } from "./context/dimensionsContext";

const mapContainerStyle = {
    height: "25vh",
    width: "85%",
    margin: "var(--standard-p) auto"
}

const Map = ({ pos }) => {

    const { appWidth, appHeight } = useContext(DimensionsContext);

    const zoom = 4; //Math.floor(appWidth / 100); //4;// between 0 and 20

    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: googleMapsApiKey,
    });

    return (
        isLoaded ?
            <GoogleMap className="map"
                mapContainerStyle={mapContainerStyle} 
                center={pos} 
                zoom={zoom} 
                options={{mapTypeControl: false, rotateControl: false, keyboardShortcuts: false}}
            >
                <MarkerF key={"mark"} position={pos} />
            </GoogleMap>
            : <MapSkeleton />
        
    );
};

export default Map