import { ErrorBoundary } from "react-error-boundary";
import { create } from "react-modal-promise";
import Modal from "./Modal";
import ErrorMessage from "./errorHandling/ErrorMessage";

const MyPromiseModal = ({ title, children, buttons, isOpen, onResolve, onReject, noPadding}) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorMessage} onReset={onResolve}>
      <Modal title={title} close={onResolve} buttons={buttons(onResolve, onReject)} noPadding={noPadding}>
        {children}
      </Modal>
    </ErrorBoundary>
  );
};

export const PromiseModal = create(MyPromiseModal)
