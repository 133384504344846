import { useCallback, useContext, useEffect, useRef, useState } from "react"
import styled, {withTheme} from "styled-components";
import { Flex } from "./styles/globalStyles";
import Brace from "./Brace.js"
import Swapper from "./genericComps/Swapper.js"
import { useMediaQuery, useUpdateEffect } from "usehooks-ts";
import { getInnerSampleHeight, getSampleHeight } from "./heights";
import { DimensionsContext } from "./context/dimensionsContext";
import { LevelContext } from "./context/levelContext";
import useFitText from "use-fit-text";
//import useFitTextMod from "./hooks/useFitTextMod.ts";
import { Textfit } from "react-textfit";
import { SampleTextSkeleton } from "./skeletons/SampleSkeleton.js";
import { ErrorBoundary } from "react-error-boundary";

export const SampleBox = styled(Flex)`
    padding-top: var(--standard-p);
    margin: var(--standard-p);
    align-items: center;
    justify-items: center;
    height: fit-content;
    flex-grow: ${(props) => Number(props.verticalFill)};
    flex-shrink: 1; //${(props) => Number(props.verticalFill)};
    overflow: hidden;
`;

export const BraceContainer = styled.div`
    font-family: "Times";
    height: 100%;
`;

const mobileWidthCutoff = 600;

const Sample = (props) => {
    const textRef = useRef();
    const { appWidth, appHeight, outerHeight } = useContext(DimensionsContext);
    const { sample, rtl } = useContext(LevelContext);
    const [finalHeight, setFinalHeight] = useState(undefined);
    const [maxSampleHeight, setMaxSampleHeight] = useState(getInnerSampleHeight(appHeight));
    const verticalFill = useMediaQuery(
        `only screen and (orientation: portrait) and (max-width: ${mobileWidthCutoff}px), 
        only screen and (orientation: landscape) and (max-height: ${mobileWidthCutoff}px`); 

    //console.log(textRef, appWidth, appHeight, outerHeight, sample, rtl, finalHeight, maxSampleHeight, verticalFill)

    const imageStyle = {
        width: "min-content",
        height: finalHeight || (verticalFill ? maxSampleHeight : "100%"), 
        transform: `scale(${rtl ? -1 : 1})`,
        color: props.theme.darkbg
    };

    const onRef = (ref) => {
        textRef.current = ref;
    }

    useUpdateEffect(() => {
        setFinalHeight(undefined);
        setMaxSampleHeight(getInnerSampleHeight(appHeight));
    }, [outerHeight, appWidth, appHeight, verticalFill])

    return (
        <SampleBox $row >
            <Swapper
                swap={rtl}
                first={
                    <div style={imageStyle}>
                        <Brace />
                    </div>
                }
                second={
                    <SampleText onRef={onRef} setParentHeight={setFinalHeight} sample={sample} rtl={rtl} verticalFill={verticalFill} maxSampleHeight={maxSampleHeight} appHeight={appHeight} />
                }
            />
        </SampleBox>
    );
}

const globalCache = {};

// Return [found, data]
const getCached = (key, data) => { 
    const keyStr = JSON.stringify(key);
    if (globalCache[keyStr]) { 
        return {found: true, val: globalCache[keyStr]}; 
    } 
    if (data !== undefined) {
        globalCache[keyStr] = data;
    }
    return {found: false, val: undefined};
};

// const useDebouncedFitText = (options) => {
//     //const {fontSize, ref} = useFitText(options);
//     //const [fontSize, setFontSize] = useState(options?.maxFontSize);
//     const [fontSizeDebounced, setFontSizeDebounced] = useState(options?.maxFontSize);

//     const {fontSize, ref} = useDebounce(useFitText({
//         minFontSize: options?.minFontSize,
//         maxFontSize: options?.fontSizeDebounced,
//         resolution: options?.resolution,
//         onStart: options?.onStart,
//         onFinish: options?.onFinish
//     }), 500);

//     return {fontSize, ref};
// }

const overlayStyle = {
    position: "absolute",
    margin: "auto",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    MsTransform: "translate(-50%,-50%)",
    width: "100%",
};

const TextfitPatched = ({onReady, ...props}) => {
    const { appWidth, appHeight, outerHeight } = useContext(DimensionsContext);
    const [key, setKey] = useState(0);

    // useEffect(() => {
    //     console.log("a render");
    //     if (mustUpdate) {
    //         console.log("a must-update render");
    //         setMustUpdate(false);
    //     }
    // })

    const onReadyPatched = (fontSize) => {
        onReady(fontSize);
        if (fontSize < 0.02 * Math.min(appWidth, appHeight)) {
            //console.log("something has gone wrong. p=", fontSize, "width=", appWidth, "height=", appHeight);
            //setMustUpdate(true);
            setKey(n => n + 1);
        }
    }

    return (
        <Textfit {...props} onReady={onReadyPatched} key={key} />
    )
}

const SampleText = (props) => {
    const [h, setH] = useState(undefined);
    //const [isVisible, setIsVisible] = useState(false);
    const [fontSize, setFontSize]= useState(undefined);
    const [scaling, setScaling] = useState(true);
    const [resizing, setResizing] = useState(false);
    const ref = useRef();
    const key = useRef(0);

    const onStart = () => {
        setH(undefined);
        setScaling(true);
        props.setParentHeight(undefined);
        //console.log("onstart");
    }

    useEffect(() => {	
        onStart();

        let timeout;
        const handleResize = () => {
            clearTimeout(timeout);

            setResizing(true);

            timeout = setTimeout(() => {
                setResizing(false);
                setScaling(true);
                onStart();
            }, 300);
        }
        window.addEventListener("resize", handleResize);
    
        return () => {
          window.removeEventListener("resize", handleResize);
        }
    }, []);

    const onReady = (p) => {
        //console.log("calculated fontsize=", p);
        //setIsVisible(true);
        setScaling(false);
        setFontSize(p);
        //setH(ref?.current?.getBoundingClientRect().height)//
        //key.current = key.current + 1;
        props.setParentHeight(ref?.current?.getBoundingClientRect().height);
        //console.log("onready", "fontSize to:", p);
        //console.log("useEffect after onready - setting height to:", ref?.current?.getBoundingClientRect().height);
    }

    // useEffect(() => {
    //     if (!scaling) {
            
    //     }
    // }, [scaling])

    useUpdateEffect(() => {
        //console.log("max height changed to", props.maxSampleHeight);
        //setH(undefined);
        //console.log(props.sample);
    }, [props.maxSampleHeight, props.appHeight, props.appWidth, props.verticalFill, props.sample])

    //console.log("dynamic?", props.verticalFill)

    const outerStyle = {
        //fontSize: "100%",
        wordWrap: "break-word",
        overflowWrap: "break-word",
        //width: "100%",
        position: "relative",
        //height: h || getSampleHeight(props.appHeight),
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        //padding: "calc(3 * var(--standard-p)) 0",
        //backgroundColor: "yellow",
        //height: "fit-content"
    }

    const innerStyle = {
        height: h || getInnerSampleHeight(props.appHeight), // TODO: h || ...
        //fontSize: props.verticalFill ? fontSize : "calc( 1.5 * var(--textFontSize))",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        margin: "calc(3 * var(--standard-p)) 0",
        //opacity: Number(!scaling), //Number(!isVisible)
        width: "100%",
        //background: "red"
    };

    const shellStyle = {
        height: "fit-content", //h || getInnerSampleHeight(props.appHeight), // TODO: h || ...
        //fontSize: props.verticalFill ? fontSize : "calc( 1.5 * var(--textFontSize))",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        margin: "calc(3 * var(--standard-p)) 0",
        //opacity: Number(isVisible),
        width: "100%",
        //background: "white"
    };

    //console.log("scaling:", scaling, "resizing:", resizing);

    const fallbackRender = ({ error, resetErrorBoundary }) => 
    {
        //console.log("falling back");
        setScaling(false);
        props.setParentHeight(ref?.current?.getBoundingClientRect().height);
        return(
        <TextfitShell style={shellStyle} fontSize={"calc( 1.5 * var(--textFontSize))"} >
            {props.sample}
        </TextfitShell>
        )}
        ;
    

    return (

        <div ref={ref} style={outerStyle} >
            {/* <SampleSkeletonOverlay show={scaling} /> */}
            {/* <SampleSkeletonOverlay show={!isVisible} /> */}
        {/* {scaling
            ?  */}
            <ErrorBoundary fallbackRender={fallbackRender} >
                <TextfitPatched key={key} mode="multi" min={1} max={500} onReady={onReady} style={innerStyle} throttle={200}>
                    {props.sample}
                </TextfitPatched> 
            </ErrorBoundary>
          {/* :
            <TextfitShell style={shellStyle} fontSize={fontSize} >
                {props.sample}
            </TextfitShell> } */}
        
        </div>
    );

    // return (
    //     <div style={outerStyle} >
            
    //         <SampleSkeletonOverlay show={!isVisible} />
    //         <div ref={ref} style={innerStyle} >
    //             <div ref={childRef} style={{height: "fit-content"}} dir={props.rtl ? "rtl" : "ltr"}>
    //                 {props.sample}
    //             </div>
    //         </div>
    //     </div>
    // );
}

const TextfitShell = ({style, fontSize, ...props}) => {
    const finalStyle = {
        ...style,
        fontSize: fontSize
    };

    const wrapperStyle = {
        display: "block" //ready ? 'block' : 'inline-block'
    };

    if (props.mode === "single") {
        wrapperStyle.whiteSpace = "nowrap";
    }

    return (
        <div style={finalStyle} >
            <div style={wrapperStyle}>
                {props.text && typeof props.children === "function"
                    ? ready
                        ? props.children(props.text)
                        : props.text
                    : props.children
                }
            </div>
        </div>
    );
}

// const overlayStyle = {
//     position: "absolute",
//     margin: "auto",
//     top: "50%",
//     left: "50%",
//     transform: "translate(-50%,-50%)",
//     MsTransform: "translate(-50%,-50%)",
//     width: "100%",
// };

const SampleSkeletonOverlay = ({show}) => {
    return (
        show
            ? <div style={overlayStyle}>
                <SampleTextSkeleton />
            </div>
            : <></>
    );
}

export default withTheme(Sample);