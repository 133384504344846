import Brace from "../Brace";
import { SampleBox, BraceContainer } from "../Sample"
import { Skeleton } from "@mui/material";

const numberOfSkelLines = 5; 
const paddingUnits = 3;
const lineHeight = "calc(2 * var(--textFontSize))";
const height = `calc(${numberOfSkelLines} * ${lineHeight} + (${numberOfSkelLines} + ${paddingUnits} ) * var(--standard-p))`;

export const SampleTextSkeleton = (props) => {
    
    const fontSize = "1.5rem";
    
    const textStyle = {
        padding: `calc(${paddingUnits} * var(--standard-p)) 0`,
        height: "fit-content",
        //fontSize: fontSize,
        width: "100%",
        alignSelf: "center",
        alignItems: "center",
        alignContent: "center",
    }

    return (
        <div style={textStyle}>
            {Array(numberOfSkelLines - 1).fill(true).map((_, i) => <Skeleton height={lineHeight} key={i} {...props}/> )}
            <Skeleton width="60%" height={lineHeight} {...props}/>
        </div>
    );
}

const SampleSkeleton = (props) => {

    return (
        <SampleBox $row >
                <Skeleton variant="rounded" height={height} {...props}>
                    <Brace />
                </Skeleton>
            <SampleTextSkeleton {...props} />
        </SampleBox>
    );
}

export default SampleSkeleton