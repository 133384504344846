import React from "react"
import { CenteredText } from "./styles/globalStyles";

const Result = ({ succ, ans, num, onAnsClick }) => {
    const Ans = () => {
        if (onAnsClick) {
            return <span style={{cursor: "pointer"}} onClick={onAnsClick}>{ans}</span>
        }
        return <span>{ans}</span>
    };

    return (
        succ 
        ? <CenteredText as="h3">
            <span style={{width: "fit-content"}}>
                {"You guessed "} 
                <Ans />
                <span style={{whiteSpace: "nowrap"}}>
                    {" in "}
                    {num}
                    {(num > 1 ? " tries!" : " try!")}
                </span>
            </span>
            </CenteredText>
        : <CenteredText as="h3">
            <span style={{width: "max-content"}}>
                {"You didn't guess "} 
                <Ans />
                {"!"}
            </span>
        </CenteredText>
    );
}

export default Result