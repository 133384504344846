const useSwipeable = (onSwipedLeft, onSwipedRight) => {

    let touchStartX = 0;
    let touchStartY = 0;
    let touchEndX = 0;
    let touchEndY = 0;

    const minSwipeDistance = 50;
    const minDifference = 20;

    const onTouchStart = (ev) => {
        touchEndX = 0;
        touchEndY = 0;
        touchStartX = ev.targetTouches[0].clientX;
        touchStartY = ev.targetTouches[0].clientY;
    }

    const onTouchMove = (ev) => {
        touchEndX = ev.targetTouches[0].clientX;
        touchEndY = ev.targetTouches[0].clientY;
    }

    const onTouchEnd = () => {
        if (!touchStartX || !touchEndX) {
            return;
        }
        const distanceX = touchStartX - touchEndX;
        const distanceY = touchStartY - touchEndY;
        const isLeftSwipe = distanceX > minSwipeDistance;
        const isRightSwipe = distanceX < -minSwipeDistance;
        if (isLeftSwipe && Math.abs(distanceX) > Math.abs(distanceY) + minDifference) {
            onSwipedLeft();
        } else if (isRightSwipe && Math.abs(distanceX) > Math.abs(distanceY) + minDifference) {
            onSwipedRight();
        }
    }

    return {
        onTouchStart,
        onTouchMove,
        onTouchEnd
    }
}

export default useSwipeable
