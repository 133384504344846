import styled, { css, withTheme } from "styled-components";
import Button from "@mui/material/Button";
import { getFGColor, newShade } from "./colors.js"
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro.js";
import { useEffect } from "react";
import { useRef } from "react";
import { relativeSize } from "../ShadowScaler.js";

export const CenteredText = styled.span`
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
`;

export const Bar = styled.div`
    height: var(--standard-h);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding-left: var(--standard-p);
    padding-right: var(--standard-p);
    border-radius: var(--standard-p);
    background-color: ${props => props.color};
    color: ${props => getFGColor(props, props.color)}; 
`;

const BarText = ({children}) => {
  return <div style={{lineHeight: 1, paddingTop: "2px", whiteSpace: "nowrap", fontSize: "min(1em, 4.5vw)"}}><strong>{children}</strong></div>;
}

export const PlayButton = ({onClick}) => {
  return (
      <ThemedBarButton 
          width="fit-content" 
          onClick={onClick} 
          startIcon={
              <div style={{width: "fit-content"}}>
                  <FontAwesomeIcon icon={icon({name: "play"})} />
              </div>} 
      >
          play
      </ThemedBarButton>
  );
}

export const HomeButton = ({onClick}) => {
  return (
      <ThemedBarButton 
          width="fit-content" 
          onClick={onClick} 
          startIcon={
              <div style={{width: "fit-content"}}>
                  <FontAwesomeIcon icon={icon({name: "house"})} />
              </div>} 
      >
          home
      </ThemedBarButton>
  );
}

export const BackButton = ({onClick}) => {
  return (
      <ThemedBarButton 
          width="fit-content" 
          onClick={onClick} 
          startIcon={
              <div style={{width: "fit-content"}}>
                  <FontAwesomeIcon icon={icon({name: "arrow-left-long"})} />
              </div>} 
      >
          back
      </ThemedBarButton>
  );
}

export const HoverCircleButton = (props) => {
  const [hovering, setHovering] = useState(false);

  return (
      <ThemedCircleButton 
          onClick={props.onClick} 
          onMouseEnter={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)} 
          hover={hovering}
      >
          <div style={{margin: 0, position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", fontSize: "inherit"}}>
            <strong>
                {props.children}
            </strong>
          </div>
      </ThemedCircleButton>
  );
}

const BarButton = (props) => {
  const hasChildren = props.children && props.children.length > 0;

  const sx = {
    flexGrow: props.$mini ? 0 : 1,
    width: props.width || "100%",
    height: "var(--standard-h)",
    borderRadius: props.borderRadius || "var(--standard-p)",
    backgroundColor: props.theme.darkbg,
    color: getFGColor(props, props.theme.darkbg),
    "&:hover": {
        backgroundColor: newShade(props.theme.darkbg, 15),
    },
    "&:disabled": {
        backgroundColor: newShade(props.theme.darkbg, 35),
        color: getFGColor(props, props.theme.darkbg),
    },
  };

  const ifChildrenSx = hasChildren
    ? {}
    : {
      "&> .MuiButton-startIcon": {
        margin: 0
      },
      "&> .MuiButton-endIcon": {
        margin: 0
      }
  };

  return (
    <Button 
      onClick={props.onClick}
      type={props.type || "button"}
      disabled={props.disabled || false}
      variant={props.variant || "contained"}
      startIcon={props.startIcon || ""}
      endIcon={props.endIcon || ""}
      sx={{...sx, ...ifChildrenSx}}>
          {hasChildren 
          ? <Flex $row $auto width={props.width || "fit-content"} >
              <BarText>
                {props.children}
              </BarText>
          </Flex>
          : <></> }
      </Button>
  );
}

export const ThemedBarButton = withTheme(BarButton);

const CircleButton = styled.div`
    position: relative;
    display: inline-block;
    border-radius: 50%;
    cursor: pointer;
    width: ${props => props.width || "2em"};
    height: ${props => props.width || "2em"};
    -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    -o-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    -moz-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: ${props => getFGColor(props, props.theme.darkbg)};
    ${props => props.disabled 
      ? css`
        background-color: ${props => newShade(props.theme.darkbg, 35)};
        color: ${props => getFGColor(props, props.theme.darkbg)};`
      : props.hover
        ? css`
          background-color: ${props => newShade(props.theme.darkbg, 15)};
          box-shadow: 0 ${relativeSize(2, 2)} ${relativeSize(4, 4)} ${relativeSize(-1, -1)} rgba(0,0,0,0.2), 
            0 ${relativeSize(4, 4)} ${relativeSize(5, 5)} 0 rgba(0,0,0,0.14), 
            0 ${relativeSize(1, 1)} ${relativeSize(10, 10)} 0 rgba(0,0,0,0.12);`
        : css`
          box-shadow: 0 ${relativeSize(3, 3)} ${relativeSize(1, 1)} -${relativeSize(-2, -2)} rgba(0,0,0,0.2), 
            0 ${relativeSize(2, 2)} ${relativeSize(2, 2)} 0 rgba(0,0,0,0.14), 
            0 ${relativeSize(1, 1)} ${relativeSize(5, 5)} 0 rgba(0,0,0,0.12);
          background-color: ${props => props.theme.darkbg};`
        }
`;

export const ThemedCircleButton = withTheme(CircleButton);

export const CloseButton = (props) => 
    <IconButton
        sx={{
          "&:hover": {
            cursor: "pointer"
          }
        }}
        size={props.size || "small"}
        aria-label="close"
        color="inherit"
        onClick={props.onClick}
    >
        <CloseIcon fontSize={props.size || "small"} />
    </IconButton>;

export const ButtonGroup = (props) => {
  const groupRef = useRef();

  useEffect(() => {
    // if (groupRef.current?.offsetWidth) {
    //   console.log("width of buttonGroup:", groupRef.current?.offsetWidth);
    // }
  }, [props.toFit])

  return (
      <Container {...props} ref={groupRef}>
        {props.$col 
          ? <Flex $col height={props.$height} justifyContent="space-evenly">{props.children}</Flex>
          : <Flex $row justifyContent="space-evenly">{props.children}</Flex>
        }
      </Container>
  );
}

export const ButtonGroup2 = (props) => {
  return (
      <Container {...props} >
        {props.$col 
          ? <Flex $col height={props.$height} justifyContent="space-evenly">{props.children}</Flex>
          : <Flex $row justifyContent="space-evenly">{props.children}</Flex>
        }
      </Container>
  );
}

export const Paragraph = styled.p`
  width: ${props => props.width ? props.width : "fit-content"};
  height: ${props => props.height ? props.height : "fit-content"};
  text-align: left;
  //text-justify: inter-word;
  justify-content: center;
  align-content: center;
  line-height: 1.43;
`;

export const Container = styled.div`
  margin: ${(props) => props.$col ? "auto 0" : "0 auto"};
  padding-top: calc(${(props) => props.$noTop ? 0 : props.$thinTop ? 1 : 3} * var(--standard-p));
  padding-bottom: calc(${(props) => props.$noBottom ? 0 : props.$thinBottom ? 1 : 3} * var(--standard-p));
  padding-left: calc(${(props) => props.$noLeft ? 0 : props.$thinLeft ? 1 : 3} * var(--standard-p));
  padding-right: calc(${(props) => props.$noRight ? 0 : props.$thinRight ? 1 : 3} * var(--standard-p));
  width: ${props => props.width ? props.width : "fit-content"};
  height: ${props => props.height ? props.height : "fit-content"};
  display: inline-block;
  justify-content: center;
  align-content: ${(props) => props.alignContent || "center"};
  line-height: 1.43;
`;

export const FitContentFullWidthContainer = styled(Container)`
    width: fit-content;
    gap: ${props => props.$noGap ? 0 : "calc(2 * var(--standard-p))"};
    display: flex;
    flex-direction: column;
`

export const FullWidthContainer = styled(Container)`
    width: 100%;
    gap: ${props => props.$noGap ? 0 : "calc(2 * var(--standard-p))"};
    display: flex;
    flex-direction: column;
`;

export const Flex = styled.div` 
  display: flex;
  gap: calc(${(props) => props.$gapWidth || 1} * var(--standard-p));
  ${(props) =>
    props.$row && 
    css`
      flex-direction: row;
      align-items: center;
      justify-content: center;
      @supports (-webkit-touch-callout: none) and (not (translate: none)) { // If flex gap is not supported in iOS/Safari
        & > *:not(:last-child) {
          margin-right: calc(${(props) => props.$gapWidth || 1} * var(--standard-p));
        }
      }
    `}
  ${(props) =>
    props.$col &&
    css`
      flex-direction: column;
      @supports (-webkit-touch-callout: none) and (not (translate: none)) { // If flex gap is not supported in iOS/Safari
        & > *:not(:last-child) {
          margin-bottom: calc(${(props) => props.$gapWidth || 1} * var(--standard-p));
        }
      }
    `};
  ${(props) => 
    props.width &&
    css`
      width: ${props.width};
    `};
  ${(props) => 
    props.height &&
    css`
      height: ${props.height};
    `};
  ${(props) => 
    props.alignItems &&
    css`
      align-items: ${props.alignItems};
    `};
  ${(props) => 
    props.justifyContent &&
    css`
      justify-content: ${props.justifyContent};
    `};
  ${(props) => 
    props.style }
`;

export const StyledFontAwesomeIconWithBG = styled.span`
    padding-left: calc(2 * var(--standard-p));
    padding-right: calc(2 * var(--standard-p));
    color: ${props => props.theme.darkbg}; 
    cursor: pointer;
    height: ${props => props.height ? props.height : "1.2em"};
    display: block;
`;

export const SeparationLine = styled.hr`
    border-style: solid;
    border-color: ${(props) => props.theme.medbg};
    border-width: 0 0 ${relativeSize(1, 1)} 0;
`;
