import styled, { withTheme } from "styled-components";
import { Bar, Flex } from './styles/globalStyles.js'
import { AnimatedPercentage, AnimatedProgressBar } from './Progress.js'

const Guess = styled.div`
    display: grid;
    grid-template-columns: 0.6fr 0.4fr calc(4.3 * var(--textFontSize)); 
    gap: var(--standard-p);
    width: 100%;
`;

const UnusedGuessBar = (props) => {
    return (
        <Bar color={props.theme.barcolor} onClick={props.onClick}/>
    )
}

const GuessBar = (props) => {
    const color = props.theme.barcolor;
  
    return(
        (props.acc)
            ? <Guess>
                <Bar className="hide-scroll g1" color={color} >
                    <Flex $row>
                        <h3>{props.lang}</h3>
                    </Flex>
                </Bar>
                <Bar color={color} >
                    <AnimatedProgressBar progress={props.acc} duration={props.duration} lang={props.lang} />
                </Bar>
                <Bar className="acc-cell" color={color}>
                    <AnimatedPercentage progress={props.acc} duration={props.duration} lang={props.lang} />
                </Bar>
            </Guess>
            : <UnusedGuessBar {...props} />
    );
};

export default withTheme(GuessBar);