import { useContext } from 'react';
import GamePlay from './GamePlay';
import { setCookie, levels } from './cookies.js'
import './App.css';
import { Flex } from './styles/globalStyles';
import AppBodySkeleton from "./skeletons/AppBodySkeleton"
import { ErrorBoundary } from "react-error-boundary";
import GamePlayFallback from './errorHandling/GamePlayFallback';
import useSwipeable from './hooks/useSwipeable';
import { GlobalContext } from './context/globalContext';
import Sample from './Sample';
import { LevelContext } from './context/levelContext';

const AppBody = (props) => {

  const { lastVisit } = useContext(GlobalContext);
  const { level, setLevel, cands, isLoaded, onMidnight } = useContext(LevelContext);

  const onSwipedLeft = () => {
    const index = levels.findIndex(x => x === level);
    if (index < levels.length - 1 && index > -1) {
      setLevel(levels[index + 1]);
      setCookie("lvl", levels[index + 1]);
    }   
  }

  const onSwipedRight = () => {
    const index = levels.findIndex(x => x === level);
    if (index > 0) {
      setLevel(levels[index - 1]);
      setCookie("lvl", levels[index - 1]);
    } 
  }

  const {onTouchStart, onTouchMove, onTouchEnd} = useSwipeable(onSwipedLeft, onSwipedRight);

  if (!isLoaded) {
    return (
      <AppBodySkeleton />
    );
  }

  return (
    <Flex $col onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd} >
        <Sample />
        
        <ErrorBoundary FallbackComponent={GamePlayFallback}>
            <GamePlay cands={cands} onMidnight={onMidnight} lastVisit={lastVisit} level={level} setLevel={setLevel}/>
        </ErrorBoundary>
    </Flex>
  );
}


export default AppBody;

