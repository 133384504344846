import { Skeleton } from "@mui/material";

export const MapSkeleton = ({errorComp, ...props}) => {
    return (
        <>
            <Skeleton sx={{width: "85%", height: "25vh"}} variant="rounded" {...props} style={{margin: "0 auto"}}/>
            {errorComp}
        </>
    );
}

export default MapSkeleton