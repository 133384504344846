import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const useGoBack = (defaultLoc) => {
    const location = useLocation();
    const navigate = useNavigate();
    return (() => {
        const doesHistoryExist = location.key !== "default";
        if (doesHistoryExist) {
            return navigate(-1);
        }
        return navigate(defaultLoc);
    })
};

export default useGoBack