const Swapper = ({ first, second, swap }) => {
    if (swap) {
        return (
            <>
                {second}
                {first}
            </>
        );
    }
    return (
        <>
            {first}
            {second}
        </>
    );
};

// export const SwapperR = ({ children, swap }) => {
//     if (swap) {
//         return (
//             <>
//                 {children.reverse()}
//             </>
//         );
//     }
//     return (
//         <>
//             {children}
//         </>
//     );
// };

export default Swapper