import React, { useState } from 'react'
import { Flex, HoverCircleButton, ThemedBarButton } from './styles/globalStyles.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { levels, getCookie, setCookie } from './cookies.js'
import SnackBar from './SnackBar.js'
import AppendErrorBoundary from './errorHandling/AppendErrorBoundary.js'
import { alreadyGameOver } from './end.js'
import { useUpdateEffect } from 'usehooks-ts'
import { shareScoreGA } from './Analytics.js'
import { useErrorBoundary } from 'react-error-boundary'

const numToEmoji = (n) => {
    const width = 5;
    const parts = 100 / 5;
    const n_yes = Math.floor(n / parts);
    const n_half = (n % parts >= parts / 2);
    const n_no = width - n_yes - n_half;
    return "🟩".repeat(n_yes) + "🟨".repeat(n_half) + "⬜".repeat(n_no);
};

const parseDate = (dateStr = undefined) => {
    if (dateStr === undefined) {
        return new Date();
    }
    let d;
    try {
        d = new Date(`${dateStr.slice(2, 4)}/${dateStr.slice(0, 2)}/20${dateStr.slice(-2)}`);
    } catch {
        d = new Date();
    }
    return d;
}

export const getGameNumber = (dateStr = undefined) => {
    const date = parseDate(dateStr);
    const startDate = new Date("07/13/2023"); // mm/dd/yyyy
    const diff = date.getTime() - startDate.getTime();
    const days = Math.floor(diff / (1000 * 3600 * 24)) + 1;
    return days;
}

const getClipboardScore = (lvl, guesses, succ, num) => {
    var res = "";
    var bestScore = 0.0;
    guesses.forEach((val, i) => {
        if (i % 2 === 1) {
            bestScore = Math.max(val, bestScore);
            res += numToEmoji(val) + "\n";
        }
    });
    const succesIn = (succ ? num : "X");
    return ("Langle #" + getGameNumber() + " level " + lvl + ": " + succesIn + "/6, " + bestScore + "%\n\n" + res);
}

const ShareButton = ({onClick, all, mini}) => {
    return (
        <ThemedBarButton 
            $mini={mini}
            width="fit-content" 
            onClick={(ev) => onClick(ev, all && levels)} 
            startIcon={
                <div style={{width: "fit-content"}}>
                    <FontAwesomeIcon id="shareIcon" icon={icon({name: "share-nodes"})} />
                </div>} 
        >
            {mini || (all ? "Share all" : "Share")}
        </ThemedBarButton>
    );
}

const ShareWrappedByErrorBoundary = (props) => {
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState();
    const [notGameOvers, setNotGameOvers] = useState(levels.filter((lvl) => !alreadyGameOver(lvl) && lvl !== level));

    const level = props.level || getCookie("lvl");

    const {showBoundary} = useErrorBoundary();

    useUpdateEffect(() => {
        setNotGameOvers(levels.filter((lvl) => !alreadyGameOver(lvl) && lvl !== level));
    }, [level])

    const getClipboardScores = (levelArray) => {
        let results = levelArray.map((lvl) => {
            if (lvl === level) {
                const guesses = props.guesses || getCookie("langs", lvl);
                const succ = props.succ || getCookie("succ", lvl);
                const num = props.num || getCookie("num", lvl);
                return getClipboardScore(lvl, guesses, succ, num);
            } else {
                const guesses = getCookie("langs", lvl);
                const succ = getCookie("succ", lvl);
                const num = getCookie("num", lvl);
                return getClipboardScore(lvl, guesses, succ, num);
            }
        });
        return results.join("\n");
    }

    const handleClose = (event, reason) => {
        // if (reason === "clickaway") {
        //     return;
        // }
        setSnackOpen(false);
    };

    const handleShare = (ev, lvls = undefined) => {
        let levelArray;
        if (lvls === undefined) {
            levelArray = [level];
        } else { 
            levelArray = lvls;
        }

        window.focus();
        const res = getClipboardScores(levelArray);
        navigator.clipboard.writeText(res).then(() => {
            setSnackMessage("Results copied to clipboard");
            shareScoreGA("clipboard", levelArray);
            setSnackOpen(true);
        }, function() {
            setSnackMessage("Your browser does not allow automatic copying. Copy this instead:\n" + res);
            setSnackOpen(true);
        });
    };

    return (
        <Flex $row $gapWidth={2}>
            <ShareButton onClick={handleShare} all={props.all} mini={props.mini} />
            {props.setLevel &&
                notGameOvers.length === 0 
                    ? <>
                        <h3>or</h3>
                        <ShareButton onClick={(ev) => handleShare(ev, levels)} all={true} mini={props.mini}/>
                    </>
                    : props.setLevel && <Levels levels={notGameOvers} setLevel={props.setLevel} />
            }
            <SnackBar open={snackOpen} message={snackMessage} onClose={handleClose} />
        </Flex>
    );
}

const Share = (props) => {
    return (
        <AppendErrorBoundary >
            <ShareWrappedByErrorBoundary {...props} />
        </AppendErrorBoundary>
    );
}

const Levels = ({ levels, setLevel }) => {
    return (
        <>
            <h3 style={{
    justifyContent: "center",
    textAlign: "center"}} >or play level</h3>
            {levels.map((lvl) => 
                <HoverCircleButton key={lvl} onClick={() => {setLevel(lvl); setCookie("lvl", lvl);}}>
                    {lvl}
                </HoverCircleButton>
            )}
        </>
    );
}

export default Share