import { Fragment } from "react";

const Intersperse = ({elements, separator}) => {
    const n = elements.length;
    return (
      <>
        {Array(n - 1).fill(true).map((_, i) => 
          <Fragment key={i}>
              {elements[i]}
              {separator(i)}
          </Fragment>
        )}
        {n > 0 && elements[n - 1]}
      </>
    );
}

export default Intersperse