import InnerScreen from "../InnerScreen";
import { BackButton, ButtonGroup, Flex, PlayButton, ThemedBarButton } from "../styles/globalStyles";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useUpdateEffect } from "usehooks-ts";
import Sample, { SampleBox, SampleWithContext } from "../Sample";
import Guesses from "../Guesses";
import AppHeader from "../AppHeader";
import styled, { withTheme } from "styled-components";
import useGoBack from "../hooks/useGoBack";
//import { goBack, useGoBack } from "../hooks/useGoBack";
import { DotNavigation } from "../DotNavigation";
import { Input } from "../LangForm";
import Disable from "../genericComps/Disable";
import { DemoInstruction, getDemoStep, numDemoSteps } from "../Instruction";
import useAutoplay from "../hooks/useAutoPlay";
import useSwipeable from "../hooks/useSwipeable";
import { Autoscaler } from "../Autoscaler";
import Brace from "../Brace";
import { getDarkestColor, getFGColor } from "../styles/colors";
//import { ButtonGroup2 } from "../styles/FlexComps";

const SimulationBody = styled.div`
    padding: var(--standard-p);
`;

const UnthemedDemoSample = (props) => {
    const childRef = useRef();
    const [finalHeight, setFinalHeight] = useState(undefined);

    const imageStyle = {
        width: "min-content",
        height: finalHeight || "unset", 
        color: props.theme.darkbg
    };
   
    const textStyle = {
        padding: "calc(3 * var(--standard-p)) 0",
        //position: "absolute",
        margin: "auto",
        //top: "50%",
        //transform: "translateY(-50%)",
        wordWrap: "break-word",
        overflowWrap: "break-word",
    }

    useEffect(() => {
        if (childRef && childRef.current) {
            setFinalHeight(childRef.current.getBoundingClientRect().height); 
        }
    })

    return (
        <SampleBox $row >
            <div style={imageStyle}>
                <Brace />
            </div>
            <h2 className="notranslate" ref={childRef} style={textStyle} >
                {props.sample}
            </h2> 
        </SampleBox>
    );
}

const DemoSample = withTheme(UnthemedDemoSample)

const SimulationPlay = (props) => {
    const sample = "Tous les êtres humains naissent libres et égaux en dignité et en droits. Ils sont doués de raison et de conscience et doivent agir les uns envers les autres dans un esprit de fraternité.";
    const guesses = [
        "Thai", 40,
        "Spanish", 95,
        "French", 100
    ];

    const id = props.showStep <= getDemoStep("guesses") ? "guesses" : "accuracy";
    //console.log(id);

    //console.log("in SimulPlay")
    
    return (
        <Disable role="img" aria-label="" > {/* TODO: add label with all instructions */}
            <AppHeader showStep={props.showStep} />
            <SimulationBody>
                <DemoInstruction id="sample" showStep={props.showStep} >
                    <DemoSample sample={sample} />
                </DemoInstruction>

                <DemoInstruction id={id} showStep={props.showStep} >
                    <Guesses guesses={guesses} duration={-1} num={guesses.length / 2} />
                </DemoInstruction>
                <div style={{height: "var(--standard-p)"}} />

                <DemoInstruction id="input" showStep={props.showStep} >
                    <ThemedBareBonesLangForm />
                </DemoInstruction>
            </SimulationBody> 
        </Disable>
    );
}

const BareBonesLangForm = (props) => {
    return (
        <Flex as="form" $col style={{paddingBottom: "calc(0.5 * var(--standard-p))"}} > 

                <div>
                    <Input placeholder="Guess language ..." />
                </div>

            <ThemedBarButton type="button" disabled={false} startIcon={
                props.theme.name === "light"
                ? <img src="/icons/icon64x64.png" width="16px" height="16px" alt="" />
                //<img src="/icons/icon16x16.png" alt="" />
                : <img src="/icons/icon64x64inv.png" width="16px" height="16px" alt="" />
            } >
                submit
            </ThemedBarButton>
            
        </Flex>
    );
}

const ThemedBareBonesLangForm = withTheme(BareBonesLangForm);

const FAQButton = ({onClick}) => {
    return (
        <ThemedBarButton 
            width="fit-content" 
            onClick={onClick} 
            startIcon={
                <div style={{width: "fit-content"}}>
                    <FontAwesomeIcon icon={icon({name: "question"})} />
                </div>} 
        >
            FAQ
        </ThemedBarButton>
    );
  }

const UnthemedPlayPauseButton = ({isPlaying, play, pause, ...props}) => {
    return (
        <FontAwesomeIcon 
            className="fa-fw"
            icon={isPlaying ? icon({name: "pause"}) : icon({name: "play"})} 
            size={"xl"}
            color={props.theme.darkbg}
            onClick={isPlaying ? pause : play}
        />); 
}

const PlayPauseButton = withTheme(UnthemedPlayPauseButton)

const UnthemedHowToPlayScreen = ({props}) => {
    const [slideShowLength, setSlideShowLength] = useState(numDemoSteps);
    const [currentSlide, setCurrentSlide, isPlaying, play, pause] = useAutoplay(slideShowLength, 3000, 0, true);
    //const [currentSlide, setCurrentSlide] = useState(0);

    const goBack = useGoBack("/intro");
    const navigate = useNavigate();

    const onClick = (ev) => {
        setCurrentSlide(n => (n + 1) % slideShowLength);
    }

    const changeSlide = (n) => {
        setCurrentSlide(n);
    }

    const onSwipedLeft = () => {
        setCurrentSlide(n => Math.min(slideShowLength - 1, n + 1));
    }

    const onSwipedRight = () => {
        setCurrentSlide(n => Math.max(0, n - 1));
    }

    const {onTouchStart, onTouchMove, onTouchEnd} = useSwipeable(onSwipedLeft, onSwipedRight);

    const buttons = 
        <ButtonGroup width="100vw" $noLeft $noRight >
            <BackButton onClick={goBack} />
            <FAQButton onClick={() => navigate("/faq")} />
            <PlayButton onClick={() => navigate("/")} />
        </ButtonGroup>;

    const isMobilePortrait = false;
 
    // TODO: $row or $col depending on isMobilePortrait
    return (
        <InnerScreen pageTitle="How to play" footer={buttons}>
            <Flex $row style={{width: "100%", height: "100%", overflow: "none"}} onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd} alignItems="center" >
                {/* <Swapper 
                    swap={isMobilePortrait}
                    first={ */}
                        <Autoscaler onClick={onClick} >
                            <SimulationPlay showStep={currentSlide} />
                        </Autoscaler>
                    {/* }
                    second= { */}
                    <Flex $col $gapWidth={2} >
                        <PlayPauseButton isPlaying={isPlaying} play={play} pause={pause} />
                        <DotNavigation value={currentSlide} length={slideShowLength} clickable={true} onChange={changeSlide} size="calc(4 * var(--standard-p))" speed="300ms" orientation={isMobilePortrait ? "horizontal" : "vertical"} />
                    </Flex>
                    {/* } 
                        
                />*/}
            </Flex>
        </InnerScreen>
    );
};

const HowToPlayScreen = withTheme(UnthemedHowToPlayScreen);

export default HowToPlayScreen