import { useCallback, useRef } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

const ipa = "pbmʙɸβɱⱱfvʋθðtdnrɾszɬɮɹlʃʒʈɖɳɽʂʐɻɭcɟɲçʝjʎkɡŋxɣɰʟqɢɴʀχʁħʕʔhɦiyɪʏeøɛœæaɶɨʉɘɵəɜɞɐʊɯuɤoʌɔɑɒʘǀǃǂǁɓɗʄɠʛ";
const ipaArr = [...ipa];

const targetHeight = 0.9;

const confettiSpeed = (windowHeight) => {
  return 0.0647841 * windowHeight + 17.6735;
}

const confettiSpread = (windowHeight, windowWidth) => {
  const x = 0.5 * windowWidth;
  const y = targetHeight * windowHeight;
  const angle = Math.atan(y / x) * 180 / Math.PI;
  return 180 - Math.abs(2 * angle);
}

const numParticles = (windowHeight, windowWidth) => {
  const particleSurface = 0.5 * windowWidth * windowHeight * (1 + targetHeight);
  return particleSurface / 18000;
}

const Confetti = () => {
  const windowWidth = Math.min(500, window.innerWidth);
  const windowHeight = window.innerHeight;

  const speed = confettiSpeed(windowHeight);
  const spread = confettiSpread(windowHeight, windowWidth);
  const numPart = numParticles(windowHeight, windowWidth);
  const partSize = 0.015 * windowHeight;

  const emitter = {
    position: {
      x: 50,
      y: 100
    },
    rate: {
      quantity: numPart,
      delay: 0.05
    },
    life: {
      duration: 0.45,
      count: 3
    },
    spawnColor: {
        value: {
          h: 0,
          s: 95,
          l: 40
        },
        animation: {
          enable: true,
          speed: 0.2,
          sync: false
        }
    }
  };
 
  const config = {
    fullScreen: {
      enable: false,
      zIndex: 15
    },
    emitters: [emitter],
    particles: {
      number: {
        value: 0
      },
      opacity: {
        value: { min: 0, max: 1 },
        animation: {
            enable: true,
            speed: 0.2,
            startValue: "max",
            destroy: "min",
        },
      },
      size: {
        value: partSize
      },
      shape: {
        type: ["character"],
        options: {
          character: [
            {
              fill: true,
              font: "Times",
              value: ipaArr,
              style: "",
              weight: 900
            }
          ]
        }
      },
      bounce: {
        vertical: {
          value: 0.8,
          random: {
            enable: true,
            minimValue: 0.4
          }
        }
      },
      reduceDuplicates: true,
      move: {
        angle: spread,
        decay: 0.1,
        direction: "top",
        enable: true,
        gravity:  {
          enable: true,
          acceleration: 0.25 * 9.81,
        },
        outModes: {
          top: "none",
          default: "destroy"
        },
        speed: {max: 1.15 * speed, min: 0.85 * speed}
      },
      rotate: {
        value: {
          min: 0,
          max: 360
        },
        direction: "random",
        animation: {
          enable: true,
          speed: 60
        }
      },
      tilt: {
        direction: "random",
        enable: true,
        value: {
          min: 0,
          max: 360
        },
        animation: {
          enable: true,
          speed: 60
        }
      },
      wobble: {
        distance: 30,
        enable: true,
        speed: {
          min: -7,
          max: 7
        }
      }
    }
  };

    const particlesInit = useCallback(async engine => {
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {
        //await console.log(container);
    }, []);

    return (
        <Particles id="tsparticles"
          init={particlesInit}
          loaded={particlesLoaded}
          options={config}
          style={{position: "absolute", top: 0, left: 0, height: "100%", width: "100%"}}
        />
    );
}

export default Confetti;