import { useContext, useEffect, useRef, useState } from 'react'
import {setCookie, getCookie, incrCookie, resetCookie, sumCookies, levels} from './cookies.js'
import axios from "axios";
import Guesses from './Guesses'
import GameOverLinks from './GameOver.js'
import LangForm from './LangForm.js'
import './GamePlay.css'
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { getFormattedDate } from './date.js'
import { useUpdateEffect } from 'usehooks-ts'
import AppendErrorBoundary from './errorHandling/AppendErrorBoundary.js';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorMessage from './errorHandling/ErrorMessage.js';
import { alreadyGameOver, lostSomeStreak, partitionGameOver, partitionSuccess } from './end.js';
import { achievementGA, levelEndGA, postScoreGA, selectLangGA, streakAchievedGA } from './Analytics.js';
import { GlobalContext } from './context/globalContext.js';
import { forwardRef } from 'react';
import { getGameOverHeight } from './heights.js';

const baseURL = `${process.env.REACT_APP_API_URL}`;

function removeAlreadyGuessed(cands, guesses) {
    if (!cands || !cands.lid) {
        return cands;
    }
    const guessedLangs = guesses.filter((el, i) => {
        return i % 2 === 0;
    });
    const parallelFilter = (mainArr, followerArr) => {
        return mainArr.reduce((acc, v, i) => !guessedLangs.includes(v) ? (acc[0].push(v), acc[1].push(followerArr[i]), acc) : acc, [[], []]); 
    };
    const [resultLname, resultLid] = parallelFilter(cands.lname, cands.lid);
    return {"lid": resultLid, "lname": resultLname};
}

const GamePlay = forwardRef((props, ref) => {
    const langFormRef = useRef();
    const [openLangMenu, setOpenLangMenu] = useState();

    const [succ, setSucc] = useState(() => getCookie("succ", props.level));
    const [num, setNum] = useState(() => getCookie("num", props.level));
    const [solInfo, setSolInfo] = useState(() => getCookie("info", props.level));
    const [guesses, setGuesses] = useState(() => getCookie("langs", props.level));

    const done = (num >= 6 || succ);
    const [alreadyFinished, setAlReadyFinished] = useState(done);
    const [gameOver, setGameOver] = useState(done);
    const [showGameOver, setShowGameOver] = useState(done);
  
    const nodeRef = useRef(null); 
    //const gameplayRef = useRef

    const animationDuration = 2500;
    const [guessAnimationDuration, setGuessAnimationDuration] = useState(0);

    const { lastVisit } = useContext(GlobalContext);

    useUpdateEffect(() => { // On new day (after midnight modal) or level switch
        let s = getCookie("succ", props.level);
        let n = getCookie("num", props.level);
        let done = n >= 6 || s;
        setSucc(s);
        setNum(n);
        setAlReadyFinished(done);
        setGameOver(done);
        setShowGameOver(done);
        setSolInfo(getCookie("info", props.level));
        setGuesses(getCookie("langs", props.level));
    }, [lastVisit, props.level]);

    const updateStreaks = (maxStr, curStr) => {
        const newCurS = 1 + getCookie(curStr, props.level);
        setCookie(curStr, newCurS, props.level);
        if (newCurS > getCookie(maxStr, props.level)) {
            setCookie(maxStr, newCurS, props.level); 
            streakAchievedGA(maxStr, newCurS, props.level); // TODO: Error in double increment when changing levels?
        }
    }

    useUpdateEffect(() => {
        if (gameOver && !alreadyFinished) {
            if (succ) {
                incrCookie("wins", props.level);
                updateStreaks("maxS", "curS");
                saveScore();
                const succSum = sumCookies("succ", levels);
                if (succSum === 1) {
                    updateStreaks("sMaxS", "sCurS");
                } else if (succSum === levels.length) {
                    updateStreaks("aMaxS", "aCurS");
                }
            } else {
                resetCookie("curS", props.level);
                resetCookie("aCurS");
                if (lostSomeStreak()) {
                    resetCookie("sCurS");
                }
                // TODO: Reset sCurS as soon as we know
            }
            const timer = setTimeout(() => {
                setShowGameOver(true);
            }, animationDuration);
            levelEndGA(props.level, succ);

            return () => clearTimeout(timer);
        }
    }, [gameOver])

    const saveScore = () => {
        var distr = getCookie("distr", props.level);
        distr[num - 1] += 1;
        setCookie("distr", distr, props.level);
    }

    const guessLanguage = async (langName, langID) => {
        const today = getFormattedDate();
        if (today !== lastVisit) {
            setGuessAnimationDuration(Math.min(-1, guessAnimationDuration - 1));
            props.onMidnight();
        } else {
            return axios.get(`${baseURL}/guess`, {params: {lid: langID, lvl: props.level, d: today, n: num}}).then((resp) => {
                const locSucc = resp.data.accuracy == "100";
                const locGameOver = locSucc || num === 5;
                setGameOver(locGameOver);
                if (locSucc) {
                    setSucc(locSucc); 
                    setCookie("succ", locSucc, props.level);
                    postScoreGA(props.level, today, num + 1);
                }
                setCookie("num", num + 1, props.level); 
                setNum(n => n + 1);
                const locGuesses = guesses.concat([langName, resp.data.accuracy])
                setGuesses(locGuesses); 
                setCookie("langs", locGuesses, props.level);
                setGuessAnimationDuration(animationDuration * parseInt(resp.data.accuracy) / 100);

                if (locGameOver) {
                    incrCookie("plays", props.level);
                    setSolInfo(resp.data.info); 
                    setCookie("info", resp.data.info, props.level);
                }

                selectLangGA(props.level, today, langID, langName);
                
                return resp;
            });
        }
    };

    const style = {
        position: "-webkit-sticky",
        position: "sticky",
        bottom: 0,
        height: getGameOverHeight(),
    }

    // Open LangMenu on clicking an unused guess bar
    const onClick = (ev, clickedUnusedGuess) => {
        ev.stopPropagation();
        if (clickedUnusedGuess && !alreadyGameOver()) {
            openLangMenu();
        }
    }

    return (
        <div ref={ref} style={{gap: "var(--standard-p)", display: "grid", height: "fit-content", flexGrow: 0}}>
            <Guesses guesses={guesses} duration={animationDuration} num={num} onClick={onClick} />
            <SwitchTransition mode="out-in" style={{height: "min-content"}}>
                <CSSTransition
                    key={!showGameOver}
                    nodeRef={nodeRef}
                    classNames="fade"
                    timeout={300}
                    unmountOnExit
                    addEndListener={(done) => {
                        nodeRef.current.addEventListener("transitionend", done, false);
                    }}
                >
                    <div ref={nodeRef} style={showGameOver ? {height: getGameOverHeight()} : style}>
                    {
                        !showGameOver 
                        ? 
                        <AppendErrorBoundary >
                            <LangForm ref={langFormRef} cands={removeAlreadyGuessed(props.cands, guesses)} onSubmit={guessLanguage} delay={guessAnimationDuration} num={num} onOpenLangMenu={setOpenLangMenu} />
                        </AppendErrorBoundary>
                        
                        : 
                        <ErrorBoundary FallbackComponent={ErrorMessage}>
                            <GameOverLinks setLevel={props.setLevel} guesses={guesses} succ={succ} info={solInfo} alreadyFinished={alreadyFinished} onMidnight={props.onMidnight} num={num} level={props.level}/>
                        </ErrorBoundary>
                    }
                    </div>
                </CSSTransition>
            </SwitchTransition>
        </div>
    );
})

export default GamePlay