import React, { useState, useEffect, useRef, useReducer } from "react";
import { useUpdateEffect } from "usehooks-ts";
import { useBlobFetch } from "./useCacheFetch";

const useAudioPreload = (url) => {

    const [loaded, setLoaded] = useState(false);
    const [playing, setPlaying] = useState(false);
    const [audio, setAudio] = useState(new Audio());

    const toggleAudio = () => setPlaying(!playing);

    const effect = (dest) => {
        setLoaded(true);
        audio.src = dest;
        audio.load();
    };

    const errorEffect = (error) => {
        setLoaded(false);
    };     

    useUpdateEffect(() => {
        setPlaying(false); // Before setAudio!!! i.e. before useBlobFetch
        audio.pause(); // Before setAudio!!! i.e. before useBlobFetch
        setLoaded(false);
    }, [url]);

    const {status, error} = useBlobFetch(url, effect, errorEffect);

    useUpdateEffect(() => {
        audio.addEventListener("ended", () => setPlaying(false));
        audio.addEventListener("loadeddata", () => setLoaded(audio.readyState >= 2));
        return () => {
            audio.removeEventListener("ended", () => setPlaying(false));
            audio.removeEventListener("loadeddata", () => setLoaded(audio.readyState >= 2));
        };
    }, [audio.src]);
  
    useUpdateEffect(() => {
        if (playing) {
            audio.play();
        } else {
            audio.pause();
        }
    }, [playing]);
    
    return [loaded, playing, toggleAudio];
};

export const useAudio = (url) => {

    const [loaded, setLoaded] = useState(false);
    const [playing, setPlaying] = useState(false);
    const [audio, setAudio] = useState(new Audio());
    const [fetchUrl, setFetchUrl] = useState(undefined);

    const toggleAudio = () => { // triggerLoad
        if (playing) {
            setPlaying(false);
        } else if (loaded) {
            setPlaying(true);
        } else {
            setFetchUrl(url);
        }
        //setPlaying(!playing);
    }

    const effect = (dest) => {
        setLoaded(true);
        audio.src = dest;
        audio.load();
    };

    const errorEffect = (error) => {
        setLoaded(false);
    };    
        
    const {status, error} = useBlobFetch(fetchUrl, effect, errorEffect);

    useUpdateEffect(() => {
        setFetchUrl(undefined);
    }, [url]);

    useUpdateEffect(() => {
        setPlaying(false); // Before setAudio!!! i.e. before useBlobFetch
        audio.pause(); // Before setAudio!!! i.e. before useBlobFetch
        setLoaded(false);
    }, [fetchUrl]);

    useUpdateEffect(() => {
        audio.addEventListener("ended", () => setPlaying(false));
        audio.addEventListener("loadeddata", () => setLoaded(audio.readyState >= 2));
        return () => {
            audio.removeEventListener("ended", () => setPlaying(false));
            audio.removeEventListener("loadeddata", () => setLoaded(audio.readyState >= 2));
        };
    }, [audio.src]);

    useUpdateEffect(() => {
        if (loaded) {
            setPlaying(true);
        }
    }, [loaded]);
  
    useUpdateEffect(() => {
        if (playing) {
            audio.play();
        } else {
            audio.pause();
        }
    }, [playing]);
    
    return [loaded, playing, toggleAudio];
};

export default useAudio