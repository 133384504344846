import { useEffect, useState, Children  } from 'react';
import GuessBar from './GuessBar'
import styled from "styled-components";

export const GuessBox = styled.div`
    display: grid;
    width: 100%; 
    grid-template-columns: 1fr;
    gap: var(--standard-p);
    text-align: center;
`;

const Guesses = ({ guesses, duration, num, onClick }) => {  
    return (
        <GuessBox onClick={onClick}>
            {Array(6).fill(true).map((_, i) => 
                (num > i
                    ? <GuessBar key={i} lang={guesses[2*i]} acc={guesses[2*i + 1] / 100} duration={duration} />
                    : <GuessBar key={i} id={"unused-guess-" + i} onClick={(ev) => onClick(ev, true)} />
                )
            )}
        </GuessBox>
    );
}

export default Guesses