import {barHeight, padding} from "./Layout"

const headerHeight = 2 * 1.5 * padding + 2.4;
// H = 2 * [top/bot header padding] + [outer FontAwesome span height]
//   = 2 * 1.5sp + [font multiplier header] * 1.2u
//   = 0.75u + 2.4u
//   = 3.15u

const footerHeight = 2 * padding + 1.25 + padding;
// H = 2 * [top/bot footer padding] + [footer FontAwesome svg height] + [footer top margin]
//   = 2sp + [font multiplier footer] * 1.25u + 1sp
//   = 0.5u + 1.25u + 0.25u
//   = 2u

const guessesHeight = 6 * barHeight + 5 * padding;
// H = 6 * [bar height] + 5 * [gap]
//   = 6sh + 5 * 0.25u
//   = 12u + 1.25u
//   = 13.25u

const langFormHeight = 2 * barHeight + padding;
// H = 2 * [bar height] + [gap]
//   = 2sh + 1sp
//   = 4u + 0.25u
//   = 4.25u

const gameOverHeight = 2 * 1.15 + barHeight + 3 * padding;
// H = 2 * [text line height] + [bar height] + 2 * [gap] + [top padding]
//   = 2 * 1.15u + sh + 2 * 0.25u + 0.25u
//   = 2.3u + 2u + 0.5u + 0.25u 
//   = 5.05u

const gamePlayHeight = guessesHeight + padding + Math.max(langFormHeight, gameOverHeight);
// H = [guessesHeight] + [gap] + max([langFormHeight], [gameOverHeight])
//   = 13.25u + 0.25u + max(4.25u, 5.05u)
//   = 13.5u + 5.05u
//   = 18.55u

export const getGameOverHeight = () => {
    return `calc((${gameOverHeight}) * var(--textFontSize))`;
}

export const getSampleHeight = (appHeight) => {
    const otherElemsHeight = headerHeight + gamePlayHeight + footerHeight;
    const margins = 4 * padding; // top and bottom sample margin + top sample padding + sample-guesses gap;
    //console.log( `calc(${appHeight}px - ${otherElemsHeight + margins} * var(--textFontSize))`);

    return `calc(${appHeight}px - ${otherElemsHeight + margins} * var(--textFontSize))`;
}

export const getInnerSampleHeight = (appHeight) => {
    const otherElemsHeight = headerHeight + gamePlayHeight + footerHeight;
    const margins = 10 * padding; // as above + twice 3 padding/margin 

    return `calc(${appHeight}px - ${otherElemsHeight + margins} * var(--textFontSize) - 1px)`; //rounding error
}