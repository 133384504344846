import React, { useEffect, useReducer, useRef } from "react";
import axios from "axios";

export const useJsonFetch = (url, effect, errorEffect) => useCacheFetch(url, effect, errorEffect, "json");
export const useBlobFetch = (url, effect, errorEffect) => useCacheFetch(url, effect, errorEffect, "blob");

const useCacheFetch = (url, effect, errorEffect, responseType = "json") => {

	const cache = useRef({});

    const initialState = {
        status: "idle",
        error: null,
        data: [],
    };
    
    const [state, dispatch] = useReducer((state, action) => {
        switch (action.type) {
            case "FETCHING":
                return { ...initialState, status: "fetching" };
            case "FETCHED":
                effect(action.payload);
                return { ...initialState, status: "fetched", data: action.payload };
            case "FETCH_ERROR":
                errorEffect(action.payload);
                return { ...initialState, status: "error", error: action.payload };
            default:
                return state;
        }
    }, initialState);
    
    useEffect(() => {
        let cancelRequest = false;
        if (!url) {
            return;
        }
    
        const fetchData = async () => {
            dispatch({ type: "FETCHING" });
            if (cache.current[url]) {
                dispatch({ type: "FETCHED", payload: cache.current[url] });
            } else {
                await axios.get(url, {responseType: responseType}).then(async resp => {
                    let data = resp.data;
                    if (responseType === "blob") {
                        data = URL.createObjectURL(resp.data);
                    }
                    cache.current[url] = data;
                    if (cancelRequest) {
                        return;
                    }
                    dispatch({ type: "FETCHED", payload: data });
                    //console.log("got here inside non-error cacheFetch:", responseType);
                }).catch((error) => {
                    //console.log("got here inside error cacheFetch:", responseType);
                    if (cancelRequest) {
                        return;
                    }
                    dispatch({ type: "FETCH_ERROR", payload: error.message });
                });
            }
        };
    
        fetchData();
    
        return function cleanup() {
            cancelRequest = true;
        };
    }, [url]);
    
    return state;
}

export default useCacheFetch