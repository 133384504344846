import styled from "styled-components";
import { Flex } from "./styles/globalStyles";
import Logo from "./Logo";
import LetterFade from "./drawing/LetterFade";
// import useWindowWidth, { useWindowHeight } from "./hooks/useWindowWidth";
// import { useEffect } from "react";
// import { useState } from "react";
// import { useUpdateEffect } from "usehooks-ts";
//import { FitToViewport } from "react-fit-to-viewport";
import { Outlet } from "react-router-dom";
//import { lightBlueTheme } from "./styles/themes";
//import AppFooter from "./AppFooter";
//import { Autoscaler, CustomAutoscaler } from "./Autoscaler";

export const logoFontSize = 8; // 8vh
export const smallerFontSize = 2.5; // roughly 8vh / 3
const heightFragment = 0.15; //for LetterFade
const headerContentStart = 0.08; // for InnerHeader positioning

const InnerScreenHeader = styled.header`
    background-color: ${props => props.theme.plainbg};
    grid-column: 1 / 4;
    display: flex;
    gap: var(--standard-p);
    flex-direction: column;
    @supports (-webkit-touch-callout: none) and (not (translate: none)) { // If flex gap is not supported in iOS/Safari
        & > *:not(:last-child) {
            margin-bottom: var(--standard-p));
        }
    }
    z-index: 100;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: fit-content;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    overflow: hidden;
    padding-bottom: calc(2 * var(--standard-p));
`;

export const minVWFontSize = 23;

const TagLine = styled.p`
    font-family: Ubuntu;
    //font-weight: 600;
    font-size: min(${smallerFontSize}vh, ${minVWFontSize / logoFontSize * smallerFontSize * 0.8}vw);
    z-index: 10;
`;

const tagLine1 = "Daily language guessing game";
const tagLine2 = "Can you beat all 3 levels?";

const LogoWrapper = styled.div` 
    font-size: min(${logoFontSize}vh, ${minVWFontSize}vw);
    height: calc(1.5 * min(${logoFontSize}vh, ${minVWFontSize}vw)); // = 1.5 * font-size
    width: fit-content;
`;

export const InnerScreenWithHeader = ({plugin}) => {
    return (
        <>
            <InnerScreenHeader >
                <LetterFade heightFragment={heightFragment} headerContentStart={`${headerContentStart * 100}vh`} direction="top" />
                {/* <CustomAutoscaler width="unset" minWidth="unset" maxWidth="90vw"> */}
                    <LogoWrapper> 
                        <Logo />
                    </LogoWrapper>
                {/* </CustomAutoscaler> */}
                <TagLine>{tagLine1}</TagLine>
                <TagLine>{tagLine2}</TagLine>
            </InnerScreenHeader>
            {plugin}
        </>
    );
}

const InnerScreenFooter = styled(Flex)`
    background-color: ${props => props.theme.plainbg};
    height: fit-content;
    width: 100%;
    grid-column: 2;
    //grid-row: 2;
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
    justify-self: center;
    z-index: 1;
`;

const ScrollElem = styled.div`
    overflow: auto;
    grid-column: 1 / 4;
    grid-row: 2;
`;

const Column80 = styled.div`
    height: 100%; //minus header 
    display: grid;
    grid-template-columns:
        1fr
        80%
        1fr;
`;

const InnerScreenBody = ({pageTitle, children, fontSize}) => {
    return (
        <ScrollElem>
            <Column80>
                <Flex $col style={{gridRow: 1, gridColumn: 2}} > 
                    {pageTitle && <h2 style={{fontSize: "var(--textFontSize)", textAlign: "center"}}>{pageTitle}</h2>}
                    {children}
                </Flex>
            </Column80>
        </ScrollElem>
    )
}

export const InnerScreenRoute = () => {
    return (
        <InnerScreenWithHeader plugin={<Outlet/>} />
    );
}

const InnerScreen = ({height, pageTitle, children, footer}) => {
    return (
        <>
            <InnerScreenBody pageTitle={pageTitle} children={children} fontSize={smallerFontSize} />
            <InnerScreenFooter>
                {footer}
            </InnerScreenFooter>
            {/* <AppFooter /> */}
        </>
    );
}

export default InnerScreen