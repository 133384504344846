import { GuessBox } from "../Guesses"
import { Skeleton } from "@mui/material";
import { Bar } from "../styles/globalStyles";

const GuessesSkeleton = ({errorComp, ...props}) => {
    return (
        <>
            <GuessBox >
                {Array(6).fill(true).map((_, i) => 
                    <Skeleton variant="rounded" width="100%" key={i} {...props}>
                        <Bar />
                    </Skeleton>
                )}
            </GuessBox>
            {errorComp}
        </>
    );
}

export default GuessesSkeleton