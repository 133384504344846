import { getCookie, levels } from './cookies.js'

const takenMaxGuesses = (lvl) => getCookie("num", lvl) >= 6;

export const alreadyGameOver = (lvl) => {
    return getCookie("succ", lvl) === true || takenMaxGuesses(lvl);
}

export const lostSomeStreak = () => {
    return !levels.some((lvl) => getCookie("succ", lvl) || !takenMaxGuesses(lvl));
}

const partitionArr = (arr, cond) => {
    const res = [[], []];
    arr.forEach(item => res[Number(cond(item))].push(item));
    return res;
};

export const partitionSuccess = () => partitionArr(levels, lvl => getCookie("succ", lvl));
export const partitionGameOver = () => partitionArr(levels, alreadyGameOver);

// export const listSuccesses = () => [false].concat(levels.map((lvl) => getCookie("succ", lvl)));
// export const listGameOvers = () => [false].concat(levels.map((lvl) => alreadyGameOver(lvl)));

// export const successLevels = () => levels.filter((lvl) => getCookie("succ", lvl));
