import {useContext, useEffect, useState} from "react";
import Modal, { MiniModal } from "../Modal";
import { ButtonGroup, Container, Flex, FullWidthContainer, Paragraph, ThemedBarButton } from "../styles/globalStyles";
import ToggleSwitch from "../ToggleSwitch";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { levels, getCookie, setCookie, resetCookies, allowAllCookies, allowNoCookies, allowOnlyEssentialCookies, isCookieSet } from "../cookies";
import { withTheme } from "styled-components"
import { getDarkestColor } from "../styles/colors";
import { Alert, AlertTitle, Button } from "@mui/material";
import { DimensionsContext } from "../context/dimensionsContext";

const essentialCookiesText = "Essential cookies enable core functionality such as security, network management, and accessibility, as well as maintaining progress and personal statistics between browser refreshes. Disabling these cookies may affect how the website functions.";

const analyticsCookiesText = "We'd like to collect website analytics to help us improve the website and gather statistics on language knowledge and interest across the globe. The data is collected in a way that does not directly identify anyone.";

const summaryText = "This website's functionality depends on essential cookies. With your consent, we may also use analytics cookies to analyse website traffic and improve user experience. ";

const miniModalAddon = "By clicking 'Accept', you agree to our cookie use as described in our cookie policy. You can review your cookie preferences at any time via 'Settings' in the top-right corner.";

const bigModalAddon = "You can change your cookie preferences here at any time via 'Settings' in the top-right corner. By accepting essential and/or analytics cookies, you agree to the following.";

const UnThemedCookieList = (props) => {
    const cookie = getCookie("c");
    const [essential, setEssential] = useState(allowOnlyEssentialCookies <= cookie);
    const [analytics, setAnalytics] = useState(allowAllCookies <= cookie);

    const { appWidth, appHeight } = useContext(DimensionsContext);

    const toggleSize =  //TODO: Turn into hook
        appWidth / appHeight >= 10 / 22
            ? appHeight / 100 * 2.5
            : appWidth / 100 * 5.5;
    const fontSize = 7/8 * toggleSize;

    useEffect(() => {
        if (props.essential !== undefined && props.analytics !== undefined) {
            setVals(props.essential, props.analytics);
            props.setWarningStatus(["none", undefined, undefined]);
        }
    }, [props.essential, props.analytics])

    const handleToggle = (essentialVal, analyticsVal) => {
        if (essentialVal || !essential) {
            setVals(essentialVal, analyticsVal);
        } else {
            props.setWarningStatus(["pending", essentialVal, analyticsVal]);
        }
    }

    const setVals = (essentialVal, analyticsVal) => {
        const realEssentialVal = essentialVal || !essential; 
        const realAnalyticsVal = analyticsVal && !analytics;
        setEssential(realEssentialVal);
        setAnalytics(realAnalyticsVal);
        setCookie("c", realEssentialVal + realAnalyticsVal);
        if (realEssentialVal && !essential) {
            setCookie("lvl", props.level);
            //console.log("setting level to", props.level);
        }
        //console.log("set c to", realEssentialVal + realAnalyticsVal);
    }

    const essentialCookiesHeader = 
        <Flex $row justifyContent="space-between">
            <strong>Essential cookies</strong>
            <ToggleSwitch size={toggleSize} defaultValue={essential} onChange={(val) => handleToggle(val, analytics)} />
        </Flex>;

    const analyticsCookiesHeader = 
        <Flex $row justifyContent="space-between">
            <strong>Analytics cookies</strong>
            <ToggleSwitch size={toggleSize} defaultValue={analytics} onChange={(val) => handleToggle(essential, val)} />
        </Flex>;
        
  
    return (
        <FullWidthContainer $thinTop $thinBottom>
            <Container $noTop $thinBottom>
                {essentialCookiesHeader}
                <Paragraph>
                    {essentialCookiesText}
                </Paragraph>
            </Container>
            <Container $noBottom $thinTop>
                {analyticsCookiesHeader} 
                <Paragraph>
                    {analyticsCookiesText}
                </Paragraph>
            </Container>
        </FullWidthContainer>
    );
}

const CookieList = withTheme(UnThemedCookieList);

const resetWarningText = (totPlays) => `You will lose all your progress, including statistics for the ${totPlays} ${totPlays === 1 ? "game" : "games"} you have played.`;

const UnthemedResetWarning = (props) => {
    const totPlays = levels.map(lvl => getCookie("plays", lvl)).reduce((a, b) => a + b); 
    const warningText = resetWarningText(totPlays);

    const action = 
        <ButtonGroup $col>
            <Button color="inherit" size="small" onClick={props.onCommit}>
                Lose it
            </Button>
            <Button color="inherit" size="small" onClick={props.onRevert}>
                Keep it
            </Button>
        </ButtonGroup>;

    return (
        <Alert severity="warning" action={action} style={props.style} sx={{color: getDarkestColor(props), backgroundColor: props.theme.name === "light" ? props.theme.white : props.theme.medbg}}> 
            <AlertTitle>Are you sure?</AlertTitle>
            {warningText}
        </Alert>
    );
}

const ResetWarning = withTheme(UnthemedResetWarning);

export const useCookiePolicy = ({ setIsOpen, level }) => {
    const hasUsedCookies = levels.some(lvl => isCookieSet("plays", lvl)); //TODO: Can we ask if "c" is set instead?
    const [showResetWarning, setShowResetWarning] = useState(false);
    const [essentialVal, setEssentialVal] = useState(undefined);
    const [analyticsVal, setAnalyticsVal] = useState(undefined);
    const [warningStatus, setWarningStatus] = useState(["none", undefined, undefined]);

    const onCommit = () => {
        const [status, essVal, anaVal] = warningStatus;
        if (status === "pending") {
            setEssentialVal(essVal);
            setAnalyticsVal(anaVal);
            setWarningStatus(["none", undefined, undefined]);
        }
        setCookie("c", allowNoCookies);
        resetCookies();
        setShowResetWarning(false);
    }

    const onRevert = () => {
        const [status, essVal, anaVal] = warningStatus;
        if (status === "pending") {
            setWarningStatus(["revert", undefined, undefined]);
        }
        setShowResetWarning(false);
    }

    useEffect(() => {
        const [status, essVal, anaVal] = warningStatus;
        if (status === "revert") {
            setWarningStatus(["none", undefined, undefined]);
        } else if (status === "none") {
            setEssentialVal(undefined);
            setAnalyticsVal(undefined);
        } else if (status === "pending") {
            handleReject();
        }
    }, [warningStatus])
    
    const handleAccept = () => {
        if (getCookie("c") === allowNoCookies) {
            setCookie("lvl", level);
            //console.log("setting level to", level);
        }
        setCookie("c", allowAllCookies);
        if (setIsOpen) {
            setIsOpen(false);
        }
    }

    const handleReject = () => {
        if (hasUsedCookies) {
            setShowResetWarning(true);
        } else {
            onCommit();
        }
    }
    
    const cookiePolicy = 
        <>
            <Paragraph>{summaryText}{bigModalAddon}</Paragraph>
            <CookieList setWarningStatus={setWarningStatus} essential={essentialVal} analytics={analyticsVal} level={level} />
            {showResetWarning && <ResetWarning onCommit={onCommit} onRevert={onRevert} />}
        </>;

    return [cookiePolicy, handleAccept, handleReject]
};

const CookiesModal = (props) => {
    const [cookiePolicy, handleAccept, handleReject] = useCookiePolicy(props);

    const buttons = 
        <>
            <ThemedBarButton width="fit-content" onClick={handleAccept} >Accept all</ThemedBarButton>
            {/* <ThemedBarButton width="fit-content" onClick={() => {setWarningStatus(["pending", false, false]); handleReject();}}>Reject all</ThemedBarButton> */}
            <ThemedBarButton width="fit-content" onClick={() => props.setIsOpen(false)} >Save</ThemedBarButton>
        </>;

    return (
        <Modal setIsOpen={props.setIsOpen} title={"Our cookie policy"} buttons={buttons}>
            {cookiePolicy}
        </Modal>
    );
};

export const CookiesMiniModal = ({ setIsOpen, openCookiesModal, level }) => {
    const hasUsedCookies = levels.some(lvl => isCookieSet("plays", lvl));
    const [showResetWarning, setShowResetWarning] = useState(false);

    const onCommit = () => {
        setCookie("c", allowNoCookies);
        setIsOpen(false);
        resetCookies();
    }

    const onRevert = () => {
        setShowResetWarning(false);
    }

    const style = {
        fontSize: `calc(0.75 * var(--textFontSize))`
    }

    const content = 
        <FullWidthContainer $col>
            <p style={style}>{summaryText}</p>
            <p style={style}>{miniModalAddon}</p>
        </FullWidthContainer>;

    const handleAccept = () => {
        if (getCookie("c") === allowNoCookies) {
            setCookie("lvl", level);
            //console.log("setting level to", level);
        }
        setCookie("c", allowAllCookies); 
        setIsOpen(false);
    }

    const handleReject = () => {
        if (hasUsedCookies) {
            setShowResetWarning(true);
        } else {
            onCommit();
        }
    }

    const buttons = 
        <>
            <ThemedBarButton width="100%" onClick={handleAccept}>Accept</ThemedBarButton>
            <ThemedBarButton width="max-content" onClick={handleReject}>Decline</ThemedBarButton>
            <ThemedBarButton width="100%" onClick={() => {handleAccept(); openCookiesModal(true);}}>Review</ThemedBarButton>
        </>;

    const footer = showResetWarning && <ResetWarning onCommit={onCommit} onRevert={onRevert} style={{width: "100%", textAlign: "left"}}/>;
    
    return (
        <MiniModal setIsOpen={setIsOpen} close={handleAccept} placement="bottom" buttons={buttons} footer={footer}>
            {content}
        </MiniModal> 
    );
}

export default CookiesModal;