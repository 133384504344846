import { ErrorBoundary } from "react-error-boundary";
import ErrorMessage from "./ErrorMessage";

const AppendErrorBoundary = (props) => {
    const fallbackRender = ({error, resetErrorBoundary}) => 
        <>
            {props.children}
            <ErrorMessage error={error} resetErrorBoundary={resetErrorBoundary} />
        </>;

    return (
        <ErrorBoundary fallbackRender={fallbackRender} {...props} />
    );
}

export default AppendErrorBoundary