import styled, { css, withTheme } from "styled-components";
import Button from "@mui/material/Button";
import { getFGColor, newShade } from "./colors.js"
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro.js";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useRef } from "react";
import { smallerFontSize } from "../InnerScreen";

export const Bar = styled.div`
    height: var(--standard-h);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding-left: var(--standard-p);
    padding-right: var(--standard-p);
    border-radius: var(--standard-p);
    background-color: ${props => props.color};
    color: ${props => getFGColor(props, props.color)}; 
`;

const BarText = ({children}) => {
  return <div style={{lineHeight: 1, paddingTop: "2px", whiteSpace: "nowrap"}}><strong>{children}</strong></div>;
}

export const PlayButton = ({onClick}) => {
  return (
      <ThemedBarButton 
          width="fit-content" 
          onClick={onClick} 
          startIcon={
              <div style={{width: "fit-content"}}>
                  <FontAwesomeIcon icon={icon({name: "play"})} />
              </div>} 
      >
          play
      </ThemedBarButton>
  );
}

export const HomeButton = ({onClick}) => {
  return (
      <ThemedBarButton 
          width="fit-content" 
          onClick={onClick} 
          startIcon={
              <div style={{width: "fit-content"}}>
                  <FontAwesomeIcon icon={icon({name: "house"})} />
              </div>} 
      >
          home
      </ThemedBarButton>
  );
}

export const BackButton = ({onClick}) => {
  return (
      <ThemedBarButton 
          width="fit-content" 
          onClick={onClick} 
          startIcon={
              <div style={{width: "fit-content"}}>
                  <FontAwesomeIcon icon={icon({name: "arrow-left-long"})} />
              </div>} 
      >
          back
      </ThemedBarButton>
  );
}

const FlexButton = styled(Button)`
  flex-grow: 1;
`

const BarButton = (props) => {
  return (
    <FlexButton 
      //as={Button} 
      onClick={props.onClick}
      type={props.type || "button"}
      disabled={props.disabled || false}
      variant={props.variant || "contained"}
      startIcon={props.startIcon || ""}
      endIcon={props.endIcon || ""}
      sx={{
        width: props.width || "100%",
        height: props.height ||"var(--standard-h)",
        borderRadius: props.borderRadius || "var(--standard-p)",
        backgroundColor: props.theme.darkbg,
        fontSize: props.fontSize || "inherited",
        //lineHeight: 1,
        color: getFGColor(props, props.theme.darkbg),
        "&:hover": {
            backgroundColor: newShade(props.theme.darkbg, 15),
        },
        "&:disabled": {
            backgroundColor: newShade(props.theme.darkbg, 35),
            color: getFGColor(props, props.theme.darkbg),
        }
      }}>
          <Flex $row $auto width={props.width || "fit-content"} >
              <BarText>
                {props.children}
              </BarText>
          </Flex>
      </FlexButton>
  );
}

export const ThemedBarButton = withTheme(BarButton);

export const ButtonGroup2 = (props) => {
    const h = "90%";
    const n = props.children.length;
    const gap = props.$buttonGapRatio ? `100% / (${n} * ${props.$buttonGapRatio} + ${n})` : undefined;
    //const buttonHeight = props.$buttonGapRatio ? `(100% - ${n} * ${gap}) / ${n}` : undefined;
    const fontSize = `50px`; //`calc(${buttonHeight} / var(--standard-h) * 100%)`;//`calc(${buttonHeight} / var(--standard-h) * ${smallerFontSize})`;

    return (
        props.$col 
            ? <Flex2 $col height={h} justifyContent="space-around" $gap={`calc(${gap})`} $fontSize={fontSize}>{props.children}</Flex2>
            : <Flex2 $row justifyContent="space-evenly">{props.children}</Flex2>
    );
}


export const ButtonGroup = (props) => {
  const groupRef = useRef();

  useEffect(() => {
    // if (groupRef.current?.offsetWidth) {
    //   console.log("width of buttonGroup:", groupRef.current?.offsetWidth);
    // }
  }, [props.toFit])

  return (
      <Container height="100%" width="100%" ref={groupRef}>
        {props.$col 
          ? <Flex $col height="100%" justifyContent="space-evenly" $gap={props.$gap}>{props.children}</Flex>
          : <Flex $row justifyContent="space-evenly">{props.children}</Flex>
        }
      </Container>
  );
}

export const Paragraph = styled.p`
  // margin: ${(props) => props.$col ? "auto 0" : "0 auto"};
  // padding-top: calc(${(props) => props.$noTop ? 0 : props.$thinTop ? 1 : 3} * var(--standard-p));
  // padding-bottom: calc(${(props) => props.$noBottom ? 0 : props.$thinBottom ? 1 : 3} * var(--standard-p));
  // padding-left: calc(${(props) => props.$noLeft ? 0 : props.$thinLeft ? 1 : 3} * var(--standard-p));
  // padding-right: calc(${(props) => props.$noRight ? 0 : props.$thinRight ? 1 : 3} * var(--standard-p));
  width: ${props => props.width ? props.width : "fit-content"};
  height: ${props => props.height ? props.height : "fit-content"};
  text-align: left;
  //text-justify: inter-word;
  justify-content: center;
  align-content: center;
  line-height: 1.43;
`;

export const FlexParagraph = styled(Paragraph)`
  font-size: round(clamp(1rem, 2.5vw, 1rem));
`;

export const Container = styled.div`
  margin: ${(props) => props.$col ? "auto 0" : "0 auto"};
  padding-top: calc(${(props) => props.$noTop ? 0 : props.$thinTop ? 1 : 3} * var(--standard-p));
  padding-bottom: calc(${(props) => props.$noBottom ? 0 : props.$thinBottom ? 1 : 3} * var(--standard-p));
  padding-left: calc(${(props) => props.$noLeft ? 0 : props.$thinLeft ? 1 : 3} * var(--standard-p));
  padding-right: calc(${(props) => props.$noRight ? 0 : props.$thinRight ? 1 : 3} * var(--standard-p));
  width: ${props => props.width ? props.width : "fit-content"};
  height: ${props => props.height ? props.height : "fit-content"};
  justify-content: center;
  align-content: center;
  line-height: 1.43;
`;

export const Flex2 = styled.div` 
  font-size: ${(props) => props.$fontSize ? `${props.$fontSize} !important` : "inherited"};
  display: flex;
  gap: ${(props) => props.$gap || `calc( ${props.$gapWidth || 1} * var(--standard-p))`};
  ${(props) =>
    props.$row && 
    css`
      flex-direction: row;
      align-items: center;
      justify-content: center;
      @supports (-webkit-touch-callout: none) and (not (translate: none)) { // If flex gap is not supported in iOS/Safari
        & > *:not(:last-child) {
          margin-right: ${(props) => props.$gap || `calc( ${props.$gapWidth || 1} * var(--standard-p))`};
        }
      }
    `}
  ${(props) =>
    props.$col &&
    css`
      flex-direction: column;
      @supports (-webkit-touch-callout: none) and (not (translate: none)) { // If flex gap is not supported in iOS/Safari
        & > *:not(:last-child) {
          margin-bottom: ${(props) => props.$gap || `calc( ${props.$gapWidth || 1} * var(--standard-p))`};
        }
      }
    `};
  ${(props) => 
    props.width &&
    css`
      width: ${props.width};
    `};
  ${(props) => 
    props.height &&
    css`
      height: ${props.height};
    `};
  ${(props) => 
    props.alignItems &&
    css`
      align-items: ${props.alignItems};
    `};
  ${(props) => 
    props.justifyContent &&
    css`
      justify-content: ${props.justifyContent};
    `};
`;

export const Flex = styled.div` 
  display: flex;
  gap: ${(props) => props.$gap || `calc( ${props.$gapWidth || 1} * var(--standard-p))`};
  ${(props) =>
    props.$row && 
    css`
      flex-direction: row;
      align-items: center;
      justify-content: center;
      @supports (-webkit-touch-callout: none) and (not (translate: none)) { // If flex gap is not supported in iOS/Safari
        & > *:not(:last-child) {
          margin-right: ${(props) => props.$gap || `calc( ${props.$gapWidth || 1} * var(--standard-p))`};
        }
      }
    `}
  ${(props) =>
    props.$col &&
    css`
      flex-direction: column;
      @supports (-webkit-touch-callout: none) and (not (translate: none)) { // If flex gap is not supported in iOS/Safari
        & > *:not(:last-child) {
          margin-bottom: ${(props) => props.$gap || `calc( ${props.$gapWidth || 1} * var(--standard-p))`};
        }
      }
    `};
  ${(props) => 
    props.width &&
    css`
      width: ${props.width};
    `};
  ${(props) => 
    props.height &&
    css`
      height: ${props.height};
    `};
  ${(props) => 
    props.alignItems &&
    css`
      align-items: ${props.alignItems};
    `};
  ${(props) => 
    props.justifyContent &&
    css`
      justify-content: ${props.justifyContent};
    `};
`;

