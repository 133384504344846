const Brace = () => { // width was 25px, now scales to the width of the app column or at least 25px
    return (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="max(min(3.3vh, 5vw), 25px)" height="100%" viewBox="0 0 1276.000000 3639.000000" preserveAspectRatio="none">
            <g transform={`translate(0.000000,3639.000000) scale(0.100000,-0.100000)`} fill="currentColor" stroke="none">
                <path d="M11565 36383 c-1277 -50 -2262 -187 -3140 -438 -1620 -463 -2785 -1357 -3421 -2628 -460 -917 -708 -2050 -774 -3527 -6 -138 -10 -1412 -10 -3290 0 -2903 -5 -3498 -30 -3840 -79 -1084 -301 -1840 -705 -2405 -536 -749 -1290 -1099 -2625 -1220 -91 -8 -321 -20 -512 -27 l-348 -12 0 -801 0 -801 348 -12 c632 -22 1079 -76 1507 -183 985 -245 1554 -730 1954 -1666 203 -475 326 -1062 381 -1812 25 -346 30 -923 30 -3831 0 -1878 4 -3152 10 -3290 85 -1904 480 -3272 1231 -4261 138 -181 262 -320 455 -509 1040 -1019 2563 -1587 4741 -1769 545 -46 934 -61 1591 -61 l512 0 0 795 0 795 -222 0 c-405 0 -984 28 -1353 66 -714 72 -1331 239 -1752 472 -399 222 -724 547 -984 983 -355 595 -578 1589 -639 2844 -6 121 -13 249 -16 285 -3 36 -9 1264 -13 2730 -6 1701 -13 2727 -19 2835 -52 805 -110 1301 -217 1843 -125 629 -278 1096 -511 1563 -252 506 -556 927 -979 1358 -577 588 -1246 1031 -2122 1406 l-113 48 0 172 0 172 157 68 c864 374 1548 819 2093 1365 606 605 1055 1375 1306 2237 150 518 262 1124 328 1788 16 159 39 455 57 745 7 114 14 1107 20 2845 4 1469 10 2699 13 2735 3 36 10 164 16 285 61 1256 284 2249 639 2844 262 440 586 762 992 987 415 230 1034 396 1744 469 363 36 949 65 1353 65 l222 0 0 795 0 795 -562 -2 c-310 -1 -594 -3 -633 -5z"/>
            </g>
        </svg>
    );
}

export default Brace

