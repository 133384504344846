import React, { useState } from "react";
import { clearDailyCookies, getCookie, levels, resetCookie, setCookie } from "../cookies";
import { addDays, getFormattedDate } from "../date";
import { partitionSuccess } from "../end";
import { LangleError } from "../errorHandling/LangleError";

export const GlobalContext = React.createContext({
    currentTheme: "",
    themeSwitchHandler: () => {},
});

const selectTheme = () => {
    if (window.localStorage.theme === "dark" || (!("theme" in window.localStorage) && window.matchMedia("(prefers-color-scheme: dark)").matches)) {
        return "dark";
    } 
    return "light";
}

const GlobalContextProvider = (props) => {
    const [currentTheme, setCurrentTheme] = useState(selectTheme());
    const [lastVisit, setLastVisit] = useState(getCookie("last"));

    const onRollOver = () => {
        const tomorrow = getFormattedDate(addDays(1));
        const yesterday = getFormattedDate(addDays(-1));
        const today = getFormattedDate();
        const lastPlayedTomorrow = (lastVisit === tomorrow);
        const lastPlayedYesterday = (lastVisit === yesterday);
        const lastPlayedToday = (lastVisit === today);
        if (lastPlayedYesterday) { 
            const [failLevels, succLevels] = partitionSuccess();
            failLevels.forEach((lvl) => {
                resetCookie("curS", lvl);
            });
            const numSuccesses = succLevels.length;
            if (numSuccesses < levels.length) {
                resetCookie("aCurS");
                if (numSuccesses < 1) {
                    resetCookie("sCurS");
                }
            }
        } else if (lastPlayedTomorrow) {
            throw new LangleError("TimeTravelError", "Greetings, time traveller", "You seem to have started tomorrow's game already. Did you cross the International Date Line or did you time travel? Don't worry, Langle will unlock again after midnight in your current timezone.");
        } else if (lastPlayedToday) {
            console.log("Welcome back to today's game"); // This shouldn't happen
        } else { // It's later than tomorrow
            levels.forEach((lvl) => {
                resetCookie("curS", lvl);
            });
            resetCookie("sCurS");
            resetCookie("aCurS");
        }
        
        clearDailyCookies();
        setCookie("last", today);
        setLastVisit(today);
        return today;
    }

    return (
        <GlobalContext.Provider
            value={{
                theme: currentTheme,
                themeSwitchHandler: setCurrentTheme,
                lastVisit: lastVisit,
                onRollOver: onRollOver,
            }}
        >
        {props.children}
        </GlobalContext.Provider>
    );
};

export default GlobalContextProvider;