
import Modal from "../Modal";
import { solutionTabContents } from "../Solution";
import { levels, getCookie, areCookiesAllowed } from "../cookies";
import { TabsUnder } from "../Tabs";
import { alreadyGameOver } from "../end";

export const SolutionTabs = (props) => {
    const cookiesAllowed = areCookiesAllowed();
    let defaultVal;
    let tabs;
    if (cookiesAllowed) {
        const finishedLvls = levels.filter((lvl) => {
            return alreadyGameOver(lvl);
        });
        defaultVal = Math.max(0, finishedLvls.indexOf(props.level));
        tabs = finishedLvls.map((lvl) => solutionTabContents(lvl, getCookie("info", lvl), getCookie("succ", lvl), getCookie("num", lvl), getCookie("langs", lvl), props.setLevel));
    } else {
        defaultVal = 0;
        tabs = [solutionTabContents(props.level, props.info, props.succ, props.num, props.guesses, props.setLevel)];
    }

    return (
        <TabsUnder defaultVal={defaultVal} tabs={tabs} />
    );
}

const SolutionModal = (props) => {

    return (
        <Modal setIsOpen={props.setIsOpen} title={"Solution"} noPadding>
            <SolutionTabs setLevel={props.setLevel} info={props.info} level={props.level} succ={props.succ} num={props.num} guesses={props.guesses}/>
        </Modal>
    );
};




export default SolutionModal;