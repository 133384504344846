import { useEffect, useRef, useState } from "react";
import "./Modal.css";
import styled, {css} from "styled-components";
import { ButtonGroup, Flex } from './styles/globalStyles.js'
import { getFGColor, newShade, setOpacity } from "./styles/colors.js"
import ReplaceErrorBoundary from "./errorHandling/ReplaceErrorBoundary";
import useWindowWidth from "./hooks/useWindowWidth";
import { relativeSize } from "./ShadowScaler";

const padding = `calc(2 * var(--standard-p))`;

export const ModalBody = styled.div`
    padding: ${(props) => props.noPadding ? "0" : `${padding} calc(3 * var(--standard-p))`};
    text-align: left;
    display: flex;
    row-gap: calc(2 * var(--standard-p));
    flex-direction: ${(props) => props.$row ? "row" : "column"};
`;

export const TextButton = styled.span`
    color: ${(props) => getFGColor(props, props.theme.darkbg)};
    font-size: calc(8 * var(--standard-p));
    font-weight: bold;
    line-height: 1;
    :hover, :focus {
        color: ${(props) => (props.theme.name === "light" ? props.theme.medbg : newShade(props.theme.plainbg, 60))};
        text-decoration: none;
        cursor: pointer;
    }
`;

const ModalHeader = styled(Flex)`
    padding: calc(0.5 * var(--standard-p)) calc(3 * var(--standard-p));
    background-color: ${(props) => props.theme.darkbg};
    color: ${(props) => getFGColor(props, props.theme.darkbg)};
    width: 100%;
    justify-content: flex-end;
    align-items: center;
`;

const ModalFooter = styled(Flex)`
    padding: 0 var(--standard-p) var(--standard-p) var(--standard-p);
    //background-color: ${(props) => props.backgroundColor || props.theme.darkbg};
    //color: ${(props) => getFGColor(props, props.theme.darkbg)};
    width: 100%;
    justify-content: flex-end;
    align-items: center;
`;

const ModalContent = styled.div`
    position: ${(props) => props.placement ? "fixed" : "relative"};
    ${(props) => props.placement ? css`
        ${props.placement}: 0;
        transform: translateX(50vw) translateX(-50%);
        //margin: 0 auto 0;
        //left: 50vh;
        //transform: translateX(50%);
    ` :
        css`margin: auto;`
    }
    text-align: center;
    overflow: hidden;
    background-color: ${(props) => props.theme.plainbg};
    padding: 0;
    width: 95%;
    min-width: min(min-content, 95%);
    max-width: min(80vh, 100% - calc(2 * var(--standard-p)));
    line-height: 1.5;
    box-shadow: 0 ${relativeSize(4, 2)} ${relativeSize(8, 4)} 0 rgba(0,0,0,0.2),
        0 ${relativeSize(6, 3)} ${relativeSize(20, 10)} 0 rgba(0,0,0,0.19);
    -webkit-animation-name: ${(props) => props.placement === "bottom" ? "animatebottom" : "animatetop"};
    -webkit-animation-duration: 0.4s;
    animation-name: ${(props) => props.placement === "bottom" ? "animatebottom" : "animatetop"};
    animation-duration: 0.4s;
`;

const ModalOverlay = styled.div` 
    display: block;
    position: fixed;
    z-index: 11;
    padding-top: calc(2 * var(--standard-h));
    padding-bottom: calc(4 * var(--standard-p));
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: ${(props) => props.theme.black};
    background-color: ${(props) => setOpacity(props.theme.black, 0.4)};
    color: ${(props) => props.theme.darkbg};
`;

const ModalTitle = styled.h3`
    width: 100%;
`;

const Modal = (props) => {

    const closeModal = ((ev, allowed = false) => {
        if (!allowed && (ev.clientX > ev.target.clientWidth || ev.clientY > ev.target.clientHeight)) {
            // ignore mousedown on scrollbars
            //console.log(ev.clientX , ev.target.clientWidth , ev.clientY , ev.target.clientHeight)
            return;
        }
        if (props.close) {
            props.close();
        } else if (props.setIsOpen) {
            props.setIsOpen(false);
        }
        ev.stopPropagation();
    });

    const openModal = (ev => {
        if (props.open) {
            props.open();
        } else if (props.setIsOpen) {
            props.setIsOpen(true);
        }
        ev.stopPropagation();
    });

    const wrapper = content => 
        <ModalOverlay onMouseDown={closeModal}>
            <ModalContent onMouseDown={openModal}>
                <ModalHeader $row>
                    <ModalTitle>{props.title}</ModalTitle>
                    <TextButton onMouseDown={(ev) => closeModal(ev, true)}>&times;</TextButton>
                </ModalHeader>
                <ModalBody noPadding={props.noPadding} >
                    {content}
                </ModalBody>
            </ModalContent>
        </ModalOverlay>;

    const content = 
        <>
            {props.children}
            {props.buttons &&
                <ButtonGroup $thinTop>
                    {props.buttons}
                </ButtonGroup>
            }
        </>;

    useEffect(() => {
        document.body.style.overflow = "hidden";
        return (() =>
            document.body.style.overflow = "unset"
        );
    });

    return (
        <ReplaceErrorBoundary
          wrapper={wrapper}
          content={content}
        />
    );

};

export const MiniModal = (props) => {
    const contentRef = useRef();
    const [height, setHeight] = useState("fit-content");

    const windowWidth = useWindowWidth();

    useEffect(() => {
        if (contentRef) {
            setHeight(contentRef.current.clientHeight);
        }
    }, [contentRef, windowWidth])

    const closeModal = (ev => {
        if (props.close) {
            props.close();
        } else if (props.setIsOpen) {
            props.setIsOpen(false);
        }
        ev.stopPropagation();
    });

    const openModal = (ev => {
        if (props.open) {
            props.open();
        } else if (props.setIsOpen) {
            props.setIsOpen(true);
        }
        ev.stopPropagation();
    });

    const wrapper = content => 
        <ModalOverlay onMouseDown={closeModal}>
            <ModalContent onMouseDown={openModal} $width="fit-content" placement={props.placement}>
                <ModalBody noPadding={true} $row >
                    {content}
                </ModalBody>
                {props.footer && <ModalFooter>{props.footer}</ModalFooter>}
            </ModalContent>
        </ModalOverlay>;

    const content = 
        <>
            <div ref={contentRef} style={{textAlign: "justify"}}>
                {props.children}
            </div>
            {props.buttons &&
                <ButtonGroup $col $thinLeft $height={`calc(${height}px - 4 * ${padding})`}>
                    {props.buttons}
                </ButtonGroup>
            }
        </>;

    useEffect(() => {
        document.body.style.overflow = "hidden";
        return (() =>
            document.body.style.overflow = "unset"
        );
    });

    return (
        <ReplaceErrorBoundary
          wrapper={wrapper}
          content={content}
        />
    );

};

export default Modal;