import React, {Fragment} from 'react'
import { getCookie, setCookie, sumCookies, sumDistr, levels, areCookiesAllowed, allowAllCookies } from '../cookies.js'
import Modal from "../Modal";
import styled, {withTheme} from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { StyledFontAwesomeIconWithBG, ThemedBarButton } from '../styles/globalStyles.js';
import {ThemedInfoPopover} from "../Popover.js"
import { FlexGrid, FlexGridRow } from "../genericComps/FlexGrid.js";
import {TabsUnder} from "../Tabs.js"
import { Container } from "../styles/globalStyles.js"
import { useUpdateEffect } from 'usehooks-ts';
import { useState } from 'react';

const ModalWrap = styled.div`
    position: relative;
    text-align: center;
    display: grid;
    padding: 0;
    grid-template-columns: 
        1fr
        fit-content
        1fr;
    grid-gap: calc(2 * var(--standard-p));
`;

const DistBar = styled.div`
    height: var(--fontSize);
    background-color: ${(props) => props.today ? "#2693e6" : props.theme.darkbg};
    width: ${(props) => props.maxVal === 0 ? '100%' : `calc(100% / ${props.maxVal} * ${props.val})`}
`;

const GoldStar = (props) => { 
  return (
      <span className="gold-star-wrapper" style={{display: "inline-block", padding: "0 var(--standard-p)"}} >
          <StyledFontAwesomeIconWithBG className="fa-layers fa-fw" >
              <FontAwesomeIcon icon={icon({name: "star"})}/> 
              <FontAwesomeIcon icon={icon({name: "star"})} color={props.theme.yellow} transform="shrink-3"/>
          </StyledFontAwesomeIconWithBG>
      </span>
  ); 
}

const goldStarColumnStyles = [
  {textAlign: "right", textJustify: "center"},
  {textAlign: "left", textJustify: "center"}
];

const StatsWrapper = (props) => {

    const allLevels = props.level === -1;
    const lvlArr = (allLevels ? levels : [props.level]);
    const plays = sumCookies("plays", lvlArr);
    const wins = sumCookies("wins", lvlArr);
    const curS = (allLevels ? getCookie("sCurS") : getCookie("curS", props.level));
    const maxS = (allLevels ? getCookie("sMaxS") : getCookie("maxS", props.level));
    const distr = (allLevels ? sumDistr(lvlArr) : getCookie("distr", props.level));

    const winsPercentage = (wins / plays * 100).toFixed(0) + "%";
    const maxVal = Math.max.apply(null, distr);

    const today = (allLevels || !getCookie("succ", props.level)) ? -1 : getCookie("num", props.level);

    return ( 
        <ModalWrap>
            <FlexGrid className="g2" cols={4} xGap={2} yGap={2}>
              <FlexGridRow >
                <h2><strong>{plays} </strong></h2>
                <h2><strong>{plays === 0 ? "n/a" : winsPercentage}</strong></h2>
                <h2><strong>{curS}</strong></h2>
                <h2><strong>{maxS}</strong></h2> 
              </FlexGridRow>
              <FlexGridRow >
                <h3>played</h3>
                <h3>wins</h3>
                <h3>current streak</h3>
                <h3>longest streak</h3>
              </FlexGridRow>
            </FlexGrid>

            <h2 className="g2 small-caps">guess distribution</h2>
            {Array(6).fill(true).map((_, i) => 
                <Fragment key={i}>
                    <h3 className="g1 right">{i + 1}</h3>
                    <DistBar className="g2" maxVal={maxVal} val={distr[i]} today={i + 1 === today}/>
                    <h3 className="g3 left">{distr[i]}</h3>
                </Fragment>
            )}
            
            {allLevels && 
              <>
                <h2 className="g2 small-caps">
                    gold
                    <GoldStar {...props} />
                    streaks
                    <ThemedInfoPopover text="Earn a gold star by correctly guessing all three daily languages!" />
                </h2>
                <FlexGrid className="g2" cols={2} xGap={4} yGap={2} columnStyles={goldStarColumnStyles}>
                  <FlexGridRow >
                    <h3>current streak:</h3>
                    <h3><strong>{getCookie("aCurS")}</strong></h3>
                  </FlexGridRow>
                  <FlexGridRow >
                    <h3>longest streak:</h3>
                    <h3><strong>{getCookie("aMaxS")}</strong></h3>
                  </FlexGridRow>
                </FlexGrid>
              </>
            }
        </ModalWrap>
    ); 
}

const ThemedStatsWrapper = withTheme(StatsWrapper);

const StatsModal = ({ setIsOpen, openCookiesModal }) => {
  const [cookiesAllowed, setCookiesAllowed] = useState(areCookiesAllowed());

  useUpdateEffect(() => {

  }, [cookiesAllowed])

  if (!cookiesAllowed) {
    const buttons = 
      <>
        <ThemedBarButton onClick={() => {setIsOpen(false); openCookiesModal(true);}}>Review</ThemedBarButton>
        <ThemedBarButton onClick={() => {setCookie("c", allowAllCookies); setCookiesAllowed(true);}}>Allow all</ThemedBarButton>
      </>

    return (
      <Modal setIsOpen={setIsOpen} title={"Statistics"} buttons={buttons}>
          <Container>
            You need to enable cookies to start tracking your statistics.
          </Container>
      </Modal>
    );
  }

  const defaultLevel = getCookie("lvl") - 1;

  const tabs = levels.map((lvl) => (
    {
      title: `Level ${lvl}`,
      content: <ThemedStatsWrapper level={lvl} />
    }
  ));
  tabs.push({title: "All", content: <ThemedStatsWrapper level={-1} />});

  return (
      <Modal setIsOpen={setIsOpen} title={"Statistics"} noPadding >
          <TabsUnder defaultVal={defaultLevel} tabs={tabs} />
      </Modal>
  );
};

export default StatsModal;