import Map from './Map.js'
import { Container, FullWidthContainer } from './styles/globalStyles.js';
import { ErrorBoundary } from 'react-error-boundary';
import MapFallback from './errorHandling/MapFallback.js';
import ErrorMessage from './errorHandling/ErrorMessage.js';
import Result from "./Result"
import Share from './Share.js';
import { ThemedFamilyPopover } from './Popover.js';

function getArticle(str) {
    function needsAn(ch) {
        switch (ch) {
            case 'a':
            case 'e':
            case 'i':
            case 'o':
            case 'u':
            case 'y':
                return true;
            default:
                return false;
        }
            
    };
    return (needsAn(str[0].toLowerCase()) ? "an" : "a");
}

const textualList = (arr, preStr, postStr) => {
    const len = arr.length;
    if (len > 0) {
        let str = arr[0];
        if (len > 1) {
            arr.slice(1, -1).forEach(function(val) {
                str += ", " + val;
            });
            str += " and " + arr.slice(-1);
        }
        return preStr + str + postStr;
    }
    return "";
}

const getSolText = (info) => {
    const countries = info.cs;

    let middleBit;
    if (info.family === info.genus || info.lname === info.genus) {
        middleBit = ` belongs to the ${info.family} language family.`;
    } else if (info.family == "other") {
        middleBit = ` is ${getArticle(info.genus)} ${info.genus} language.`;
    } else {
        const subFamilyPhrase = info.subfamily == null ? "" : `the ${info.subfamily} branch of `;
        middleBit = ` is ${getArticle(info.genus)} ${info.genus} language from ${subFamilyPhrase}the ${info.family} language family.`;
    }

    let res = info.lname + middleBit + textualList(countries, " It is spoken in ", ".");
    return res;
}

const getFamilyText = (info) => {
    if (info.family === "other") {
        return textualList(info.rels, ` ${info.lname} is based on `, ".");
    } 
    return textualList(info.rels, ` ${info.lname} is related to e.g. `, ".");
}

const Solution = (props) => {
    var info;
    if (props.info) {
        info = props.info;
    } else {
        throw TypeError("Function was expecting an argument.");
    }

    const solText = getSolText(info);
    const familyText = getFamilyText(info);
 
    const pos = {lat: info.lat, lng: info.long};

    return (
        <FullWidthContainer $noTop $noBottom $noLeft $noRight>
            <Container $noTop $noBottom >
                <h3 style={{width: "100%", height: "min-content"}}>
                    <ThemedFamilyPopover text={familyText} />
                    {solText}
                </h3>
            </Container>
            <ErrorBoundary FallbackComponent={MapFallback}>
                <Map pos={pos} />
            </ErrorBoundary>
            {props.guesses &&
                <>
                    <Result succ={props.succ} ans={props.info.lname} num={props.num} /> 
                    <Container $thinTop $noBottom $noLeft $noRight >
                        <Share setLevel={props.setLevel} level={props.level} guesses={props.guesses} succ={props.succ} num={props.num} />
                    </Container>
                </>}
        </FullWidthContainer>
    );
}

export const solutionTabContents = (level, info, succ, num, guesses, setLevel) => {
    return (
        {
          title: `Level ${level}`,
          content: 
            <ErrorBoundary FallbackComponent={ErrorMessage}>
                <Solution setLevel={setLevel} info={info} level={level} succ={succ} num={num} guesses={guesses} />
            </ErrorBoundary>
        }
    );
}

export default Solution