import { ErrorBoundary } from "react-error-boundary";
import ErrorMessage from "./ErrorMessage";

const ReplaceErrorBoundary = ({wrapper, content, ...props}) => {
    const fallbackRender = ({error, resetErrorBoundary}) => 
        wrapper(<ErrorMessage error={error} resetErrorBoundary={resetErrorBoundary} />);

    return (
        <ErrorBoundary fallbackRender={fallbackRender} {...props}>
            {wrapper(content)}
        </ErrorBoundary>
    );
}

export default ReplaceErrorBoundary