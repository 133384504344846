import GuessesSkeleton from "../skeletons/GuessesSkeleton"
import ErrorMessage from "./ErrorMessage";

const GamePlayFallback = (props) => {
  return (
      <GuessesSkeleton animation={false} errorComp={<ErrorMessage {...props}/>} />
  );
}

export default GamePlayFallback


