import SampleSkeleton from "./SampleSkeleton"
import GuessesSkeleton from "./GuessesSkeleton"
import { Flex } from "../styles/globalStyles";

const AppBodySkeleton = ({errorComp, ...props}) => {
  return (
      <Flex $col height="fit-content">
        <SampleSkeleton {...props} />
        <GuessesSkeleton {...props} />
        {errorComp}
      </Flex>
  );
}

export default AppBodySkeleton