import React from "react"
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Swapper from "./genericComps/Swapper.js"
import { withTheme } from "styled-components";
import { setOpacity } from "./styles/colors.js";

function TabPanel(props) {
    const { children, value, $noTop, index, ...otherProps } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...otherProps}
      >
        {value === index && (
          <div style={{padding: $noTop 
            ? "0 calc(4 * var(--standard-p)) calc(3 * var(--standard-p))" 
            : "calc(4 * var(--standard-p)) calc(4 * var(--standard-p)) calc(3 * var(--standard-p))"}}>
            {children}
          </div>
        )}
      </div>
    );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TabPages = (props) => {
  const { defaultVal, tabs, tabsUnder, $noTop, ...otherProps } = props;
  const [value, setValue] = React.useState(defaultVal);

  const handleChange = (ev, newValue) => {
    setValue(newValue);
  };

  const tabIndicatorStyle = {
    sx: tabsUnder ? {
      top: 0, 
      backgroundColor: props.theme.darkbg,
      height: `max(2px, calc(0.1 * var(--textFontSize))) !important`
    } : {
      bottom: 0, 
      backgroundColor: props.theme.darkbg,
      height: `max(2px, calc(0.1 * var(--textFontSize))) !important`
    }
  };

  return (
    <Swapper 
      swap={tabsUnder}
      first={
        <Tabs value={value} onChange={handleChange} TabIndicatorProps={tabIndicatorStyle} centered sx={{
            "& button": { 
              minWidth: 20, 
              color: setOpacity(props.theme.darkbg, 0.5),
              fontSize: "calc(7/8 * var(--textFontSize)) !important",
              padding: "calc(3 * var(--standard-p)) !important",
              "&.Mui-selected": {
                color: props.theme.darkbg,
                fontWeight: 700,
              },
              borderTop: tabsUnder ? `max(1px, calc(0.02 * var(--textFontSize))) solid ${setOpacity(props.theme.darkbg, 0.2)} !important` : 0,
              borderBottom: !tabsUnder ? `max(1px, calc(0.02 * var(--textFontSize))) solid ${setOpacity(props.theme.darkbg, 0.2)} !important` : 0,
            }
          }} >
          {tabs.map((tab, i) =>
            <Tab label={tab.title} key={tab.title} {...a11yProps(i)} sx={{pl: 1.5, pr: 1.5}}/>
          )}
        </Tabs>
      }
      second={
        tabs.map((tab, i) =>
          <TabPanel value={value} index={i} key={i} $noTop={$noTop}>
            {tab.content}
          </TabPanel>
        )
      }
    />
  );
}

const style = {
  WebkitTransition: "height 0.3s ease-out",
  OTransition: "height 0.3s ease-out",
  MozTransition: "height 0.3s ease-out",
  transition: "height 0.3s ease-out"
}

const UnthemedTabsOver = (props) => TabPages({...props, tabsUnder: false, style: style});
const UnthemedTabsUnder = (props) => TabPages({...props, tabsUnder: true, style: style});

export const TabsOver = withTheme(UnthemedTabsOver);
export const TabsUnder = withTheme(UnthemedTabsUnder);