export const getFormattedDate = (date) => {
    if (date === undefined) {
        date = new Date();
    }
    const todayStr = new Intl.DateTimeFormat('en-GB', { day: "2-digit", month: "2-digit", year: "2-digit" , hour12: false}).format(date);
    return todayStr.replace(/\//g,"");
}

export const addDays = (n, date) => {
    if (date === undefined) {
        date = new Date();
    }
    date.setDate(date.getDate() + n);
    return date;
}

export function getNearest(h, m, s) {
    const now = new Date();
    var nearest = new Date();
    nearest.setHours(h, m, s, 0);
    var sDiff = (nearest.getTime() - now.getTime()) / 1000;
    if (sDiff < 0) {
        nearest.setDate(nearest.getDate() + 1);
    }
    return nearest;
};

export function getMidnight() {
    return getNearest(0, 0, 0);
};