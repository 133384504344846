import React, { useContext, useRef, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import Logo from "./Logo"
import StatsModal from "./modals/Stats"
import InfoModal from "./modals/Info"
import SettingsModal from "./modals/Settings"
import CookiesModal, {CookiesMiniModal} from "./modals/Cookies"
import styled, { withTheme } from "styled-components";
import { Flex, StyledFontAwesomeIconWithBG } from "./styles/globalStyles.js"
import "./AppHeader.css"
import { getFGColor, newShade } from "./styles/colors.js"
import { isCookieSet } from "./cookies"
import useAudio from "./hooks/useAudio"
import { useUpdateEffect } from "usehooks-ts"
import { ErrorBoundary } from "react-error-boundary"
import { getFormattedDate } from "./date"
import { GlobalContext } from "./context/globalContext"
import { useNavigate } from "react-router-dom"
import { DemoInstruction, HideDuringDemo } from "./Instruction"
import { relativeSize } from "./ShadowScaler"
import { LevelContext } from "./context/levelContext"

const audioPath = `${process.env.REACT_APP_API_URL}/audio`;

const Header = styled(Flex)`
    background-color: ${props => newShade(props.theme.plainbg, 10)}; // 10% lighter than plainbg
    width: 100%;
    height: max-content;
    grid-column: 1 / 4;  
    padding: calc(1.5 * var(--standard-p)) var(--standard-p);
    font-size: calc(2 * var(--textFontSize));
    display: flex;
    z-index: 10;
    justify-content: center;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    box-shadow: 0 ${relativeSize(5, 1)} ${relativeSize(10, 2)} 0 rgba(0, 0, 0, 0.5);
`;

const buttonStyle = (hovering, animating) => {
    const relative1Pixel = relativeSize(1, 1);
    const relative2Pixel = relativeSize(2, 2);

    const smallerShadow = `drop-shadow(${relative1Pixel} ${relative1Pixel} ${relative1Pixel} rgba(0,0,0,0.3))`;
    const largerShadow = `drop-shadow(${relative1Pixel} ${relative1Pixel} ${relative2Pixel} rgba(0,0,0,0.3))`;

    return {
        MozFilter: hovering ? largerShadow : smallerShadow,
        WebkitFilter: hovering ? largerShadow : smallerShadow,
        OFilter: hovering ? largerShadow : smallerShadow,
        MsFilter: hovering ? largerShadow : smallerShadow,
        filter: hovering ? largerShadow : smallerShadow,

        "& path": {
            animationPlayState: animating ? "running" : "paused",
        },
        animationPlayState: animating ? "running" : "paused",
    };
}

export const buttonAnimations = {
    "circle-question": "fa-shake-hover",
    "square-poll-vertical": "fa-flip-hover",
    "gear": "fa-spin-hover",
    "default": "fa-shake-hover",
}

const HeaderButton = (props) => { 
    const [hovering, setHovering] = useState(false);
    const [animating, setAnimating] = useState(false);

    const style = buttonStyle(hovering, animating);

    return (
        <span 
            onClick={props.onClick} 
            onMouseEnter={() => {setHovering(true); setAnimating(true);}}
            onMouseLeave={() => setHovering(false)}
            style={{overflow: "hidden", flexShrink: 0}}
        >
            <StyledFontAwesomeIconWithBG className="fa-layers fa-fw" style={style}>
                <FontAwesomeIcon 
                    icon={icon({name: "circle"})} 
                    color={getFGColor(props, props.theme.darkbg)} 
                    transform="shrink-5"
                /> 
                {animating
                    ? <FontAwesomeIcon 
                        className={`${props.animation}`} 
                        color={hovering ? newShade(props.theme.darkbg, 15) : undefined} 
                        icon={props.icon} 
                        style={hovering ? undefined : style} 
                        onAnimationIteration={() => {
                            if (!hovering) {
                                setAnimating(false);
                            }
                        }}
                    />
                    : <FontAwesomeIcon icon={props.icon} style={style}/>
                }
            </StyledFontAwesomeIconWithBG>
        </span>
    ); 
} 

const SoundButton = (props) => { 

    const buttonRef = useRef();

    const [hovering, setHovering] = useState(false);
    const [loaded, playing, toggleAudio] = useAudio(props.audioFile);
    const [clicked, setClicked] = useState(false);

    const style = buttonStyle(hovering, playing || clicked);

    useUpdateEffect(() => {
        setHovering(false);
    }, [props.audioFile])

    useUpdateEffect(() => {
        if (playing) {
            setClicked(false);
        }
    }, [playing])

    if (!props.show) {
        return <></>;
    }

    const onClick = () => {
        if (!playing) {
            setClicked(true);
        }
        toggleAudio();
    }

    return (
        <span 
            onClick={onClick}
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
            style={{overflow: "hidden", flexShrink: 0}}
        >
            <StyledFontAwesomeIconWithBG className="fa-layers fa-fw" style={style}>
                {playing
                    ? <FontAwesomeIcon 
                        className="fa-play-hover "
                        color={hovering ? newShade(props.theme.darkbg, 15) : undefined} 
                        icon={icon({name: "volume-high"})} 
                        style={hovering ? undefined : style} 
                        size="sm"
                    />
                    : 
                    (clicked  
                        ? <>
                            <FontAwesomeIcon icon={icon({name: "volume-high"})} size="sm" style={style} fixedWidth className="clicked-path" /> 
                            <FontAwesomeIcon icon={icon({name: "spinner"})} style={style} spin transform="shrink-9 right-5"/>
                          </>
                        : <FontAwesomeIcon icon={icon({name: "volume-high"})} size="sm" style={style} fixedWidth className="change-path" />
                    )
                } 
            </StyledFontAwesomeIconWithBG>
        </span>
    ); 
} 

const BareSoundButton = (props) => { 

    const style = buttonStyle(false, false);

    return (
        <span 
            style={{overflow: "hidden", flexShrink: 0}}
        >
            <StyledFontAwesomeIconWithBG className="fa-layers fa-fw" style={style}>
                <FontAwesomeIcon icon={icon({name: "volume-high"})} size="sm" style={style} fixedWidth className="change-path" />
            </StyledFontAwesomeIconWithBG>
        </span>
    ); 
} 

const encodeParams = (obj) => { //Also in AppBody
    let arr = [];
    Object.keys(obj).forEach((key) => 
    arr.push(`${key}=${encodeURIComponent(obj[key])}`));
    return arr;
};

export const ThemedHeaderButton = withTheme(HeaderButton);
export const ThemedSoundButton = withTheme(SoundButton);

const AppHeader = (props) => {
    const [infoIsOpen, setInfoIsOpen] = useState(false);
    const [statsIsOpen, setStatsIsOpen] = useState(false);
    const [settingsIsOpen, setSettingsIsOpen] = useState(false);
    const [cookiesIsOpen, setCookiesIsOpen] = useState(false);
    const [cookiesMiniIsOpen, setCookiesMiniIsOpen] = useState(!isCookieSet("c"));

    const { lastVisit } = useContext(GlobalContext);
    const { level, setLevel, hasAudio } = useContext(LevelContext);

    const getAudioURL = () => `${audioPath}?${encodeParams({d: getFormattedDate(), lvl: level}).join("&")}`;

    const [audioFile, setAudioFile] = useState(getAudioURL());

    const navigate = useNavigate();

    useUpdateEffect(() => {
        setAudioFile(getAudioURL());
    }, [level, lastVisit])

    return (
        <Header as="header" $row >
            <DemoInstruction id={"faq"} showStep={props.showStep}>
                <ThemedHeaderButton icon={icon({name: "circle-question"})} onClick={() => setInfoIsOpen(true)} animation={buttonAnimations["circle-question"]} />
            </DemoInstruction>

            <Logo onClick={() => navigate("/intro")} />

            { props.showStep !== undefined
            ? <DemoInstruction id={"sound"} showStep={props.showStep}>
                <BareSoundButton />
            </DemoInstruction>
            : <ErrorBoundary fallback={<></>}>
                <ThemedSoundButton audioFile={audioFile} show={hasAudio} />
            </ErrorBoundary> }

            <DemoInstruction id={"stats"} showStep={props.showStep}>
                <ThemedHeaderButton icon={icon({name: "square-poll-vertical"})} onClick={() => setStatsIsOpen(true)} animation={buttonAnimations["square-poll-vertical"]} />
            </DemoInstruction>
            <DemoInstruction id={"settings"} showStep={props.showStep}>
                <ThemedHeaderButton icon={icon({name: "gear"})} onClick={() => setSettingsIsOpen(true)} animation={buttonAnimations["gear"]}/>
            </DemoInstruction>

            <div style={{fontSize: "var(--textFontSize)"}} >
                {infoIsOpen && <InfoModal setIsOpen={setInfoIsOpen} level={level} onLevelChange={setLevel} openCookiesModal={setCookiesIsOpen} />}
                {statsIsOpen && <StatsModal setIsOpen={setStatsIsOpen} openCookiesModal={setCookiesIsOpen} />} 
                {settingsIsOpen && <SettingsModal setIsOpen={setSettingsIsOpen} level={level} onLevelChange={setLevel} openCookiesModal={setCookiesIsOpen} />} 
                {cookiesIsOpen && <CookiesModal setIsOpen={setCookiesIsOpen} level={level} />} 
                {cookiesMiniIsOpen && <HideDuringDemo showStep={props.showStep}><CookiesMiniModal setIsOpen={setCookiesMiniIsOpen} openCookiesModal={setCookiesIsOpen} level={level} /></HideDuringDemo> }
            </div>
        </Header>
    );
};

export default withTheme(AppHeader)