
import { getFormattedDate, getMidnight } from './date.js'

function entry(val, path, day, hasLvls, type, persists) {
    return {val: val, path: path, day: day, hasLvls: hasLvls, type: type, persists: persists};
}

export const levels = [1, 2, 3];

export const allowAllCookies = 2;
export const allowOnlyEssentialCookies = 1;
export const allowNoCookies = 0;

export const sumCookies = (str, lvls) => {
    if (lvls === undefined) {
        lvls = levels;
    }
    return lvls.map(lvl => getCookie(str, lvl)).reduce((acc, val) => acc + val);
}

export const sumDistr = (levels) => {
    const pairwiseAdd = (arr1, arr2) => arr1.map((v, i) => v + arr2[i]);
    return levels.map(lvl => getCookie("distr", lvl)).reduce(pairwiseAdd);
}

// Dict containing: default value, path, daily expiration, has levels, type, persistsClearAll
const def = {
    // Dailies
    "num": entry(0, "/", true, true, "int", false), 
    "succ": entry("", "/", true, true, "boolean", false), 
    "langs": entry(JSON.stringify([]), "/", true, true, "object", false), 
    "info": entry(JSON.stringify({}), "/", true, true, "object", false),
    "lvl": entry(1, "/", true, false, "int", false),

    // Stats per level
    "plays": entry(0, "/", false, true, "int", false),
    "wins": entry(0, "/", false, true, "int", false),
    "curS": entry(0, "/", false, true, "int", false),  
    "maxS": entry(0, "/", false, true, "int", false), 
    "distr": entry('[0,0,0,0,0,0]', "/", false, true, "object", false), 

    // Stats overall
    "sCurS": entry(0, "/", false, false, "int", false),
    "aCurS": entry(0, "/", false, false, "int", false),
    "sMaxS": entry(0, "/", false, false, "int", false),
    "aMaxS": entry(0, "/", false, false, "int", false),

    // Settings
    "confetti": entry("1", "/", false, false, "boolean", false),
    //"autoplay": entry("1", "/", false, false, "boolean", false),
    
    "last": entry(getFormattedDate(), "/", false, false, "string", false), 

    // Persisting cookies
    "c": entry(0, "/", false, false, "int", true),
    "beta": entry("1", "/", false, false, "boolean", true),
};

const checkCookieIsKnownAndPermitted = (c) => {
    if (def[c].persists) {
        return true;
    }
    if (c in def) {
        let cVal = get("c");
        return cVal != null && cVal !== allowNoCookies.toString();
    }
    throw new Error (`'${c}' is not a cookie managed by this application.`);
}

const cookieDecorator = (c) => {
    var typeToString = s => s;
    var stringToType = s => s;
    switch (def[c].type) {
        case "boolean":
            typeToString = b => (b ? "1" : "");
            stringToType = s => (s === "1" ? true : false);
            break;
        case "int":
            typeToString = (n => n.toString());
            stringToType = parseInt
            break;
        case "object":
            typeToString = JSON.stringify;
            stringToType = JSON.parse;
            break;
    }
    return [typeToString, stringToType];
}

const levelCookie = (c, lvl) => {
    let optLvl = "";
    if (def[c].hasLvls) {
        optLvl = (lvl === undefined ? getCookie("lvl") : parseInt(lvl));
    }
    return c + optLvl;
}

const expiryCookie = (c) => {
    let d = new Date();
    d.setFullYear(d.getFullYear() + 1);
    // if (def[c].day) {
    //     d.setTime(getMidnight());
    //     d.setMinutes(5);
    // } else {
    //     d.setFullYear(d.getFullYear() + 1);
    // }
    return d.toUTCString();
}

export const setCookie = (c, val, lvl) => {
    if (checkCookieIsKnownAndPermitted(c)) {
        const [toString, fromString] = cookieDecorator(c);
        const lvlC = levelCookie(c, lvl);
        set(lvlC, toString(val), expiryCookie(c), def[c].path);  
    }  
};

export const resetCookie = (c, lvl) => {
    if (checkCookieIsKnownAndPermitted(c)) {
        const lvlC = levelCookie(c, lvl);
        //console.log("Resetting", lvlC, document.cookie);
        set(lvlC, def[c].val, expiryCookie(c), def[c].path);
    }
}

export const getCookie = (c, lvl) => {
    if (c in def) {
        const [toString, fromString] = cookieDecorator(c);
        if (checkCookieIsKnownAndPermitted(c)) {
            const lvlC = levelCookie(c, lvl);
            //console.log("Getting", lvlC, document.cookie);
            const cur = get(lvlC);
            if (cur !== null && cur !== undefined) {
                //console.log(`Getting ${lvlC} = ${cur}`);
                return fromString(cur);
            }
            //console.log(`Setting ${lvlC} to default value ${def[c].val} via getCookie`); 
            set(lvlC, def[c].val, expiryCookie(c), def[c].path);
        }
        return fromString(def[c].val);
    }
    throw new Error (`'${c}' is not a cookie managed by this application.`);
};

export function incrCookie(c, lvl) {
    if (checkCookieIsKnownAndPermitted(c)) {
        if (def[c].type !== "int") {
            return -1;
        }
        const lvlC = levelCookie(c, lvl);
        //console.log("Incrementing", lvlC, document.cookie);
        set(lvlC, (getCookie(c) + 1).toString(), expiryCookie(c), def[c].path);
    }
}

function set(c, val, expires, path) {
    //console.log("primitive set:", c + "=" + val + ";expires=" + expires + ";path=" + path);
    document.cookie = c + "=" + val + ";expires=" + expires + ";path=" + path;
}

function get(c) {
    let name = c + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let d = ca[i];
        while (d.charAt(0) === ' ') {
            d = d.substring(1);
        }
        if (d.indexOf(name) === 0) {
            //console.log("primitive get:", c + "=" + d.substring(name.length, d.length));
            return d.substring(name.length, d.length);
        }
    }
    return null;
}

export function deleteCookie(c) {
    if (checkCookieIsKnownAndPermitted(c)) {
        var d = new Date();
        d.setDate(d.getDate() - 1);
        let expires = "expires=" + d.toUTCString();
        levels.forEach((lvl) => {
            document.cookie = c + parseInt(lvl) + "=;" + expires + ";path=" + def[c].path;
        })
    }
}

function clearAllCookies() {
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
        document.cookie = cookies[i] + "=;expires=" + new Date(0).toUTCString();
    }
}

// Assumes all persisting cookies are unlevelled
export const resetCookies = (keepBeta = true) => {
    let persistCookies = [];
    for (let key in def) {
        if (def[key].persists) {
            if (isCookieSet(key)) {
                persistCookies[key] = getCookie(key);
            }
        }
    }
    clearAllCookies();
    for (let key in persistCookies) {
        if (keepBeta || key !== "beta") {
            setCookie(key, persistCookies[key]);
            //console.log("Resetting", key, document.cookie);
        }
    }
}

export function clearDailyCookies() {
    deleteCookie("num");
    deleteCookie("succ");
    deleteCookie("langs");
    deleteCookie("info");
    deleteCookie("lvl");
}

export const setBetaCookie = () => {
    const cookieName = "beta";
    if (get(cookieName) === null) {
        var d = new Date();
        d.setDate(d.getDate() + 20)
        const expires = d.toUTCString(); 
        set(cookieName, "1", expires, "/");
    }
}

export const isCookieSet = (c, lvl) => {
    if (checkCookieIsKnownAndPermitted(c)) {
        const [toString, fromString] = cookieDecorator(c);
        const lvlC = levelCookie(c, lvl);
        return get(lvlC) !== null;
    }
    return false;
}

export const areCookiesAllowed = () => {
    return isCookieSet("c") && allowOnlyEssentialCookies <= getCookie("c");
}

export const areAnalyticsAllowed = () => {
    return false;
    return isCookieSet("c") && getCookie("c") === allowAllCookies;
}