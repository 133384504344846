import { useState, useEffect, useMemo } from "react";
import { useDebounce, useUpdateEffect } from "usehooks-ts";
import { useDebouncedCallback } from "use-debounce";
import { useActiveElement } from "@react-hooks-library/core";

const getWidth = () => window.innerWidth 
  || document.documentElement.clientWidth 
  || document.body.clientWidth;

// const getHeight = () => {
//     let maxHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
//     // //console.log("doc client height", document.documentElement.clientHeight);
//     // //console.log("window inner height", window.innerHeight);
//     // //console.log("doc body client height", document.body.clientHeight);
//     return window.visualViewport ? window.visualViewport.height : (
//       maxHeight
//       || document.body.clientHeight
//       || 0
//     );
// }

const getHeight = () => {
  let maxHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
  // //console.log("doc client height", document.documentElement.clientHeight);
  // //console.log("window inner height", window.innerHeight);
  // //console.log("doc body client height", document.body.clientHeight);
  return window.visualViewport ? window.visualViewport.height : (
    maxHeight
    || document.body.clientHeight
    || 0
  );
}

function useWindowWidth() {
  let [width, setWidth] = useState(getWidth());

  useEffect(() => {
    let timeoutID = null;
    const resizeListener = () => {
      timeoutID = setTimeout(() => setWidth(getWidth()), 150);
    };
    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
      clearTimeout(timeoutID);
    }
  }, [])

  return width;
}

export const useWindowHeight = () => {
  let [height, setHeight] = useState(getHeight());

  useEffect(() => {
    let timeoutID = null;
    const resizeListener = () => {
      timeoutID = setTimeout(() => setHeight(getHeight()), 150);
    };
    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
      clearTimeout(timeoutID);
    }
  }, [])

  return height;
}

// Workaround for android bug: https://issuetracker.google.com/issues/36922899
const delayedOuterWidth = () => setTimeout(() => {
  return window.outerWidth;
}, 200);

const delayedOuterHeight = () => setTimeout(() => {
  return window.outerHeight;
}, 200);

const smallestNonZero = (a, b) => {
  if (a == 0) {
    return b;
  }
  if (b == 0) {
    return a;
  }
  return Math.min(a, b);
}

export const useWindowDimensionsDebounce = (debounce) => {
  const [width, setWidth] = useState(getWidth());
  const [height, setHeight] = useState(getHeight());
  const [outerWidth, setOuterWidth] = useState(window.outerWidth);
  const [outerHeight, setOuterHeight] = useState(window.outerHeight);
  const [hasOuterChanged, setHasOuterChanged] = useState(true);

  const activeElement = useActiveElement();

  const locOuterHeight = window.outerHeight;
  //console.log("Outside all:", height, getHeight(), outerHeight, locOuterHeight)

  useEffect(() => {
    //console.log("setting smallestNonzero of,", getHeight(), window.outerHeight, "is", smallestNonZero(getHeight(), window.outerHeight));
    setHeight(smallestNonZero(getHeight(), window.outerHeight))
  }, [])

  // console.log("doc client height", document.documentElement.clientHeight);
  // console.log("window inner height", window.innerHeight);
  // console.log("doc body client height", document.body.clientHeight);
  // console.log("window vis viewport height", window.visualViewport?.height);
  // console.log("window screen avail height", window.screen.availHeight);

  const changeHandler = (ev) => {
    //console.log("event?", ev);
    const locOuterWidth = window.outerWidth;
    const locOuterHeight = window.outerHeight;
    //console.log(activeElement?.activeElement?.tagName)
    if (activeElement?.activeElement?.tagName === "INPUT") {
      //console.log("TRIGGERED BY INPUT!");
    } else if (locOuterWidth != outerWidth || locOuterHeight != outerHeight) {
      setWidth(getWidth());
      setHeight(getHeight());
      setOuterWidth(locOuterWidth);
      setOuterHeight(locOuterHeight);
      setHasOuterChanged(true);
      //console.log("Outer has changed:", width, getWidth(), height, getHeight(), outerWidth, locOuterWidth, outerHeight, locOuterHeight);
    } else {
      // setWidth(getWidth()); //Without this, scaling works. Without it, changing orientation doesn't work.
      // setHeight(getHeight()); //Without this, scaling works. Without it, changing orientation doesn't work.
      setHasOuterChanged(false);
      //console.log("Outer has NOT changed:", width, getWidth(), height, getHeight(), outerWidth, locOuterWidth, outerHeight, locOuterHeight);    
    }
  }

  const debouncedHandler = useDebouncedCallback((ev) => changeHandler(ev), debounce);

  // useEffect(() => {
  //   changeHandler();
  //   console.log("triggered");
  // }, [window.outerWidth, window.outerHeight])

  useEffect(() => {	
    window.addEventListener("resize", debouncedHandler);

    return () => {
      window.removeEventListener("resize", debouncedHandler);
    }
  }, []);

  //console.log(width, height, outerHeight, hasOuterChanged)

  return [width, height, outerHeight, hasOuterChanged];
}

export default useWindowWidth