import { outboundClickGA } from './Analytics.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import styled from "styled-components";

const url = {
    "Tumblr": "https://www.tumblr.com/blog/langleapp",
    "Facebook": "https://facebook.com/langleapp",
    "Twitter": "https://twitter.com/langleapp",
    "Ko-fi": "https://ko-fi.com/langleapp",
    "UDHR": "https://www.ohchr.org/en/human-rights/universal-declaration/universal-declaration-human-rights/about-universal-declaration-human-rights-translation-project"
}

export const openInNewTab = (target) => {
    outboundClickGA(target, url[target]);
    window.open(url[target], "_blank", "noreferrer");
}; 

const SocialButton = styled(FontAwesomeIcon)`
    padding-left: var(--standard-p);
    padding-right: var(--standard-p);
    cursor: pointer;
`;

export const KoFiButton = () =>
    <SocialButton icon={icon({name: "mug-saucer"})} className="fa-lg" onClick={() => openInNewTab("Ko-fi")} />;

export const TwitterButton = () =>
    <SocialButton icon={icon({name: "twitter", style: "brands"})} className="fa-lg" onClick={() => openInNewTab("Twitter")} />;

export const FacebookButton = () =>
    <SocialButton icon={icon({name: "facebook", style: "brands"})} className="fa-lg" onClick={() => openInNewTab("Facebook")} />;

export const TumblrButton = () =>
    <SocialButton icon={icon({name: "tumblr", style: "brands"})} className="fa-lg" onClick={() => openInNewTab("Tumblr")}/>;

export const Socials = () => {
    return (
        <>
            <KoFiButton />
            <TwitterButton />
            <FacebookButton />
            <TumblrButton />
        </>
    );
}