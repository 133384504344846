import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import GlobalContextProvider from "./context/globalContext";
import AppFallBack from './errorHandling/AppFallBack';
import { ErrorBoundary } from 'react-error-boundary';
import ReactGA from "react-ga4";
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import DimensionsContextProvider from './context/dimensionsContext';

const root = ReactDOM.createRoot(document.getElementById("root"));

ReactGA.initialize("G-RWL67XD2RY", {
  gaOptions: {
    debug_mode: true,
  },
  gtagOptions: {
    debug_mode: true,
  },
});

root.render(
  <React.StrictMode>
    <DimensionsContextProvider>
      <GlobalContextProvider>
          <Router>
            <App />
          </Router>
      </GlobalContextProvider>
    </DimensionsContextProvider>
  </React.StrictMode>
);

const sendAnalytics = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}

reportWebVitals(sendAnalytics);
