import React, { useContext } from "react"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import Popover from "@mui/material/Popover";
import styled, { withTheme } from "styled-components";
import { getDarkestColor, getFGColor } from "./styles/colors";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { DimensionsContext } from "./context/dimensionsContext";
import { useEffectOnce, useUpdateEffect } from "usehooks-ts";

const InfoPopover = (props) => 
    <MouseOverPopover
        containerStyle={{display: "inline-flex", alignItems: "center",
        flexWrap: "wrap"}}
        visibleContent={<InfoOutlinedIcon fontSize="small" color={props.theme.darkbg} style={{paddingLeft: "var(--standard-p)"}} />}
        hiddenContent={<p style={{
          padding: "calc(2 * var(--standard-p))", 
          width: "minmax(fit-content, 50%)", 
          color: getDarkestColor(props), 
          backgroundColor: props.theme.name === "light" ? props.theme.white : props.theme.darkbg,
        }}>{props.text}</p>}
    />;

export const ThemedInfoPopover = withTheme(InfoPopover);

export const FamilyPopover = (props) => {
  const containerStyle = {
    display: "inline-block", 
    flexWrap: "wrap", 
    top: "calc(2 * var(--standard-p))", 
    position: "relative",
    height: "1.43em" //line height * font size
  };

  const visibleContent = <Diversity3Icon viewBox="0 4 24 20" color={props.theme.darkbg} style={{paddingRight: "var(--standard-p)", fontSize: "2em", height: "100%"}} />;

  const hiddenContent = <p style={{
    padding: "calc(2 * var(--standard-p))", 
    maxWidth: "minmax(fit-content, 50%)", 
    color: getDarkestColor(props), 
    backgroundColor: props.theme.name === "light" ? props.theme.white : props.theme.darkbg,
  }}>{props.text}</p>;

    return (<MouseOverPopover
        containerStyle={containerStyle}
        visibleContent={visibleContent}
        hiddenContent={hiddenContent}
        noShow={props.text === ""}
    />);
}

export const ThemedFamilyPopover = withTheme(FamilyPopover);

export const MouseOverPopover = ({visibleContent, hiddenContent, noShow, containerStyle}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
  
    const handlePopoverOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handlePopoverClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
  
    return (
      noShow ?
      <></> :
      <div className="hover" style={containerStyle} onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose} >
        {visibleContent}
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: "none",
            borderRadius: "var(--standard-p)",
            maxWidth: "min(100%, 80vh)"
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
            {hiddenContent}
        </Popover>
      </div>
    );
  }

  //{children, text, open, origins}
const UnthemedFixedPopover = (props) => {
  const anchorRef = useRef();
  const actionRef = useRef();
  const { appWidth, appHeight } = useContext(DimensionsContext); // important!
  const [anchorPosition, setAnchorPosition] = useState({left: 0, top: 0});

  const [anchorDimensions, setAnchorDimensions] = useState({
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  });

  //console.log("in Popover:", appWidth, appHeight, anchorPosition, anchorDimensions);

  useEffect(() => {
    if (anchorRef.current) {
      //console.log("in inside")
      const rect = anchorRef.current.getBoundingClientRect();
      setAnchorDimensions({
          top: rect.top,
          bottom: rect.bottom,
          left: rect.left,
          right: rect.right,
          width: rect.width,
          height: rect.height
      });
      var left, top;
      //anchor: { vertical: "top", horizontal: "right" },
      //transform: { vertical: "bottom", horizontal: "left" },
      switch (props.origins.anchor.horizontal) {
        case "center":
          //console.log("center")
          left = (rect.left + rect.right) / 2;
          if (props.origins.transform.horizontal === "left") {
            left -= offsetXPx;
          } else if (props.origins.transform.horizontal === "right") {
            left += offsetXPx;
          } 
          break;
        case "left":
          //console.log("left")
          left = rect.left;
          break;
        default: //case "right" //TODO: fix this!
          //console.log("right");
          left = rect.left + rect.width;
          break;
      }
      switch (props.origins.anchor.vertical) {
        case "center":
          top = (rect.top + rect.bottom) / 2;
          break;
        case "top":
          top = rect.top - offsetYPx;
          break;
        default: //case "bottom"
          top = rect.bottom + offsetYPx;
          break;
      }
    
      setAnchorPosition({
        left: Math.floor(left),
        top: Math.floor(top)
      });
    } 
  }, [anchorRef, anchorRef.current, appWidth, appHeight, props.key])

  // useEffect(() => {
  //   if (actionRef && actionRef.current) {
  //     actionRef.current.updatePosition();
  //     console.log("updating position")
  //   }
  // })

  const isTop = props.origins.transform.vertical === "top";
  const isRight = props.origins.transform.horizontal === "right";
  const isCenter = props.origins.transform.horizontal === "center";

  const arrowSize = `calc(2 * var(--standard-p))`;
  const offsetXPx = anchorDimensions.width /
    (props.origins.anchor.horizontal === "center"
    ? 2
    : 11);
  const offsetYPx = 4; //TODO: make flexible

  const distNeg = `calc(${offsetXPx}px - calc(0.707 * ${arrowSize}))`; 
  // 0.707 is approx. 0.5 * sqrt(2)
  // i.e. the multiplier of ${arrowSize} to get half the diagonal of an ${arrowSize}-sided square

  const arrowSx = (before) => {
    return {
      zIndex: before ? -1 : 1,
      content: '""',
      display: "block",
      position: "absolute",
      top: isTop ? 0 : "unset",
      bottom: isTop ? "unset" : 0,
      right: isRight ? distNeg : "unset",
      left: isRight ? "unset" : isCenter ? "50%" : distNeg,
      width: arrowSize,
      height: arrowSize,
      backgroundColor: props.theme.name === "light" ? props.theme.white : props.theme.darkbg,
      transform: `translateY(${isTop ? "-50%" : "50%"}) rotate(45deg)`,
      boxShadow: before ? "inherit" : "unset"
    };
  };

  return (
    <div ref={anchorRef} style={{alignItems: "center", flexWrap: "wrap"}} >
      {props.children}
      <Popover 
        //key={props.defaultOffsetX}
        open={props.open}
        action={actionRef}
        disableScrollLock={true}
        disablePortal={true}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition}
        anchorOrigin={{ // DO NOT CHANGE
          vertical: "top",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: props.origins.transform.vertical,
          horizontal: props.origins.transform.horizontal,
        }}
        slotProps={{
          paper: {
            sx: {
              overflow: "visible",
              "&:before": arrowSx(true),
              "&:after": arrowSx(false),
            },
          },
        }}
      >
        <PopoverText>
          {props.text}
        </PopoverText>
      </Popover>
    </div>
  );
}

export const FixedPopover = withTheme(UnthemedFixedPopover)

const PopoverText = styled.p`
  font-size: ${2.5 / 8 * 7}vh; // Original p fontsize
  padding: 1.25vh; // Original 2 * var(--standard-p)
  border-radius: ${0.625}vh; // Original var(--standard-p) 
  width: minmax(fit-content, 50%);
  color: ${(props) => getDarkestColor(props)};
  background-color: ${(props) => props.theme.name === "light" ? props.theme.white : props.theme.darkbg};
`;

export default InfoPopover