import React from "react";

const Emoji = (props) => (
    <span
        role="img"
        aria-label={props.label ? props.label : ""}
        aria-hidden={props.label ? "false" : "true"}
    >
        {props.prefix}
        {props.symbol}
        {props.suffix}
    </span>
);

export const TextWithEmoji = (props) => (
    <>
        {props.prefix}
        <Emoji symbol={props.emoji} />
        {props.suffix}
    </>
);

export default Emoji;