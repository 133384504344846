import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Slider } from '@mui/material'
import { getFGColor, newShade, setOpacity } from "./styles/colors.js"
import {withTheme} from "styled-components"
import { relativeSize } from './ShadowScaler.js';

const MyUnthemedSlider = ({ defaultValue, onChange, ...props }) => {
    const [currentValue, setCurrentValue] = useState(() => defaultValue);

    const hasLightTheme = props.theme.name === "light";
    const primaryColor = hasLightTheme ? props.theme.darkbg : props.theme.medbg;
    const secondaryColor = hasLightTheme ? props.theme.medbg : props.theme.darkbg;

    const dist = props.size; //40
    const steps = 2;
    const thumbSize = dist; //40
    const trackHeight = 0.75 * dist; //30
    const width = dist * steps + trackHeight; //110
    const fontSize = 0.5 * dist; //20
    const trackWidth = width - (thumbSize - trackHeight); //100

    const markPadding = (trackHeight - fontSize) / 2;
    const sliderPadding = (thumbSize - trackHeight) / 2;

    const slotProps = {
      root: {
        sx: {
          width: width, 
          height: trackHeight,
          margin: "0 !important",
          padding: `${sliderPadding}px !important`,
          paddingLeft: 2 * sliderPadding,
        }
      },
      thumb: {
        sx: {
          width: thumbSize,
          height: thumbSize,
          color: primaryColor,
          boxShadow: `${setOpacity(primaryColor, 0.6)} 0 ${relativeSize(1, 1)} ${relativeSize(5, 5)}`,
          "&:hover": {
            boxShadow: `0 0 0 ${relativeSize(8, 8)} ${setOpacity(primaryColor, 0.16)}`,
          },
          "&.Mui-focusVisible": {
            boxShadow: `0 0 0 ${relativeSize(8, 8)} ${setOpacity(primaryColor, 0.16)}`,
          },
          "&.Mui-active": {
            boxShadow: `0 0 0 ${relativeSize(14, 14)} ${setOpacity(primaryColor, 0.16)}`,
          },
        }
      },
      rail : {
        sx: {
            borderRadius: "999px",
            width: trackWidth,
            height: trackHeight,
            color: secondaryColor,
            opacity: 1,
            marginLeft: `${sliderPadding}px !important`,
            paddingLeft: `${sliderPadding}px !important`,
          }
      },
      track: {
        sx: {
          borderRadius: "999px",
          width: trackWidth,
          height: trackHeight,
          color: newShade(primaryColor, 20),
          marginLeft: `${sliderPadding}px !important`,
        }
      },
      mark: {
        sx: {
          display: "none",
        }
      },
      markLabel: {
        sx: {
            display: "block", 
            margin: "auto",
            width: trackHeight,
            height: trackHeight,
            top: `-${trackHeight}px !important`,
            paddingTop: `${trackHeight + markPadding + 0.4 * sliderPadding}px !important`,
            fontSize: fontSize,
          }
      }
    };

    const toggleColor = (n, props) => {
      if (currentValue > n) {
        return getFGColor(props, primaryColor);
      }
      return getFGColor(props, secondaryColor);
    }

    const marks = [
      {
        value: 1,
        label: <FontAwesomeIcon icon={icon({name: "1"})} color={toggleColor(1, props)} />,
      },
      {
        value: 2,
        label: <FontAwesomeIcon icon={icon({name: "2"})} color={toggleColor(2, props)} />,
      },
      {
        value: 3,
        label: <FontAwesomeIcon icon={icon({name: "3"})} color={toggleColor(3, props)} />,
      },
    ];

    return (
        //<div style={{padding: `0 5`, width: width, height: thumbSize, position: "absolute"}}>
          <Slider
            aria-label="Level"
            value={currentValue ? currentValue : defaultValue}
            onChangeCommitted={(_, val) => {setCurrentValue(val); onChange(val)}}
            step={null}
            marks={marks}
            //min={1}
            //max={3}
            min={0.5} // dist=40, trackHeight/2=15, (thumbSize-trackHeight)/2=5, so 3*5/40=0.375 extra
            max={3.5}
            valueLabelDisplay="off"
            slotProps={slotProps}
          />
      //</div>
    );
};

const MySlider = withTheme(MyUnthemedSlider);

export default MySlider;