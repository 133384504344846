import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";

const useAutoplay = (length, delay, defaultSlide, autoStart) => {
    const [currentSlide, setCurrentSlide] = useState(defaultSlide);
    const timeoutRef = useRef(null);
    const isPlayingRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(autoStart);

    const resetTimeout = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }

    const play = () => {
        isPlayingRef.current = true;
        setIsPlaying(true);
    }

    const pause = () => {
        isPlayingRef.current = false;
        setIsPlaying(false);
        //resetTimeout();
    }

    useEffect(() => {
        isPlayingRef.current = autoStart;
    }, [])
  
    
    useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(() => {
            if (isPlayingRef.current) {
                setCurrentSlide((prevVal) =>
                    prevVal === length - 1 ? 0 : prevVal + 1
                )
            }
        }, delay);
    
        return () => {
            resetTimeout();
        };
    }, [currentSlide, isPlayingRef?.current]);

    return [currentSlide, setCurrentSlide, isPlaying || isPlayingRef?.current, play, pause]
}

export default useAutoplay