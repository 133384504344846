import { useContext, useEffect, useState } from "react";
import Home from "./Home";
//import { use100vh } from "react-div-100vh";
//import { useWindowHeight } from "./hooks/useWindowWidth";
import { Navigate, Outlet, Route, Routes, Switch, useLocation, useParams } from "react-router-dom";
import { getCookie, isCookieSet } from "./cookies";
import { GlobalContext } from "./context/globalContext";
import Layout from "./Layout";
import IntroScreen from "./innerScreens/IntroScreen";
import CookiesScreen from "./innerScreens/CookiesScreen";
import FAQScreen from "./innerScreens/FAQScreen";
import HowToPlayScreen from "./innerScreens/HowToPlayScreen";
import { InnerScreenRoute, LetterHeader } from "./InnerScreen";
import NotFoundScreen from "./innerScreens/NotFoundScreen";
import AppFallBack from "./errorHandling/AppFallBack";
import { ErrorBoundary } from "react-error-boundary";
import { getFormattedDate } from "./date";
import { DimensionsContext } from "./context/dimensionsContext";
import { withTheme } from "styled-components";
import ReactGA from "react-ga4"

const QRPath = "/qr";

const App = () => {
  const { appHeight } = useContext(DimensionsContext);

  //Set theme
  const { theme, themeSwitchHandler } = useContext(GlobalContext);
  useEffect(() => {
    window.localStorage.setItem("theme", theme);
  }, [theme]);

  // Decide route
  const [visitedToday, setVisitedToday] = useState(isCookieSet("last") && getCookie("last") === getFormattedDate());

  useEffect(() => {
    if(navigator.userAgent.indexOf("iPhone") > -1 ) {
      //console.log("yes iPhone");
      document
        .querySelector("[name=viewport]")
        .setAttribute("content","width=device-width, initial-scale=1.0, maximum-scale=1.0");
    }
  }, []);

  //console.log("appHeight in router:",appHeight);

  const fallbackRender = ({ error, resetErrorBoundary }) => 
      <AppFallBack height={appHeight} error={error} resetErrorBoundary={resetErrorBoundary} />;

  return ( // element={visitedToday ? <Home /> : <Navigate to="/intro" />} /> //TODO: was appHeight // "100dvh"
    <div className="App" style={{height: appHeight}}> 
      <Layout>
        <ErrorBoundary fallbackRender={fallbackRender} >
          <Routes>
              {/* <Route path="*" exact element={<Home />} /> */}
              <Route path="/" exact element={<Home />} /> 
              <Route path={`${QRPath}/:id`} element={<RedirectQR />} />
              <Route element={<InnerScreenRoute />}>
                {/* <Route path="/intro" element={<IntroScreen height={appHeight} />} />
                <Route path="/how-to-play" element={<HowToPlayScreen />} />
                <Route path="/faq" element={<FAQScreen height={appHeight} />} />
                <Route path="/cookies" element={<CookiesScreen height={appHeight} />} /> */}
                <Route path="*" element={<NotFoundScreen height={appHeight} />} />
              </Route>
          </Routes>
        </ErrorBoundary>
        
          
          {/* <Route path="*" element={<Navigate to="/" />} /> */} 
        {/*</Layout></Routes> */}
      </Layout>
    </div>
  );
}

const RedirectQR = (props) => {
  const params = useParams();
  const path = `${QRPath}/${params.id}`;
  ReactGA.send({
    hitType: "pageview",
    page: path,
  });
  return <Navigate to="/" />;
}

export default App;