import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import styled, {withTheme} from "styled-components";
import { Flex, ThemedBarButton } from './styles/globalStyles.js'
import { getDarkestColor } from "./styles/colors.js"
import useAutocomplete from '@mui/material/useAutocomplete';
import {useErrorBoundary} from "react-error-boundary";
import { relativeSize } from './ShadowScaler.js';
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { useEffectOnce } from 'usehooks-ts';
import SnackBar from './SnackBar.js'

const relative1Pixel = relativeSize(1, 1);
const relative2Pixel = relativeSize(2, 2);
const shadowString = `drop-shadow(${relative1Pixel} ${relative1Pixel} ${relative1Pixel} rgba(44,64,111,0.3))`;

const InvalidGuessSnackbar = (props) => <SnackBar {...props} message={"This language isn't an option. Remove or add letters to guess."} />;

const Listbox = styled.ul`
    max-height: calc(6 * var(--standard-h) + 7 * var(--standard-p));
    height: auto;
    width: 100%;
    margin: 0;
    padding: 0; 
    overflow: auto;
    border-radius: var(--standard-p);
    border: ${relative1Pixel} solid ${props => getDarkestColor(props)};
    list-style: none;
    zIndex: 1;
    position: absolute;
    bottom: 100%;
    -moz-filter: ${shadowString};
    -webkit-filter: ${shadowString};
    -o-filter: ${shadowString};
    -ms-filter: ${shadowString};
    filter: ${shadowString};
    & li {
        padding: var(--standard-p) !important;
        padding-left: calc(2 * var(--standard-p)) !important;
        background-color: ${props => props.theme.name === "light" ? "#ffffff" : props.theme.darkbg} !important;
        color: ${props => getDarkestColor(props)} !important;
        cursor: pointer;
    },
    & li:hover {
        background-color: ${props => props.theme.name === "light" ? props.theme.plainbg : props.theme.medbg} !important;
    }
`;

const ThemedListItem = withTheme(Listbox);

const LangForm = forwardRef((props, ref) => {
    const [isDisabled, setIsDisabled] = useState(false);
    const currentVal = useRef("");
    const inputRef = useRef();
    const formRef = useRef();
    const [invalidGuessSnackBarOpen, setInvalidGuessSnackBarOpen] = useState(false);
    const [confirmMode, setConfirmMode] = useState(false);

    const {showBoundary} = useErrorBoundary();

    const {
        getRootProps,
        getInputProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
      } = useAutocomplete({
        id: 'langmenu-autocomplete',
        options: props.cands.lname,
        getOptionLabel: (option) => option,
        blurOnSelect: true,
        disablePortal: true,
        disabled: isDisabled,
        freeSolo: true,
        openOnFocus: true,
        // onChange: {(ev, val) => {
        //     // if (val !== null) {
        //     //     console.log("onChange:", val, "(submitting, this was a click/enter)");
        //     // } else {
        //     //     console.log("onChange:", val, "(no action, this was a delete)");
        //     // }
        // }},
        onInputChange: (_, val, reason) => { 
            // if (reason === "reset") {
            //     if (val !== "") {
            //         //console.log("onInputChange:", val, reason, "(resetting entry)");
            //          onLangClick(val);
            //     } else {
            //         //console.log("onInputChange:", val, reason, "(no action, can't reset here)");
            //     }
            // } else {
                //console.log("onInputChange:", val, reason, "(no action, this was an input)");
                currentVal.current = val;
                //console.log(reason)
                if (reason === "reset") { // Menu item selected
                    setConfirmMode(true);
                }
            //} 
        }
      });

    useEffect(() => {
        props.onOpenLangMenu(() => () => getInputProps().ref.current.focus());
    }, [])


    useEffect(() => {
        if (isDisabled) {
            const timer = setTimeout(() => {
                setIsDisabled(false);
            }, Math.max(0, Math.min(5000, props.delay))); 
            return () => clearTimeout(timer);
        }
    }, [props.num, props.delay]);

    const submitLang = async (candName) => {
        const index = matchLang(candName);
        if (index !== -1) { 
            currentVal.current = "";
            props.onSubmit(props.cands.lname[index], props.cands.lid[index]).then((resp) => setIsDisabled(true)).catch(error => showBoundary(error));
        } else {
            setInvalidGuessSnackBarOpen(true);
        }
    };

    // Returns the index of the unique language that contains the string cand; -1 otherwise
    // Equality takes precedence over "contains"
    const matchLang = (cand) => {
        const target = cand.trim().toLowerCase();
        const indices = [];

        for (let i in props.cands.lname) {
            if (props.cands.lname[i].toLowerCase().includes(target)) {
                if (target === props.cands.lname[i].toLowerCase()) {
                    return i;
                }
                indices.push(i);
            }
        }

        if (indices.length === 1) { 
            return indices[0];
        }
        return -1;
    };

    // // Called when user clicks on language
    // const onLangClick = (val) => {
    //     submitLang(val);
    //     getInputProps().ref.current.blur();
    // }
    
    // Called when the form submits
    // I.e. when enter or the submit button is pressed
    const onSubmit = (ev) => {
        //console.log("onSubmit, setConfirm=false, submitLang, blur");
        ev.preventDefault();
        setConfirmMode(false);
        submitLang(currentVal.current);
        getInputProps().ref.current.blur();
    };

    return (
        <Flex ref={formRef} as="form" onSubmit={onSubmit} $col > 

            <div {...getRootProps()} >
                <Input ref={ref} type="search" {...getInputProps()} placeholder="Guess language ..." value={currentVal.current} onFocus={() => {setConfirmMode(false); if (formRef.current) {
      const offsetBottom = formRef.current.top + formRef.current.height;
      formRef.current.scrollIntoView();
      //console.log("hello");
    } else {
        //console.log("nein");
    }}} />
            </div>
            {groupedOptions.length > 0 ? (
                <ThemedListItem {...getListboxProps()}>
                    {groupedOptions.map((option, index) => { 
                        const matches = match(option, currentVal.current, { insideWords: true });
                        const parts = parse(option, matches);
                        return (
                            <li {...getOptionProps({ option, index })}>
                                <h3>
                                    {parts.map((part, index) => (
                                        <span
                                            key={index}
                                            style={{
                                            fontWeight: part.highlight ? 700 : 400,
                                            }}
                                        >
                                            {part.text}
                                        </span>
                                    ))}
                                </h3>
                            </li>
                        )
                    })}
                </ThemedListItem>
            ) : null}

            <ThemedBarButton type="submit" disabled={isDisabled} startIcon={
                <img 
                    src={`/icons/icon64x64${props.theme.name === "light" ? "" : "inv"}.png`} 
                    style={{width: "min(1em, 64px)", height: "min(1em, 64px)"}} 
                    alt="" 
                />
            } >
                {confirmMode ? "confirm" : "submit"}
            </ThemedBarButton>
            <InvalidGuessSnackbar open={invalidGuessSnackBarOpen} onClose={(ev, reason) => setInvalidGuessSnackBarOpen(false)}/>  
        </Flex>
    );
});

export const Input = styled.input`
    height: var(--standard-h);
    font-size: var(--textFontSize);
    padding-left: calc(2 * var(--standard-p));
    padding-right: var(--standard-p);
    border-radius: var(--standard-p);
    background-color: ${props => "#ffffff"};
    color: ${props => getDarkestColor(props)};
    border: ${relative2Pixel} solid ${props => props.theme.darkbg}; 
    :focus {
        outline: none;
        border-color: ${props => props.theme.medbg};
    };
    width: 100%;
    box-shadow: 0 ${relativeSize(3, 2)} ${relative1Pixel} ${relativeSize(-2, -2)} rgba(44,64,111,0.2), 
        0 ${relative2Pixel} ${relative2Pixel} 0 rgba(44,64,111,0.14), 
        0 ${relative1Pixel} ${relativeSize(5, 3)} 0 rgba(44,64,111,0.12);
`;

export default withTheme(LangForm);