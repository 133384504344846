import React, { useContext, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { getCookie, setCookie, levels, isCookieSet } from '../cookies.js'
import { getFormattedDate } from '../date.js'
import { useJsonFetch } from "../hooks/useCacheFetch";
import { levelStartGA } from '../Analytics';
import { GlobalContext } from './globalContext';
import { useEffectOnce, useUpdateEffect } from 'usehooks-ts';
import axios from "axios";
import { alreadyGameOver, partitionGameOver } from '../end.js'
import MidnightModal from '../modals/Midnight';
import { Container as ModalContainer } from 'react-modal-promise'

const baseURL = `${process.env.REACT_APP_API_URL}`;

const getStartingLevel = () => {
    const today = getFormattedDate();
    if (!isCookieSet("beta") && today === getCookie("last")) {
      return getCookie("lvl");
    }
    setCookie("lvl", 1);
    return 1;
  }

export const LevelContext = React.createContext({
    sample: undefined,
    rtl: undefined,
    cands: undefined,
    isLoaded: false,
    hasAudio: false,
    level: getStartingLevel(),
    setLevel: () => {},
    onMidnight: () => {}
});

const LevelContextProvider = (props) => {  
    const [midnightIsOpen, setMidnightIsOpen] = useState(() => false);

    const {showBoundary} = useErrorBoundary();

    const [sample, setSample] = useState(() => "");
    const [rtl, setRtl] = useState(() => false);
    const [cands, setCands] = useState(() => []);
    const [isLoaded, setIsLoaded] = useState(() => false);
    const [hasAudio, setHasAudio] = useState(() => false);
    const [level, setLevel] = useState(() => getStartingLevel());
  
    const { lastVisit, onRollOver } = useContext(GlobalContext);
  
    const [url, setUrl] = useState("");
  
    const effect = (args) => {
      if (!alreadyGameOver(level)) {
        setCands(args.cands);
      }
      setSample(args.sample);
      setRtl(args.rtl ? args.rtl : false);
      setHasAudio(args.audio ? args.audio : false);
      setIsLoaded(true);
    };
  
    const errorEffect = (error) => {
      //console.log("Got here in errorEffect");
      showBoundary(error);
    };
  
    const { status, data, error } = useJsonFetch(url, effect, errorEffect);
  
    levelStartGA(level);
  
    useEffectOnce(() => { //TODO: Merge with the below? No, bc rollOver changes lastVisit
      let today = getFormattedDate();
      if (today !== getCookie("last")) {
        onRollOver();
      }
      setIsLoaded(false);
      fetchSample(today, false);
    }, []);
  
    useUpdateEffect(() => { //TODO: useUpdateEffect?
      setIsLoaded(false);
      fetchSample(lastVisit, alreadyGameOver(level));
    }, [level]); 
    
    const fetchSample = async (date, done) => {43
      const encodeParams = (obj) => {
        let arr = [];
        Object.keys(obj).forEach((key) => 
        arr.push(`${key}=${encodeURIComponent(obj[key])}`));
        return arr;
      };
     
      const params = {c: Number(!done), d: date, lvl: level}; 
      setUrl(`${process.env.REACT_APP_API_URL}/sample?${encodeParams(params).join("&")}`);
    }

     
  const openMidnightModal = async (info) => {
    return MidnightModal(setMidnightIsOpen, info);
  }

  const onMidnight = async () => {
    const [unfinishedLvls, finishedLvls] = partitionGameOver();
    if (unfinishedLvls.length !== 0) {
      const result = await axios.get(`${baseURL}/solution`, {params: {lvls: JSON.stringify(unfinishedLvls), d: lastVisit}}).then((resp) => {
          const info = levels.map(lvl => finishedLvls.includes(lvl) ? getCookie("info", lvl) : resp.data[`info${lvl}`]);
          return openMidnightModal(info);
      }).catch((error) => {
        showBoundary(error);
      });
    } else {
      const info = levels.map(lvl => getCookie("info", lvl));
      const result = await openMidnightModal(info);
    }
    setIsLoaded(false);
    fetchSample(onRollOver(), false);
  };

  //console.log(sample, cands, isLoaded, hasAudio, level);
  
  return (
      <LevelContext.Provider
          value={{
              sample: sample,
              rtl: rtl,
              cands: cands,
              isLoaded: isLoaded,
              hasAudio: hasAudio,
              level: level,
              setLevel: setLevel,
              onMidnight: onMidnight
          }}
      >
          {props.children}
          <ModalContainer />
      </LevelContext.Provider>
  );
};

export default LevelContextProvider;