import { useEffect, useState } from "react";
import AppHeader from "./AppHeader";
import AppBody from "./AppBody";
import AppFooter from "./AppFooter";
import "./App.css";
import { getCookie, isCookieSet, resetCookies, setCookie } from "./cookies";
import { addDays, getFormattedDate } from "./date";
//import BetaModal from "./modals/Beta";
//import bugFix from "./bugFix";
import { useEffectOnce, useMediaQuery, useUpdateEffect } from "usehooks-ts";
import { ErrorBoundary } from "react-error-boundary";
import AppBodyFallback from "./errorHandling/AppBodyFallback";
import LevelContextProvider from "./context/levelContext";

// const handleBeta = () => {
//   resetCookies(false);
// }

// const firstVisitToday = () => getFormattedDate() !== getCookie("last");

const Home = () => {
  const today = getFormattedDate(addDays(-1));
  //const [level, setLevel] = useState(() => getStartingLevel()); 
  //const [hasAudio, setHasAudio] = useState(() => false);
  //const [betaIsOpen, setBetaIsOpen] = useState(() => isCookieSet("beta"));
  //const [handledBeta, hasHandledBeta] = useState(false);
  //const [fixedBugs, setHaveFixedBug] = useState(() => !firstVisitToday() || bugFix()); // Fix bugs on new day

  // useEffectOnce(() => {
  //   if (betaIsOpen) {
  //     handleBeta();
  //     hasHandledBeta(true);
  //   }
  // }, [])

  return (
    <LevelContextProvider>
        <AppHeader date={today}/>
        <ErrorBoundary FallbackComponent={AppBodyFallback} >
            <AppBody />
            {/*betaIsOpen && <BetaModal setIsOpen={setBetaIsOpen}/>*/}
        </ErrorBoundary>
        <AppFooter />
    </LevelContextProvider>
  );
}

export default Home;
