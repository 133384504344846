import styled from "styled-components"
import { Flex } from './styles/globalStyles.js'

const LangleLogo = styled(Flex)`
    margin: 0;
    padding: 0;
    min-width: 0;
    height: 0;
    flex-grow: 1;
    min-height: 100%;
    font-size: 1em;
    transform: scale(calc(1/3));
    &:hover {
      cursor: pointer;
    }
`;

const LangleL = styled.div`
  font-family: 'Alegreya', serif;
  font-weight: 550;
  font-size: 5.375em;
  transform: translate(calc(1em / 5.375), calc(-0.125em / 5.375)) rotate(40deg);
  width: min-content;
  height: min-content;
`;

const LangleR = styled.div`
  font-family: 'Alegreya', serif;
  font-weight: 900;
  font-size: 3em;
`;

const Logo = (props) => {
  return (
    <LangleLogo as="h1" $row onClick={props.onClick} >
        <LangleL>L</LangleL>
        <LangleR>aŋgle</LangleR>
    </LangleLogo>
  );
}

export default Logo
