import React from "react"
import styled from "styled-components";

const FlexGridStyles = styled.div`
    position: relative;
    width: min(100%, fit-content);
    margin: 0 auto;
    text-align: center;
    display: grid;
    padding: 0 var(--standard-p);
    grid-template-columns:
        ${props => `${`
            auto
            minmax(calc(2 * var(--standard-p)), calc(${props.xGap || 2} * var(--standard-p))) 
        `.repeat(props.cols - 1)}
        auto`};
    row-gap: calc(${props => props.yGap || 2} * var(--standard-p));
    grid-auto-flow: row;
`;

export const FlexGrid = (props) => {
    const {cols, columnStyles, children, ...otherProps} = props;
    if (columnStyles && cols !== columnStyles.length) {
        throw Error("Incompatible list lengths");
    }

    const childrenWithColumnStyles = () => {
        return React.Children.map(children, (child) => {
            return React.cloneElement(child, {
                columnStyles: columnStyles
            });
        });
    };

    return (
        <FlexGridStyles cols={cols} {...otherProps}>
            {childrenWithColumnStyles()}
        </FlexGridStyles>
    )
}

export const FlexGridRow = ({columnStyles, children}) => {
    const n = children.length;
    if (columnStyles && columnStyles.length !== n) {
        throw Error("Incompatible list lengths");
    } else if (!columnStyles) {
        columnStyles = {};
    }

    const renderChildren = () => {
        return React.Children.map(children, (child, i) => {
            return React.cloneElement(child, {
                style: { ...columnStyles[i], gridColumn: 2 * i + 1, height: "fit-content", margin: "auto 0" },
            });
        });
    };

    return (
        <>
            {renderChildren()}
        </>
    )
}