import { useContext } from "react";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import { normalize } from "styled-normalize";
import { lightBlueTheme, darkBlueTheme } from './styles/themes.js'
import { GlobalContext } from "./context/globalContext";

export const baseFontSize = 16;

export const barHeight = 2;
export const padding = 0.25;

const GlobalStyle = createGlobalStyle`
    ${normalize}

    html {
      font-size: ${baseFontSize}px;
    }

    .MuiTypography-root {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
      font-weight: 400 !important;
    }

    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-weight: 400;
        max-height: 100000px;
    }

    /* Too narrow viewport width */
    @media (max-aspect-ratio: 10/22) {
      :root {
        --textFontSize: 5.5vw; //1rem;
      }
    }

    @media (min-aspect-ratio: 10/22) {
      :root {
        --textFontSize: 2.5vh; //1rem;
      }
    }

    body {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: var(--textFontSize);
    }

    :root {
        --standard-h: calc(${barHeight} * var(--textFontSize));
        --standard-p: calc(${padding} * var(--textFontSize));
        color: ${(props) => props.theme.darkbg};
        background-color: ${props => props.theme.plainbg};
    }
    
    h1 {
        font-size: calc( 2 * var(--textFontSize));
    }
    
    h2 {
        font-size: calc( 1.5 * var(--textFontSize));
    }

    h3 {
        font-size: var(--textFontSize);
    }
    
    p {
        font-size: calc( 7 / 8 * var(--textFontSize));
        //line-height: 1.43;
        //letter-spacing: 0.01071em;
    }

    li {}
      max-height: 100000px;
    }
`;

const Layout = ({ children }) => {
  const currentTheme = useContext(GlobalContext);

  let theme;

  switch (currentTheme.theme) {
    case "dark":
      theme = darkBlueTheme;
      break;
    case "light":
      theme = lightBlueTheme;
      break;
    default:
      theme = lightBlueTheme;
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  );
};

export default Layout;