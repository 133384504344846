import { useContext,  useEffect, useState } from "react";
import Modal from "../Modal";
import ToggleSwitchWrapper from '../ToggleSwitch';
import MySlider from "../Slider";
import { GlobalContext } from "../context/globalContext";
import { getCookie, setCookie } from '../cookies.js'
import { FlexGrid, FlexGridRow } from "../genericComps/FlexGrid.js";
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { ThemedBarButton } from "../styles/globalStyles";
import { DimensionsContext } from "../context/dimensionsContext";

const columnStyles = [
    {textAlign: "right"},
    {width: "fit-content"},
]

const Setting = (props) => {
    return (
        <FlexGridRow {...props} >
            <h3>{props.name}: </h3>
            <div>{props.content}</div>
        </FlexGridRow>
    );
}

const themeToBool = (theme) => (theme === "light");
const boolToTheme = (b) => b ? "light" : "dark";

const SettingsModal = (props) => {
    const [showConfetti, setShowConfetti] = useState(getCookie("confetti"));

    const { theme, themeSwitchHandler } = useContext(GlobalContext);
    const { appWidth, appHeight } = useContext(DimensionsContext);
    const lightIcon = icon({name: "sun"});
    const darkIcon = icon({name: "moon"});

    useEffect(() => {
        window.localStorage.setItem("theme", theme);
    }, [theme]);

    const onThemeChange = (val) => {
        themeSwitchHandler(boolToTheme(val));
    }

    const size = 2 * ( //TODO: Turn into hook
        appWidth / appHeight >= 10 / 22
            ? appHeight / 100 * 2.5
            : appWidth / 100 * 5.5 );

    return (
        <Modal setIsOpen={props.setIsOpen} title="Settings">
            <FlexGrid cols={2} xGap={6} yGap={4} columnStyles={columnStyles}>
                <Setting name="Difficulty level" content={<MySlider size={size} defaultValue={props.level} onChange={(val) => {setCookie("lvl", val); props.onLevelChange(val); props.setIsOpen(false);}} />} />
                <Setting name="Toggle mode" content={<ToggleSwitchWrapper size={size} defaultValue={themeToBool(theme)} onChange={onThemeChange} rightIcon={lightIcon} leftIcon={darkIcon} />} />
                <Setting name="Show confetti on win" content={<ToggleSwitchWrapper size={size} defaultValue={showConfetti} onChange={(val) => {setCookie("confetti", val); setShowConfetti(val);}} />} />
                {/* <Setting name="Autoplay sound" content={<ToggleSwitch size={40} defaultValue={props.autoplay} onChange={(val) => {setCookie("autoplay", val); props.setAutoplay(val);}} />} /> */}
                <Setting name="Cookie preferences" content={<ThemedBarButton onClick={() => {props.setIsOpen(false); props.openCookiesModal(true);}}>Review</ThemedBarButton>} />
            </FlexGrid>
        </Modal>
    );
};

export default SettingsModal;