import { useEffect } from "react";
import { useRef } from "react";
import { useEffectOnce, useUpdateEffect } from "usehooks-ts";
import useWindowWidth, { useWindowHeight } from "../hooks/useWindowWidth";
import { useState } from "react";
import { useContext } from "react";
import { GlobalContext } from "../context/globalContext";

// adapted from: https://www.npmjs.com/package/intrinsic-scale
function getObjectFitSize(
  contains /* true = contain, false = cover */,
  containerWidth,
  containerHeight,
  width,
  height
) {
  var doRatio = width / height;
  var cRatio = containerWidth / containerHeight;
  var targetWidth = 0;
  var targetHeight = 0;
  var test = contains ? doRatio > cRatio : doRatio < cRatio;

  if (test) {
    targetWidth = containerWidth;
    targetHeight = targetWidth / doRatio;
  } else {
    targetHeight = containerHeight;
    targetWidth = targetHeight * doRatio;
  }

  return {
    width: targetWidth,
    height: targetHeight,
    x: (containerWidth - targetWidth) / 2,
    y: (containerHeight - targetHeight) / 2
  };
}

var PIXEL_RATIO = (function () {
    var ctx = document.createElement("canvas").getContext("2d"),
        dpr = window.devicePixelRatio || 1,
        bsr = ctx.webkitBackingStorePixelRatio ||
              ctx.mozBackingStorePixelRatio ||
              ctx.msBackingStorePixelRatio ||
              ctx.oBackingStorePixelRatio ||
              ctx.backingStorePixelRatio || 1;

    return dpr / bsr;
})();

const setWidth = () => Math.ceil(Math.max(window.innerWidth, document.body.scrollWidth, document.documentElement.scrollWidth));

const setHeight = (heightFragment) => Math.ceil(heightFragment * window.innerHeight);

const Canvas = ( { draw, heightFragment, headerContentStart, realDimensions } ) => {
    const canvasRef = useRef();
    
    const [ratio, setRatio] = useState(PIXEL_RATIO);
    const [toRedraw, setToRedraw] = useState(false);

    const windowWidth = useWindowWidth();
    const windowHeight = useWindowHeight();
    const [letterFadeWidth, setLetterFadeWidth] = useState(setWidth());
    const [letterFadeHeight, setLetterFadeHeight] = useState(setHeight(heightFragment));
    const [realWidth, setRealWidth] = useState(0);
    const [realHeight, setRealHeight] = useState(0);

    const {theme, themeSwitchHandler} = useContext(GlobalContext);

    // console.log(
    //   "body scroll:", document.body.scrollWidth, 
    //   "doc scroll:", document.documentElement.scrollWidth,
    //   "body offset:", document.body.offsetWidth, 
    //   "doc offset:", document.documentElement.offsetWidth,
    //   "body client:", document.body.clientWidth, 
    //   "doc client:", document.documentElement.clientWidth,
    //   "window outer:", window.outerWidth,
    //   "window inner:", window.innerWidth,
    // );

    useEffect(() => {
        const [w, h] = [setWidth(), setHeight(heightFragment)];
        setLetterFadeWidth(w);
        setLetterFadeHeight(h);
        const [realW, realH] = realDimensions(setWidth(), setHeight(heightFragment));
        setRealWidth(realW);
        setRealHeight(realH);
        setToRedraw(true);
    }, [windowWidth, windowHeight])

    useEffect(() => {
        if (toRedraw) {
            setToRedraw(false);
            const context = canvasRef.current?.getContext("2d");
            context.clearRect(0, 0, realWidth, realHeight);
            context.setTransform(ratio, 0, 0, ratio, 0, 0);
            draw(letterFadeWidth, letterFadeHeight, theme === "dark")(context);
        }
    }, [toRedraw])

    return (
      <div style={{height: headerContentStart}}>
        <canvas 
            ref={canvasRef}
            width={Math.ceil(realWidth * ratio)}
            height={Math.ceil(realHeight * ratio)}
            style={{width: `${realWidth}px`, height: `${realHeight}px`, top: 0, left: 0, position: "absolute", zIndex: 0}}
        />
      </div>
    );
};

export default Canvas