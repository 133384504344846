import ReactSwitch from "react-switch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { withTheme } from "styled-components"
import { getFGColor, newShade } from "./styles/colors";
import { relativeSize } from "./ShadowScaler.js";
import { useEffect, useRef } from "react";

const ToggleSwitchWrapper = (props) => {
    const count = useRef(0);

    useEffect(() => {
        count.current += 1;
    })

    return (
        <ToggleSwitch {...props} key={count.current} />
    );
}

const UnthemedToggleSwitch = (props) => {

    const dist = props.size;
    const steps = 1;
    const thumbSize = dist;
    const trackHeight = 0.75 * dist;
    const width = dist * steps + trackHeight;
    const fontSize = 0.5 * dist;

    const hasLightTheme = props.theme.name === "light";
    const primaryColor = hasLightTheme ? props.theme.darkbg : props.theme.medbg;
    const secondaryColor = hasLightTheme ? props.theme.medbg : props.theme.darkbg;

    const style = {
        display: "block", 
        margin: "auto", 
        paddingTop: (trackHeight - fontSize) / 2,
    };

    return (
        <ReactSwitch
            checked={props.defaultValue}
            onChange={(val) => props.onChange(val)}
            onColor={newShade(primaryColor, 20)}
            onHandleColor={primaryColor} 
            offColor={secondaryColor}
            offHandleColor={primaryColor}
            handleDiameter={thumbSize}
            uncheckedIcon={
                props.rightIcon
                ? <FontAwesomeIcon icon={props.rightIcon} fontSize={fontSize} style={style} color={getFGColor(props, secondaryColor)} /> //sun
                : false
            }
            checkedIcon={
                props.leftIcon
                ? <FontAwesomeIcon icon={props.leftIcon} fontSize={fontSize} style={style} color={getFGColor(props, primaryColor)}/> //moon
                : false
            }
            boxShadow={`0 ${relativeSize(1, 1)} ${relativeSize(5, 5)} rgba(0, 0, 0, 0.6)`}
            activeBoxShadow={`0 0 ${relativeSize(1, 1)} ${relativeSize(10, 10)} rgba(0, 0, 0, 0.2)`}
            height={trackHeight}
            width={width}
        />
    );
}

const ToggleSwitch = withTheme(UnthemedToggleSwitch);

export default ToggleSwitchWrapper;