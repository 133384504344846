import Alert from '@mui/material/Alert';
import { AlertTitle } from "@mui/material";
import Button from "@mui/material/Button";
import { withTheme } from "styled-components";
import { getDarkestColor } from '../styles/colors';
import { LangleError } from './LangleError';
import { exceptionGA } from '../Analytics';
import { lightBlueTheme } from '../styles/themes';

const sentenceCase = (str) => {
  if (str.length === 0) {
    return str;
  }
  return str[0].toUpperCase() + str.slice(1).toLowerCase();
}

const UnthemedErrorMessage = ({error, resetErrorBoundary, ...props}) => {
  const action = 
    resetErrorBoundary 
      ? <Button color="inherit" size="small" onClick={() => resetErrorBoundary()}>
        RETRY
      </Button>
      : <></> ;

  exceptionGA(error);

  let errorTitle;
  let errorText;

  // if (error.response) {
  //   errorTitle = error.response.statusText; // error.response.data contains useful info
  //   errorText = "Retry or refresh the page. If that doesn't work, try again later.";
  // } else if (error.request) {
  //   errorTitle = error.message;
  //   errorText = "No response from server. Make sure you are connected to the internet.";
  // } else 
  if (error instanceof LangleError) {
    errorTitle = error.title;
    errorText = error.message;
  } else {
    errorTitle = "Oops, error occurred!"; 
    errorText = "Retry or refresh the page. If that doesn't work, try again later.";
  }

  const sx = 
    props.theme 
      ? {
        color: getDarkestColor(props), 
        backgroundColor: props.theme.name === "light" ? props.theme.white : props.theme.medbg
      }
      : {
        color: lightBlueTheme.darkbg, 
        backgroundColor: lightBlueTheme.white
      };

  return (
    <Alert severity="error" action={action} sx={sx} style={props.style}> 
      <AlertTitle>{sentenceCase(errorTitle)}</AlertTitle>
      <p>Langle got itself into a tangle! — {errorText}</p>
    </Alert>
  );
}

const ErrorMessage = withTheme(UnthemedErrorMessage);

export default ErrorMessage