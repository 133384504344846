import InnerScreen from "../InnerScreen";
import ErrorMessage from "../errorHandling/ErrorMessage";
import { LangleError } from "../errorHandling/LangleError";
import { BackButton, ButtonGroup, Flex, HomeButton, ThemedBarButton } from "../styles/globalStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

const BigBarButton = ({onClick, label, icon}) => {
    return (
        <ThemedBarButton 
            width="fit-content" 
            onClick={onClick} 
            startIcon={icon &&
                <div style={{width: "fit-content"}}>
                    <FontAwesomeIcon icon={icon} />
                </div>} 
        >
            {label}
        </ThemedBarButton>
    );
}

const NotFoundScreen = () => {

    const navigate = useNavigate();

    const buttons = 
        <ButtonGroup width="100%" $noLeft $noRight >
            <HomeButton onClick={() => navigate("/")} /> {/* TODO: /intro */}
        </ButtonGroup>;

        const NotFoundError = new LangleError("NotFoundError", "Page not found", "Where do you think you're going? Langle doesn't know the page you're looking for.");

    return (
        <InnerScreen pageTitle="404" footer={buttons}>
            <ErrorMessage error={NotFoundError} />
        </InnerScreen>
    );
};

export default NotFoundScreen;