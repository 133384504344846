import { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import { useUpdateEffect } from "usehooks-ts";
import { DimensionsContext } from "./context/dimensionsContext";
import { withTheme } from "styled-components";
import { getFGColor } from "./styles/colors";

const UnthemedAutoscaler = (props) => {
    //const innerRef = useRef();
    const outerRef = useRef();
    const [scale, setScale] = useState(0.58);
    const [toRender, setToRender] = useState(true);
    const { appWidth, appHeight } = useContext(DimensionsContext);

    useUpdateEffect(() => {
        setScale(0.58);
        setToRender(true);
    }, [appWidth, appHeight])

    useEffect(() => {
        if (toRender) {
            setToRender(false);
        }
    })

    //console.log("in Autoscaler:", scale, appWidth, appHeight)

    const multiplier = 2.5 * scale;

    const style = {
        height: "fit-content",
        width: "clamp(250px, 80vw, 500px)",
        borderStyle: "solid",
        borderWidth: "min(0.8vw, 4px)",
        borderColor: getFGColor(props, props.theme.plainbg),
        //margin: "0 auto",
        //position: "relative",
        fontSize: `${multiplier}vh`,
        "--textFontSize": `${multiplier}vh`,
        "--standard-h": `${multiplier * 2}vh`,
        "--standard-p": `${multiplier * 0.25}vh`,
        borderRadius: `${multiplier * 0.25}vh`,
        top: 0,
    }; 

    return (
        <div style={style} ref={outerRef} key={scale} {...props}>
            { props.children }
        </div>
    );
}

// const UnthemedAutoscaler = (props) => {
//     //const innerRef = useRef();
//     const outerRef = useRef();
//     const [scaling, setScaling] = useState(true);
//     const [scale, setScale] = useState(0.61);
//     const [toRender, setToRender] = useState(true);
//     const { appWidth, appHeight } = useContext(DimensionsContext);

//     useUpdateEffect(() => {
//         setScaling(true);
//         setScale(0.61);
//         setToRender(true);
//     }, [appWidth, appHeight])

//     // useEffectOnce(() => {
//     //     setToRender(true);
//     // }, [])

//     useEffect(() => {
//         setToRender(true);
//     }, [scale])

//     useEffect(() => {
//         if (toRender && scaling) {
//             console.log(outerRef.current.clientWidth , outerRef.current.scrollWidth, outerRef.current.clientHeight < outerRef.current.scrollHeight)
//             if (outerRef.current.clientWidth < outerRef.current.scrollWidth || outerRef.current.clientHeight < outerRef.current.scrollHeight) {
//                 const newScale = scale - 0.02;
//                 setScale(newScale);
//                 setToRender(true);
//             } else {
//                 setScaling(false);
//                 setToRender(false);
//             }
//         } 
//     })

//     console.log("in Autoscaler:", scale)

//     const multiplier = 2.5 * scale;

//     const style = {
//         height: scaling ? `${scale * 100}vh`  : "fit-content",
//         width: "clamp(250px, 80vw, 500px)",
//         borderStyle: "solid",
//         borderWidth: "min(0.8vw, 4px)",
//         borderColor: getFGColor(props, props.theme.plainbg),
//         //margin: "0 auto",
//         //position: "relative",
//         fontSize: `${multiplier}vh`,
//         "--textFontSize": `${multiplier}vh`,
//         "--standard-h": `${multiplier * 2}vh`,
//         "--standard-p": `${multiplier * 0.25}vh`,
//         borderRadius: `${multiplier * 0.25}vh`,
//         top: 0,
//     }; 

//     return (
//         <div style={style} ref={outerRef} key={scale} {...props}>
//             { props.children }
//         </div>
//     );
// }

export const Autoscaler = withTheme(UnthemedAutoscaler)