import styled, {css, keyframes} from "styled-components";
import { setOpacity } from "./styles/colors";
import { useEffect, useRef, useState } from "react";
import { relativeSize } from "./ShadowScaler";

const ProgressContainer = styled.div` 
    height: calc(var(--standard-h) * 9/16);
    -webkit-transition: width 0.4s linear, background-color 0.4s linear
    -o-transition: width 0.4s linear, background-color 0.4s linear
    -moz-transition: width 0.4s linear, background-color 0.4s linear
    transition: width 0.4s linear, background-color 0.4s linear
`;

const progress = (props) => keyframes`
    0% {
        width: 0%;
        background: ${props.theme.red};
        box-shadow: 0 0 ${relativeSize(1, 1)} var(--redTrans), 
            0 0 ${relativeSize(1, 1)} var(--redTrans), 
            0 0 ${relativeSize(2, 2)} var(--redTrans), 
            0 0 ${relativeSize(2, 2)} var(--redTrans);
    }    
    45% {
        width: 45%;
        background: ${props.theme.orange};
        box-shadow: 0 0 ${relativeSize(1, 1)} var(--orangeTrans),
            0 0 ${relativeSize(2, 2)} var(--orangeTrans),
            0 0 ${relativeSize(4, 4)} var(--orangeTrans),
            0 0 ${relativeSize(6, 6)} var(--orangeTrans);
    }    
    65% {
        width: 65%;
        background: ${props.theme.yellow};
        box-shadow: 0 0 ${relativeSize(2, 2)} var(--yellowTrans),
            0 0 ${relativeSize(3, 3)} var(--yellowTrans),
            0 0 ${relativeSize(5, 5)} var(--yellowTrans),
            0 0 ${relativeSize(8, 8)} var(--yellowTrans);
    }        
    100% {
        width: 100%;
        background: ${props.theme.green};
        box-shadow: 0 0 ${relativeSize(2, 2)} var(--greenTrans),
            0 0 ${relativeSize(4, 4)} var(--greenTrans),
            0 0 ${relativeSize(8, 8)} var(--greenTrans),
            0 0 ${relativeSize(16, 16)} var(--greenTrans);
    }
`;

const ProgressBar = styled.div`
    ${props => css`
    --redTrans: ${setOpacity(props.theme.red, 0.5)};
    --orangeTrans: ${setOpacity(props.theme.orange, 0.5)};
    --yellowTrans: ${setOpacity(props.theme.yellow, 0.5)};
    --greenTrans: ${setOpacity(props.theme.green, 0.5)};
    `}

    background-color: ${props => props.theme.green};
    border-radius: ${relativeSize(30, 30)};
    -webkit-animation: ${props => progress(props)} ${props => props.duration}ms linear forwards ${props => props.progress};
    -o-animation: ${props => progress(props)} ${props => props.duration}ms linear forwards ${props => props.progress};
    -moz-animation: ${props => progress(props)} ${props => props.duration}ms linear forwards ${props => props.progress};
    animation: ${props => progress(props)} ${props => props.duration}ms linear forwards ${props => props.progress};
    height: 100%;
`;

export const AnimatedProgressBar = (props) => {
    const duration = props.duration;
    const [lang, setLang] = useState(props.lang);
    const [progress, setProgress] = useState(props.progress);

    useEffect(() => {
        setLang(props.lang);
        setProgress(props.progress);
    }, [props.lang, props.progress])

    return(
        <ProgressContainer>
            <ProgressBar duration={duration} lang={lang} progress={progress} />
        </ProgressContainer>
    );
};

export const AnimatedPercentage = (props) => {
  const number = props.progress * 100;
  const [count, setCount] = useState(props.duration > 0 ? 0 : number);

  useEffect(() => {
    let start = 0;
    const end = Math.floor(number);

    let totalMilSecDur = props.duration * props.progress
    let incrementTime = totalMilSecDur / (props.progress * 100);

    if (totalMilSecDur > 0) {
        let timer = setInterval(() => {
        start += 1;
        setCount(start)
        if (start >= end) {
            //setCount(n => Math.min(n, 99));
            clearInterval(timer);
        }
        }, incrementTime);
    }
  }, [props.lang, props.progress]);

    return (
        <h2 style={{fontWeight: "bold"}}>
            {`${count}%`}
        </h2>
    )
}

// export const AnimatedPercentage = styled.h2`
//     font-weight: bold;
//     -webkit-animation: p ${props => props.duration}ms steps(100) forwards ${props => props.progress};
//     -o-animation: p ${props => props.duration}ms steps(100) forwards ${props => props.progress};
//     -moz-animation: p ${props => props.duration}ms steps(100) forwards ${props => props.progress};
//     animation: p ${props => props.duration}ms steps(100) forwards ${props => props.progress};
//     counter-reset: p var(--p);
  
//     &:after {
//         content: counter(p) "%";
//     }
  
//     @keyframes p {
//         0% {
//             --p: 0;
//         }
//         100% {
//             --p: 100;
//         }
//     }
// `;
