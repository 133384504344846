import styled, { css } from "styled-components";

//props.size, props.clickable
// <div className={style} onClick={(e) => props.updatePosition(props.position, e)}></div>
const DotHolder = styled.div`
    width: ${(props) => props.size};
    height: ${(props) => props.size};
    border-radius: 50%;
    background-color: ${(props) => props.theme.darkbg};
    cursor: ${(props) => props.clickable ? "pointer" : ""};
    ${(props) => props.orientation === "vertical"
        ? css`&:not(:last-child) { 
                margin-bottom: ${(props) => `calc(0.3 * ${props.size})`};
            },`
        : css`&:not(:last-child) { 
                margin-right: ${(props) => `calc(0.3 * ${props.size})`};
            },`
    }
`;

const WrappedDotHolder = (props) => {
    return (
        <DotHolder {...props} onClick={() => props.updatePosition()} />
    )
}

//props.index, props.size, props.speed
const Dot = styled.div`
    position: absolute;
    width: ${(props) => props.size};
    height: ${(props) => props.size};
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-width: ${(props) => `calc(${(props) => props.size}/3)`};
    border-color: ${(props) => props.theme.darkbg};
    border-style: solid;
    background-color: ${(props) => props.theme.medbg};
    margin: auto;
    ${(props) => props.orientation === "vertical"
        ? css`top: ${(props) => `calc(${props.index} * 1.3  * ${props.size})`};
            left: 0;        
            transition: top ${props => props.speed} ease-in;`
        : css`left: ${(props) => `calc(${props.index} * 1.3  * ${props.size})`};
            bottom: 0;
            transition: left ${props => props.speed} ease-in;`
    }
`;

// props.length, props.size, props.clickable, props.defaultPosition, props.orientation
export const DotNavigation = (props) => {
    const inlineStyleString = 
        props.orientation === "vertical" ? "inline-block" : "inline-flex";

    return (
        <div style={{display: inlineStyleString, position: "relative", width: "fit-content", height: "fit-content", alignSelf: "center"}}>
            <div style={{display: inlineStyleString}}>
                { Array(props.length).fill(true).map((_, i) => <WrappedDotHolder key={i} clickable={props.clickable} size={props.size} updatePosition={() => props.onChange(i)} orientation={props.orientation} />) }
            </div>
            <Dot index={props.value} size={props.size} speed={props.speed} orientation={props.orientation} />
        </div>
    );
}