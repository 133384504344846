import { useEffect, useState } from 'react';

const useCountdown = (targetDate, onExpiration) => {
  const countDownDate = new Date(targetDate).getTime();

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    if (countDown <= 0) {
      onExpiration();
      return;
    }
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDown]);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor( (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60) );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [days, hours, minutes, seconds];
};

// export const onCountdown = (targetDate, effect, until = () => void(0)) => {
//   const [days, hours, minutes, seconds] = useCountdown(targetDate);

//   if (days + hours + minutes + seconds <= 0) {
//     effect();
//   } else {
//     until();
//   }
// };

export { useCountdown };